import { config } from 'src/config';
import { AxiosRequester } from './AxiosRequester';
import { CUSTOM_EVENTS } from '../constants/custom-events';
import { IRequesterError } from '../model/interfaces/IRequesterError';
import { IRequester } from '../model/interfaces/IRequester';
import { HTTP_STATUS_CODES } from '../constants/http';

const playerSvc: IRequester = new AxiosRequester(config.PLAYER_API_ENDPOINT);

playerSvc.addErrorResponseInterceptor((e: IRequesterError): void => {
  const { statusCode, config: requesterConfig } = e;

  if (statusCode === HTTP_STATUS_CODES.UNATHORIZED || statusCode === HTTP_STATUS_CODES.FORBIDDEN) {
    const referer = `[${requesterConfig?.method}] ${requesterConfig?.url}`;

    window.dispatchEvent(new CustomEvent(CUSTOM_EVENTS.RESTRICTED_EVENT, { detail: { referer, code: statusCode } }));
  }
});

export { playerSvc };
