import { IPlayer, IPlayerAboutInfo } from 'src/common/model/interfaces/IPlayer';
import { ITeam } from 'src/common/model/interfaces/ITeam';
import { replaceEmptyValues } from 'src/common/utils/form-data';

export type AboutInfoFormValues = {
  id: string,
  firstName: string;
  lastName: string;
  about: string;
  jerseyNumber: string;
  twitter: string;
  instagram: string;
  facebook: string;
  tiktok: string;
  team: ITeam;
  username: string;
};

export type AboutImageFormValues = {
  image: string;
};

export const getAboutInfoFormValues = (player: IPlayer): AboutInfoFormValues => {
  const {
    id,
    firstName,
    lastName,
    about,
    jerseyNumber,
    twitter,
    instagram,
    tiktok,
    facebook,
    username,
    team,
  } = player;

  return {
    id,
    team,
    firstName: firstName ?? '',
    lastName: lastName ?? '',
    about: about ?? '',
    jerseyNumber: jerseyNumber ?? '',
    twitter: twitter ?? '',
    instagram: instagram ?? '',
    facebook: facebook ?? '',
    tiktok: tiktok ?? '',
    username: username ?? '',
  };
};

export const getAboutInfoPayload = (aboutInfo: AboutInfoFormValues): IPlayerAboutInfo => replaceEmptyValues(aboutInfo);
