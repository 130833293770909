import { useQuery } from 'react-query';
import { getPlayer } from 'src/common/apis/player.api';
import { RQ_KEY } from 'src/common/constants/react-query';
import { IPlayer } from 'src/common/model/interfaces/IPlayer';
import { config } from 'src/config';
import { useAnalytics } from 'src/features/analytics/hooks/useAnalytics';
import { useAuthPlayer } from 'src/features/auth';
import { IRequesterError } from 'src/common/model/interfaces/IRequesterError';

/**
 * Fetch player info based on player identifier
 * @param identifier string, id or username of the player
 */
export function usePublicProfile(identifier?: string) {
  const { analytics, events } = useAnalytics();
  const { data: authPlayer, isLoading } = useAuthPlayer();

  return useQuery<IPlayer, IRequesterError>(
    [RQ_KEY.PROFILE_INFO, identifier],
    () => getPlayer(identifier!),
    {
      enabled: !!identifier && !isLoading && (!!analytics || config.STAGE === 'local'),
      async onSuccess(player) {
        const { id: playerId } = player;

        const isOwnProfileView = authPlayer?.id === playerId;

        /** Trigger profile view count event only for not owners */
        if (!isOwnProfileView) {
          analytics?.track(events.PROFILE_VIEW, { playerId });
        }
      },
    },
  );
}
