import { useParams } from 'react-router-dom';
import { usePublicProfile } from 'src/common/hooks/player/usePublicProfile';
import { PageMeta } from './page-meta';
import { OgMeta } from './og-meta';

export function MetaTags() {
  const { id: playerIdentifier } = useParams();

  const { data: player } = usePublicProfile(playerIdentifier);

  return (
    <>
      <PageMeta player={player} />
      <OgMeta player={player} />
    </>
  );
}
