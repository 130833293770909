import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { getPaginatedMatches } from 'src/common/apis/matches.api';
import { EXTENDED_CACHE_TIME_MS, RQ_KEY } from 'src/common/constants/react-query';
import { IMatch } from 'src/common/model/interfaces/IMatch';
import { RequesterError } from 'src/common/model/interfaces/IRequesterError';
import { IPaginatedResponse } from 'src/common/model/interfaces/IPaginatedResponse';
import { getClubsByTeams } from 'src/common/utils/teams';
import { useAuthPlayerTeams } from './useAuthPlayerTeams';
import { QueryParams } from '../useQueryParams';

const defaultQueryParams: QueryParams = {
  page: 1,
  pageSize: 10,
  filter: null,
  search: null,
  sort: null,
};

export function useAuthPlayerMatches(params: Partial<QueryParams> = defaultQueryParams) {
  const queryParams = { ...defaultQueryParams, ...params };
  const { data: playerTeams } = useAuthPlayerTeams();

  const hardFilters = useMemo(() => {
    if (playerTeams) {
      const club = getClubsByTeams(playerTeams).map(({ slug }) => slug);
      const team = playerTeams.map(({ slug }) => slug);

      return { club, team };
    }

    return null;
  }, [playerTeams]);

  return useQuery<IPaginatedResponse<IMatch>, RequesterError>(
    [RQ_KEY.AUTH_PLAYER_MATCHES, { ...queryParams, filter: { ...queryParams?.filter, ...hardFilters } }],
    () => getPaginatedMatches({
      ...queryParams,
      filter: { ...queryParams?.filter, ...hardFilters },
    }),
    {
      enabled: !!hardFilters,
      cacheTime: EXTENDED_CACHE_TIME_MS,
      staleTime: EXTENDED_CACHE_TIME_MS,
    },
  );
}
