import { ValueOf } from 'src/common/model/types/helper.type';

export const BROWSERS = {
  UNKNOWN: 'Unknown',
  FIREFOX: 'Firefox',
  CHROME: 'Chrome',
  SAFARI: 'Safari',
  OPERA: 'Opera',
  EDGE: 'Edge',
} as const;

export type BrowserName = ValueOf<typeof BROWSERS>;

type BrowserInfo = {
  name: BrowserName;
  version: string;
};

export const getBrowserDetails = (): BrowserInfo => {
  const { userAgent } = navigator;
  let browserName: BrowserName = BROWSERS.UNKNOWN;
  let version = 'Unknown';

  // Detect browser name
  if (userAgent.includes(BROWSERS.FIREFOX)) {
    browserName = BROWSERS.FIREFOX;
  } else if (userAgent.includes(BROWSERS.CHROME)) {
    browserName = BROWSERS.CHROME;
  } else if (userAgent.includes(BROWSERS.SAFARI)) {
    browserName = BROWSERS.SAFARI;
  } else if (userAgent.includes(BROWSERS.OPERA)) {
    browserName = BROWSERS.OPERA;
  } else if (userAgent.includes(BROWSERS.EDGE)) {
    browserName = BROWSERS.EDGE;
  }

  // Extract version number
  const versionMatch = userAgent.match(/(?:[^ ]+[ ])+[^ ]+/);
  if (versionMatch) {
    [version] = versionMatch[0].split(' ');
  }

  return {
    name: browserName,
    version,
  };
};
