import cn from 'classnames';
import { Tooltip } from '@veo/web-design-system';
import { useHover } from 'src/common/hooks/useHover';
import { useMobileDevice } from 'src/common/hooks/useMobileDevice';
import { Position } from 'src/common/model/enums/Position';
import styles from './pitch-positions.module.scss';

type Props = {
  position: Position;
  label: string;
  active?: boolean;
  activeOrder?: number;
  tooltip?: boolean;
  row: number;
  column: number;
  onSelect?(p: Position): void;
};

export function PositionItem({
  position,
  label,
  active = false,
  tooltip = true,
  activeOrder = 0,
  row,
  column,
  onSelect,
}: Props) {
  const { isMobile } = useMobileDevice();
  const [hoverRef, isHovered] = useHover<HTMLDivElement>(!isMobile);

  const handleClickPosition = () => {
    if (onSelect) {
      onSelect(position);
    }
  };

  const positionClassnames = cn(
    styles.position,
    {
      [styles.interactive]: !!onSelect,
      [styles.active]: active,
      [styles[`active-${activeOrder}`]]: active,
    },
  );

  return (
    <div
      ref={hoverRef}
      className={styles.position__wrapper}
      style={{ gridRow: row, gridColumn: column }}
    >
      <Tooltip
        portal
        className={styles.tooltip}
        visible={tooltip && isHovered}
        key={position}
        text={label}
        location="top"
      >
        <div
          role="button"
          tabIndex={0}
          aria-label={label}
          onClick={handleClickPosition}
          className={positionClassnames}
        />
      </Tooltip>
    </div>
  );
}
