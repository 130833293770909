import { Logo, TopAppBar } from '@veo/web-design-system';
import { HEADER_CONTENT_PORTAL_ID } from 'src/common/constants/portals';
import { UserStateIndicator } from 'src/components/user-state-indicator/user-state-indicator';
import { config } from 'src/config';
import { OidcProtected } from 'src/features/oidc';
import { NavigationMenu } from 'src/features/fosbury-navigation';
import headerStyles from './header.module.scss';

export function Header() {
  return (
    <TopAppBar className={headerStyles.header}>
      <div className={headerStyles['menu-wrapper']}>
        <OidcProtected>
          <NavigationMenu />
        </OidcProtected>
        <a href={config.ZOLA_URL} className={headerStyles.logo}>
          <Logo name="veo" color="mono" />
        </a>
      </div>
      <div id={HEADER_CONTENT_PORTAL_ID} />
      <UserStateIndicator />
    </TopAppBar>
  );
}
