import { QueryParams, FilterParams } from '../hooks/useQueryParams';

const getFilters = (filter: FilterParams) => {
  const filters: Record<string, string> = Object.entries(filter).reduce((acc, [key, value]) => {
    let filterValue = `${value}`;

    if (Array.isArray(value)) {
      filterValue = value.join(',');
    }

    return {
      ...acc,
      [key]: filterValue,
    };
  }, {});

  return filters;
};

export function getUrlQueryParams(query: Partial<QueryParams>) {
  let urlSearchParams: Record<string, string> = {};

  Object.entries(query)
    .forEach(([key, value]) => {
      if (!value || value === '') {
        return;
      }

      if (key === 'filter' && value) {
        urlSearchParams = { ...urlSearchParams, ...getFilters(value as FilterParams) };
        return;
      }

      urlSearchParams[key] = value as string;
    });

  return new URLSearchParams(urlSearchParams);
}
