import { UseInfiniteQueryOptions, useInfiniteQuery } from 'react-query';
import { getPlayerClips } from 'src/common/apis/player-clips';
import { EXTENDED_CACHE_TIME_MS, RQ_KEY } from 'src/common/constants/react-query';
import { IPlayerClip } from 'src/common/model/interfaces/IPlayerClip';
import { IPaginatedResponse } from 'src/common/model/interfaces/IPaginatedResponse';
import { RequesterError } from 'src/common/model/interfaces/IRequesterError';
import { FilterParams } from 'src/common/hooks/useQueryParams';
import { AbilityCode } from 'src/common/model/enums/AbilityCode';
import { Nullable } from 'src/common/model/types/helper.type';
import { QueryParams } from '../useQueryParams';

export type PlayerClipsFilter = FilterParams & {
  ability?: Nullable<AbilityCode>;
  playerId?: string;
};

type PlayerClipsQueryParams = Partial<QueryParams> & {
  filter: PlayerClipsFilter,
};

type InfiniteQueryOptions = Omit<UseInfiniteQueryOptions<IPaginatedResponse<IPlayerClip>, RequesterError>, 'queryKey'
| 'queryFn'
| 'enabled'
| 'select'
| 'getNextPageParam'>;

const defaultQueryParams = {
  page: 1,
  pageSize: 10,
  filter: {},
  search: null,
  sort: null,
};

export function usePlayerClips(
  params: PlayerClipsQueryParams = defaultQueryParams,
  options?: InfiniteQueryOptions,
) {
  const { playerId, ...restFilter } = params.filter;

  const queryParams = { ...defaultQueryParams, ...params, filter: { ...restFilter } };

  return useInfiniteQuery<IPaginatedResponse<IPlayerClip>, RequesterError>(
    [RQ_KEY.PLAYER_CLIPS, { queryParams, playerId }],
    ({ signal, pageParam = 1 }) => getPlayerClips(
      playerId!,
      { ...queryParams, page: pageParam },
      signal,
    ),
    {
      enabled: !!playerId,
      cacheTime: EXTENDED_CACHE_TIME_MS,
      staleTime: EXTENDED_CACHE_TIME_MS,
      getNextPageParam: (lastPage) => {
        const totalPages = Math.ceil(lastPage.meta.totalCount / queryParams.pageSize);
        const nextPageExists = totalPages > lastPage.meta.pageNumber;

        return nextPageExists ? lastPage.meta.pageNumber + 1 : undefined;
      },
      ...options,
    },
  );
}
