import { ITelestration } from 'src/common/model/interfaces/ITelestration';
import { VeoPlayerRendererProps } from 'src/components/veo-player/types/veo-player.type';
import { VeoPlayerControls } from 'src/components/veo-player/veo-player';
import { Nullable } from 'src/common/model/types/helper.type';
import { SpotlightIndicator } from './spotlight-indicator';
import styles from './telestration-video-controls.module.scss';

type Props = VeoPlayerRendererProps & {
  telestration: Nullable<ITelestration>,
};

export function TelestrationVideoControls(props: Props) {
  const { telestration, ...controlsProps } = props;

  const { videoRef } = controlsProps;

  return (
    <>
      <div className={styles['indicator-container']}>
        {
          telestration?.items?.map(({ id, timestamp }) => (
            <SpotlightIndicator
              key={id}
              timestamp={timestamp}
              duration={videoRef.current?.duration ?? 1}
            />
          ))
        }
      </div>
      <VeoPlayerControls {...controlsProps} />
    </>
  );
}
