import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { CUSTOM_EVENTS, RestrictedEvent } from 'src/common/constants/custom-events';
import { HTTP_STATUS_CODES } from 'src/common/constants/http';
import { useOidcAuth } from './useOidcAuth';
import { OIDC_ROUTES } from '../constants';

export function useRestrictedEvents() {
  const navigate = useNavigate();
  const { removeUser, clearStaleState } = useOidcAuth();

  useEffect(() => {
    const handleRestrictedEvent = async (e: Event) => {
      const customEvent = e as RestrictedEvent;

      if (customEvent.detail.code === HTTP_STATUS_CODES.UNATHORIZED) {
        await removeUser();
        await clearStaleState();

        navigate(`/${OIDC_ROUTES.unauthorized}`);
      }
    };

    window.addEventListener(CUSTOM_EVENTS.RESTRICTED_EVENT, handleRestrictedEvent);

    return () => {
      window.removeEventListener(CUSTOM_EVENTS.RESTRICTED_EVENT, handleRestrictedEvent);
    };
  }, []);
}
