import { uniqueId } from 'lodash-es';
import { IZolaClip } from '../model/interfaces/IZolaClip';
import { IPlayerClip } from '../model/interfaces/IPlayerClip';
import { PlayerClipTypes } from '../model/enums/PlayerClipType';
import { Privacy } from '../model/enums/Privacy';

export const convertZolaClipToPlayerClip = (
  zolaClip: IZolaClip,
  playerId: string,
): IPlayerClip => {
  const {
    id,
    url,
    tags,
    duration,
    thumbnail,
    match,
  } = zolaClip;

  return {
    url,
    tags,
    duration,
    thumbnail,
    playerId,
    id: uniqueId(),
    videoId: id,
    order: -(new Date().getTime()),
    telestration: null,
    ability: [],
    title: match.title,
    source: PlayerClipTypes.ZolaClip,
    privacy: Privacy.Public,
  };
};
