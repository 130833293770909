import React from 'react';
import { Menu, MenuList } from '@veo/web-design-system';
import { ITeam } from 'src/common/model/interfaces/ITeam';
import { TeamItem } from './menu-item';

type Props = React.ComponentProps<typeof Menu> & {
  teams: ITeam[];
  selectedTeam: ITeam;
  onChange: (selectedTeam: ITeam) => void;
};

export const TeamSelectorMenu = React.forwardRef((
  {
    teams, selectedTeam, onChange, ...menuProps
  }: Props,
  ref: React.ForwardedRef<HTMLDivElement>,
) => (
  <Menu {...menuProps} ref={ref}>
    <MenuList>
      {teams.map((team) => (
        <TeamItem
          key={team.id}
          {...team}
          selected={team?.id === selectedTeam?.id}
          onClick={() => onChange(team)}
        />
      ))}
    </MenuList>
  </Menu>
));
