import { useReducer } from 'react';
import { FilterParams } from 'src/common/hooks/useQueryParams';
import { IMatch } from 'src/common/model/interfaces/IMatch';
import { IPlayerClip } from 'src/common/model/interfaces/IPlayerClip';
import { Nullable } from 'src/common/model/types/helper.type';

type AddPlayerClipState = {
  step: number;
  match: Nullable<IMatch>;
  filter: FilterParams;
  playerClip: Nullable<IPlayerClip>
};

export const AddPlayerClipActions = {
  selectFilter: 'select_filter',
  selectMatch: 'select_match',
  goBack: 'go_back',
  change: 'change_player_clip',
  reset: 'reset',
} as const;

type SelectFilterAction = {
  type: typeof AddPlayerClipActions.selectFilter,
  payload: FilterParams,
};

type SelectMatchAction = {
  type: typeof AddPlayerClipActions.selectMatch,
  payload: IMatch,
};

type CreateAction = {
  type: typeof AddPlayerClipActions.change,
  payload: IPlayerClip,
};

type GoBackAction = {
  type: typeof AddPlayerClipActions.goBack,
};

type ResetAction = {
  type: typeof AddPlayerClipActions.reset,
};

type AddPlayerClipAction = SelectFilterAction
| SelectMatchAction
| CreateAction
| GoBackAction
| ResetAction;

const initialState: AddPlayerClipState = {
  step: 0,
  filter: {},
  match: null,
  playerClip: null,
};

function addPlayerClipReducer(state: AddPlayerClipState, action: AddPlayerClipAction): AddPlayerClipState {
  if (action.type === AddPlayerClipActions.selectFilter) {
    return {
      ...state,
      step: 1,
      filter: action.payload,
    };
  }

  if (action.type === AddPlayerClipActions.selectMatch) {
    return {
      ...state,
      step: 1,
      filter: { matchId: action.payload.id },
      match: action.payload,
    };
  }

  if (action.type === AddPlayerClipActions.goBack) {
    if (state.step === 1) {
      return initialState;
    }

    if (state.step === 2) {
      return {
        ...state,
        step: 1,
        playerClip: null,
      };
    }

    return state;
  }

  if (action.type === AddPlayerClipActions.change) {
    return {
      ...state,
      step: 2,
      playerClip: action.payload,
    };
  }

  if (action.type === AddPlayerClipActions.reset) {
    return initialState;
  }

  return state;
}

export function useAddPlayerClip() {
  return useReducer(addPlayerClipReducer, initialState);
}
