import { useParams, useNavigate } from 'react-router-dom';
import { useFormContext } from 'react-hook-form';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { Button, useNotification } from '@veo/web-design-system';
import { isValidationError } from 'src/common/utils/requester-errors';
import { IPlayerAboutInfo } from 'src/common/model/interfaces/IPlayer';
import { Nullable } from 'src/common/model/types/helper.type';
import { isPlayerId, isPlayerUsername } from 'src/common/utils/player-profile';
import { useAuthPlayerAboutInfoMutation } from 'src/common/hooks/player/useAuthPlayerAboutInfoMutation';
import { APP_ROUTES } from 'src/common/constants/routes';
import { usePlayerOptimisticUpdate } from 'src/common/hooks/player/usePlayerOptimisticUpdate';
import { getAboutInfoPayload, AboutInfoFormValues } from '../../../utils/about-information';
import { FirstnameField } from './firstname-field';
import { LastnameField } from './lastname-field';
import { TeamField } from './team-field';
import { UsernameField } from './username-field';
import { JerseyField } from './jersey-field';
import { AboutField } from './about-field';
import { FacebookField } from './facebook-field';
import { InstagramField } from './instagram-field';
import { TwitterField } from './twitter-field';
import { TiktokField } from './tiktok-field';
import styles from './about-information-form.module.scss';

type Props = {
  onCancel: VoidFunction;
  onSubmit: VoidFunction;
};

export function AboutInformationForm({ onCancel, onSubmit }: Props) {
  const { id: playerIdentifier } = useParams();
  const navigate = useNavigate();

  const {
    formState,
    setError,
    handleSubmit,
  } = useFormContext<AboutInfoFormValues>();

  const {
    isDirty,
    isSubmitting,
    isValid,
  } = formState;

  const { notify } = useNotification();

  const { t } = useTranslation();

  const { mutateAsync: updateAboutInfo } = useAuthPlayerAboutInfoMutation();
  const { applyOptimisticUpdate } = usePlayerOptimisticUpdate(playerIdentifier);

  function getNewProfileUrl(nextUsername: Nullable<string>, playerId: string): Nullable<string> {
    const isUrlHasUsername = isPlayerUsername(playerIdentifier!);
    const isUrlHasId = isPlayerId(playerIdentifier!);

    if (isUrlHasId) {
      return null;
    }

    if (isUrlHasUsername && !nextUsername) {
      return `/${APP_ROUTES.playerProfile}/${playerId}`;
    }

    if (isUrlHasUsername && nextUsername && nextUsername !== playerIdentifier) {
      return `/${APP_ROUTES.playerProfile}/${nextUsername}`;
    }

    return null;
  }

  async function submitForm(data: AboutInfoFormValues) {
    const formattedData = getAboutInfoPayload(data);

    await updateAboutInfo(formattedData as IPlayerAboutInfo, {
      onSuccess(res, optimisticPayload) {
        const { username, id } = optimisticPayload;

        /** If username was updated replace with new url to avoid Profile Not Found page */
        const nextProfileUrl = getNewProfileUrl(username, id);

        notify('success', t('player_about.form.success_notification'));

        if (nextProfileUrl) {
          navigate(nextProfileUrl, { replace: true });
        } else {
          applyOptimisticUpdate(optimisticPayload);
        }
        onSubmit();
      },
      onError(err) {
        if (isValidationError(err)) {
          const { fields } = err;

          const errorKeys = Object.keys(fields) as (keyof AboutInfoFormValues)[];

          errorKeys.forEach((field) => setError(field, { message: fields[field], type: 'server' }));
        }
      },
    });
  }

  return (
    <form
      className={styles.form}
      onSubmit={handleSubmit(submitForm)}
    >
      <div className={styles.content}>
        <FirstnameField />
        <LastnameField />
        <TeamField />
        <UsernameField />
        <JerseyField />
        <AboutField />
        <FacebookField />
        <InstagramField />
        <TwitterField />
        <TiktokField />
      </div>

      <div className={cn(styles.controls, styles.field)}>
        <Button
          flexible
          variant="outlined"
          label={t('player_about.form.cancel_button_label')}
          disabled={isSubmitting}
          onClick={onCancel}
        />
        <Button
          flexible
          type="submit"
          loading={isSubmitting}
          disabled={!isDirty || !isValid || isSubmitting}
          variant="secondary"
          label={t('player_about.form.submit_button_label')}
        />
      </div>
    </form>
  );
}
