import cn from 'classnames';
import { MenuListItem } from '@veo/web-design-system';
import { ITeam } from 'src/common/model/interfaces/ITeam';
import styles from './menu-item.module.scss';

type Props = ITeam & {
  selected: boolean;
  onClick(): void;
};

export function TeamItem({
  name, crest, selected, onClick,
}: Props) {
  const classNames = cn(
    styles['team-item'],
    { [styles['team-item--selected']]: selected },
  );

  return (
    <div className={classNames}>
      <MenuListItem
        title={name}
        crest={{ name, url: crest || undefined }}
        iconEnd={selected ? 'check' : undefined}
        onClick={onClick}
      />
    </div>
  );
}
