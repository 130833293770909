import cn from 'classnames';
import { IClub } from 'src/common/model/interfaces/IClub';
import { MenuListItem } from '@veo/web-design-system';
import { config } from 'src/config';
import { LinkWrapper } from 'src/components/link/link';
import styles from './menu-item.module.scss';

type Props = IClub & {
  selected: boolean;
  onClick(): void;
};

export function ClubhouseItem({
  name, crest, url, selected, onClick,
}: Props) {
  const classNames = cn(
    styles['clubhouse-item'],
    { [styles['clubhouse-item--selected']]: selected },
  );

  return (
    <LinkWrapper
      external
      link={`${config.ZOLA_URL}${url}#recordings`}
      className={classNames}
    >
      <MenuListItem
        title={name}
        crest={{ name, url: crest || undefined }}
        iconEnd={selected ? 'check' : undefined}
        onClick={onClick}
      />
    </LinkWrapper>
  );
}
