import { DateTime, Duration } from 'luxon';
import i18n from '../services/i18next';

type DurationKey = 'years' | 'months' | 'weeks' | 'days' | 'hours' | 'minutes' | 'seconds' | 'milliseconds';

type DurationType = Partial<Record<DurationKey, number>>;

export const calculateYearsFromDate = (startDate: string | undefined | null): number | null => {
  if (!startDate) {
    return null;
  }

  const start = DateTime.fromISO(startDate);

  const years = DateTime.now().diff(start, 'years').toObject().years ?? 0;

  return Math.floor(years);
};

export const formatDuration = (duration: DurationType, format: string): string => {
  if (!duration) {
    return '';
  }

  return Duration.fromDurationLike(duration).toFormat(format);
};

export const formatDate = (dateString: string, format: string = 'dd.LL.yyyy') => {
  const date = DateTime.fromISO(dateString);

  return date.toFormat(format);
};

export const convertToIsoDateTime = (dateString: string, endDay = false) => {
  let date = DateTime.fromISO(dateString);

  if (endDay) {
    date = date.set({
      hour: 23,
      minute: 59,
      second: 59,
      millisecond: 999,
    });
  }

  return date.toISO();
};

export const calculateAge = (dateString: string) => {
  const birthDate = DateTime.fromISO(dateString);
  const now = DateTime.now();

  const { years: age = 0 } = now.diff(birthDate, 'years').toObject();

  if (age < 13) {
    return i18n.t('validators.forbidden_age');
  }

  return Math.floor(age);
};
