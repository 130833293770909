import { CSSProperties, useMemo } from 'react';
import cn from 'classnames';
import { Text } from '@veo/web-design-system';
import { isEmpty } from 'lodash-es';
import { useDebounce } from 'src/common/hooks/useDebounce';
import { AbilityItem } from 'src/components/ability';
import { VeoPlayerRendererProps } from 'src/components/veo-player/types/veo-player.type';
import { IPlayerClip } from 'src/common/model/interfaces/IPlayerClip';
import { useResizeObserver } from 'src/common/hooks/useResizeObserver';
import { VeoPlayerControls } from 'src/components/veo-player/veo-player';
import styles from './video-player-controls.module.scss';

type Props = VeoPlayerRendererProps & {
  prefix?: string;
  playerClip?: IPlayerClip;
};

export function VideoPlayerControls(props: Props) {
  const { playerClip, prefix, ...controlsProps } = props;
  const [containerRef, rect] = useResizeObserver<HTMLDivElement>();

  const debouncedRect = useDebounce(rect, 300);

  if (!playerClip) {
    return <VeoPlayerControls {...controlsProps} />;
  }

  const { ability, title, duration } = playerClip;

  const wrapperStyles = useMemo(() => {
    if (!containerRef.current || containerRef.current.clientWidth >= containerRef.current.scrollWidth) {
      return undefined;
    }

    const distance = containerRef.current.scrollWidth - containerRef.current.clientWidth;
    const timingMs = duration * 1000;

    return {
      '--player-clips-video-controls-timing': `${timingMs}ms`,
      '--player-clips-video-controls-translate': `-${distance}px`,
    };
  }, [debouncedRect]);

  const wrapperClassnames = cn(
    styles.wrapper,
    { [styles.animated]: !!wrapperStyles },
  );

  return (
    <>
      <div ref={containerRef} className={styles.container}>
        <div
          className={wrapperClassnames}
          style={wrapperStyles as CSSProperties}
        >
          {
            !isEmpty(prefix) && (
              <Text type="block">
                { prefix }
              </Text>
            )
          }
          <Text type="block">
            { title }
          </Text>
          {
            ability.map((code) => <AbilityItem key={code} className={styles.ability} code={code} />)
          }
        </div>
      </div>
      <VeoPlayerControls {...controlsProps} />
    </>
  );
}
