import { useQueryClient } from 'react-query';
import { RQ_KEY } from 'src/common/constants/react-query';
import { IPlayer } from 'src/common/model/interfaces/IPlayer';
import { usePublicProfile } from './usePublicProfile';

type OptimisticPayload = Partial<IPlayer>;

/**
 * Update Public Player Profile optimistically
 * @param identifier string, id or username of the player
 */
export function usePlayerOptimisticUpdate(identifier?: string) {
  const queryClient = useQueryClient();
  const { data: publicProfile } = usePublicProfile(identifier);

  function applyOptimisticUpdate(payload: OptimisticPayload) {
    if (publicProfile) {
      const nextData = {
        ...publicProfile,
        ...payload,
      };

      queryClient.setQueryData<IPlayer>([RQ_KEY.PROFILE_INFO, identifier], nextData);

      queryClient.invalidateQueries([RQ_KEY.PROFILE_INFO, identifier]);
    }
  }

  return {
    applyOptimisticUpdate,
  };
}
