import { ReactElement, useState, cloneElement } from 'react';
import { Modal } from '@veo/web-design-system';
import { IPlayerClip } from 'src/common/model/interfaces/IPlayerClip';
import { AddPlayerClipWizard } from './wizard/wizard';
import styles from './add-player-clip.module.scss';

type Props = {
  children: ReactElement;
  onAddClip?(pc: IPlayerClip): void;
};

export function AddPlayerClip({ children, onAddClip }: Props) {
  const [open, setOpen] = useState(false);

  return (
    <>
      { cloneElement(children, { onClick: () => setOpen(true) }) }
      <Modal
        open={open}
        closeOnClickOutside={false}
        className={styles.modal}
        onClose={() => setOpen(false)}
      >
        <AddPlayerClipWizard
          onAddClip={onAddClip}
          onClose={() => setOpen(false)}
        />
      </Modal>
    </>
  );
}
