import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate } from 'react-router-dom';
import { ErrorPage, Spinner } from '@veo/web-design-system';
import { APP_ROUTES } from 'src/common/constants/routes';
import { useAuthPlayer } from 'src/features/auth';
import { getProfileIdentifier } from 'src/common/utils/player-profile';
import { useOidcAuth } from '../../hooks/useOidcAuth';
import { useOidcUserSync } from '../../hooks/useOidcUserSync';
import styles from './signin-redirect.module.scss';

export function SigninRedirect() {
  const {
    isAuthenticated, clearStaleState, error: loginError, signinRedirect,
  } = useOidcAuth();
  const { isFetched: isSynced } = useOidcUserSync();
  const { data: authPlayer } = useAuthPlayer();

  const { t } = useTranslation();

  useEffect(() => () => {
    clearStaleState();
  }, []);

  if (loginError) {
    return (
      <ErrorPage
        illustration="users"
        title={t('auth.login.error_title')}
        description={loginError.message}
        onButtonClick={() => signinRedirect()}
        buttonText={t('auth.login.action_label') as string}
      />
    );
  }

  if (isAuthenticated && isSynced && !authPlayer) {
    return <Navigate to="/" replace />;
  }

  if (isAuthenticated && isSynced && authPlayer) {
    return <Navigate to={`/${APP_ROUTES.playerProfile}/${getProfileIdentifier(authPlayer)}`} replace />;
  }

  return <Spinner className={styles.loader} size="lg" />;
}
