/* eslint-disable @typescript-eslint/naming-convention */
import { useMemo, useEffect } from 'react';
import { useAuth } from 'react-oidc-context';
import { IOidcUser } from 'src/common/model/interfaces/IUser';

export const useOidcAuth = () => {
  const oidcData = useAuth();

  useEffect(() => {
    const { user, isAuthenticated, signinRedirect } = oidcData;

    if (!isAuthenticated && user) {
      signinRedirect();
    }
  }, [oidcData.isAuthenticated, oidcData.user]);

  const userData: IOidcUser | undefined = useMemo(() => {
    if (oidcData.user) {
      const { user } = oidcData;
      const {
        sub, email, family_name, given_name, birthdate, picture,
      } = user.profile;

      return {
        id: sub,
        externalId: sub,
        firstName: given_name!,
        lastName: family_name!,
        avatar: picture,
        birthdate,
        email: email!,
      };
    }

    return undefined;
  }, [oidcData.user]);

  return {
    ...oidcData,
    data: userData,
  };
};
