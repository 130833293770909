import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { BaseOrderableItem, OrderableDndOptions, OrderableDisabled } from '../model/orderable';

type Props<T extends BaseOrderableItem> = {
  item: T;
  disabled?: OrderableDisabled;
};

export function useOrderable<T extends BaseOrderableItem>(props: Props<T>) {
  const { item, disabled = false } = props;

  const {
    isDragging,
    isOver,
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({ id: item.id, disabled, data: item });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    opacity: isDragging ? 0 : 1,
  };

  const options: OrderableDndOptions = {
    isDragging,
    isHovered: isOver,
  };

  return {
    attributes,
    listeners,
    setNodeRef,
    style,
    options,
  };
}
