import cn from 'classnames';
import { Skeleton } from './skeleton';
import styles from './skeleton.module.scss';

type Props = {
  size?: 'sm' | 'md' | 'lg';
  icon?: boolean;
};

export function ButtonSkeleton({ size = 'lg', icon = false }: Props) {
  const buttonClassnames = cn(
    styles['button-skeleton'],
    styles[`button-skeleton-${size}`],
    { [styles.icon]: icon },
  );

  return <Skeleton className={buttonClassnames} />;
}
