import { components, ClearIndicatorProps } from 'react-select';
import cn from 'classnames';
import { Icon } from '@veo/web-design-system';
import styles from '../dropdown.module.scss';

export function ClearIndicator<Option, IsMulti extends boolean>(props: ClearIndicatorProps<Option, IsMulti>) {
  const { isFocused } = props;

  const indicatorClassnames = cn(
    styles['clear-indicator'],
    { [styles['clear-indicator__focused']]: isFocused },
  );

  return (
    <components.ClearIndicator {...props}>
      <Icon name="x" size="xs" className={indicatorClassnames} />
    </components.ClearIndicator>
  );
}
