/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable max-len */
import { SVGProps } from 'react';

type Props = SVGProps<SVGSVGElement>;

export function TelestrationSVGIndicator(props: Props) {
  return (
    <svg
      viewBox="0 0 28 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="none"
      {...props}
    >
      <rect opacity="0.5" x="0.253906" y="0.296875" width="26.9792" height="18.8854" rx="5.39583" fill="#121212" />
      <path d="M9.32937 4.34375H18.212L19.3165 10.6095C19.5935 12.1811 18.567 13.687 17.0029 14.0035L14.3218 14.5461C13.9388 14.6236 13.5465 14.6434 13.1576 14.605L11.7384 14.4645L10.3377 14.1096C8.83804 13.7295 7.88901 12.2529 8.16618 10.7309L9.32937 4.34375Z" fill="url(#paint0_linear_6728_16430)" />
      <path d="M18.8692 12.0411C18.8692 13.1877 16.6096 14.1172 13.8223 14.1172" stroke="white" strokeWidth="1.20686" />
      <path d="M18.8692 11.9746C18.8692 10.828 16.6096 9.89856 13.8223 9.89856" stroke="#C1C1C1" strokeWidth="1.20686" />
      <path d="M13.6651 9.89856C10.8778 9.89856 8.61816 10.828 8.61816 11.9746" stroke="white" strokeWidth="1.20686" />
      <path d="M8.61816 12.0411C8.61816 13.1877 10.8778 14.1172 13.6651 14.1172" stroke="#C1C1C1" strokeWidth="1.20686" />
      <defs>
        <linearGradient id="paint0_linear_6728_16430" x1="13.7441" y1="11.4878" x2="13.7441" y2="4.93909" gradientUnits="userSpaceOnUse">
          <stop stopColor="#D9D9D9" />
          <stop offset="1" stopColor="#D9D9D9" stopOpacity="0" />
        </linearGradient>
      </defs>
    </svg>
  );
}
