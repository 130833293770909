import { useTranslation } from 'react-i18next';
import { IconButton } from '@veo/web-design-system';
import { IMatch } from 'src/common/model/interfaces/IMatch';
import { Stepper, StepperSizes } from 'src/components/stepper/stepper';
import { FilterParams } from 'src/common/hooks/useQueryParams';
import { MEDIA_QUERY } from 'src/common/constants/media-query';
import { useMediaQuery } from 'src/common/hooks/useMediaQuery';
import { IPlayerClip } from 'src/common/model/interfaces/IPlayerClip';
import { useCreatePlayerClipMutation } from 'src/common/hooks/player-clips/useCreatePlayerClipMutation';
import { ITelestration } from 'src/common/model/interfaces/ITelestration';
import { Confirmation, useConfirmation } from 'src/components/confirmation';
import { useCreateTelestrationMutation } from 'src/features/telestration';
import { ADD_CLIP_STEPS as steps } from '../../constants';
import { useAddPlayerClip, AddPlayerClipActions } from '../../hooks/useAddPlayerClip';
import { ClipsFilters } from '../filter-step/clips-filters';
import { ClipPicker } from '../find-step/clip-picker';
import { ClipDetails } from '../enhance-step/clip-details';
import styles from './wizard.module.scss';

type Props = {
  onClose: VoidFunction;
  onAddClip?(pc: IPlayerClip): void;
};

export function AddPlayerClipWizard({ onClose, onAddClip }: Props) {
  const [state, dispatch] = useAddPlayerClip();

  const { t } = useTranslation();

  const { mutate: createPlayerClip, isLoading: isCreatingPlayerClip } = useCreatePlayerClipMutation();
  const { mutateAsync: createTelestration, isLoading: isCreatingTelestration } = useCreateTelestrationMutation();

  const {
    step, filter, match, playerClip,
  } = state;

  const isMobileLayout = useMediaQuery(MEDIA_QUERY.MOBILE);

  function handleSelectFilter(f: FilterParams) {
    dispatch({ type: AddPlayerClipActions.selectFilter, payload: f });
  }

  function handleSelectMatch(m: IMatch) {
    dispatch({ type: AddPlayerClipActions.selectMatch, payload: m });
  }

  function handlePlayerClipChange(pc: IPlayerClip) {
    dispatch({ type: AddPlayerClipActions.change, payload: pc });
  }

  function handleGoBack() {
    dispatch({ type: AddPlayerClipActions.goBack });
  }

  function closeModal() {
    onClose();
    dispatch({ type: AddPlayerClipActions.reset });
  }

  const { triggerAction: handleCloseModal, ...restConfirmationProps } = useConfirmation({
    required: !!playerClip,
    action: closeModal,
  });

  function handleCreateTelestration(telestration: ITelestration, playerClipId: string) {
    return createTelestration(
      { playerClipId, telestration },
      {
        onSettled() {
          onClose();
        },
      },
    );
  }

  function handlePublish() {
    if (!playerClip) {
      return;
    }

    createPlayerClip(playerClip, {
      async onSuccess(createdPlayerClip) {
        if (playerClip.telestration) {
          const { id } = createdPlayerClip;

          await handleCreateTelestration(playerClip.telestration, id);
        }

        closeModal();

        if (onAddClip) {
          onAddClip({ ...playerClip, id: createdPlayerClip.id });
        }
      },
    });
  }

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <Stepper
          steps={steps}
          active={steps[step]}
          size={isMobileLayout ? StepperSizes.sm : StepperSizes.lg}
        />
        <IconButton
          size="sm"
          icon="x"
          variant="text"
          onClick={handleCloseModal}
        />
      </div>
      {
        step === 0 && (
          <ClipsFilters
            onFilterChange={handleSelectFilter}
            onMatchChange={handleSelectMatch}
            className={styles.content}
          />
        )
      }
      {
        step === 1 && (
          <ClipPicker
            filter={filter}
            match={match}
            onPlayerClipChange={handlePlayerClipChange}
            onGoBack={handleGoBack}
            className={styles.content}
          />
        )
      }
      {
        step === 2 && (
          <ClipDetails
            playerClip={playerClip}
            isPublishing={isCreatingPlayerClip || isCreatingTelestration}
            onPlayerClipChange={handlePlayerClipChange}
            onGoBack={handleGoBack}
            onPublished={handlePublish}
            className={styles.content}
          />
        )
      }
      <Confirmation
        title={t('player_clip_details.edit.discard_confirmation_title')}
        message={t('add_player_clips.save.discard_description') as string}
        confirmLabel={t('player_clip_details.edit.discard_confirmation_confirm_button_label') as string}
        {...restConfirmationProps}
      />
    </div>
  );
}
