import { PlayStyle, PlayStyleMap, Position } from 'src/common/model/enums/Position';
import { IPlayer, IPlayerPositionInfo } from 'src/common/model/interfaces/IPlayer';
import { Nullable } from 'src/common/model/types/helper.type';

export type PositionPlaystyleFormValues = {
  id: string;
  positions: Position[];
  playstyle: Nullable<PlayStyle>;
};

export function getPlayerPositions(player?: Nullable<IPlayer>) {
  if (!player || !player.position) {
    return [];
  }

  return [player.position, ...(player.additionalPositions ?? [])].filter(Boolean);
}

export function getDefaultPlaystyle(position?: Nullable<Position>) {
  if (!position) {
    return null;
  }

  return PlayStyleMap[position][0] ?? null;
}

export const getPositionPlaystyleFormValues = (player: IPlayer): PositionPlaystyleFormValues => {
  const {
    id,
    playstyle,
  } = player;

  const positions = getPlayerPositions(player);

  return {
    id,
    positions,
    playstyle,
  };
};

export const getPositionPlaystylePayload = (formValues: PositionPlaystyleFormValues): IPlayerPositionInfo => {
  const { id, positions, playstyle } = formValues;
  const [position, ...additionalPositions] = positions;

  return {
    id,
    position: position ?? null,
    additionalPositions: additionalPositions ?? null,
    playstyle,
  };
};
