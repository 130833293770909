import { useQuery } from 'react-query';
import { useOidcAuth } from 'src/features/oidc';
import { EXTENDED_CACHE_TIME_MS, RQ_KEY } from '../../constants/react-query';
import { RequesterError } from '../../model/interfaces/IRequesterError';
import { getUserSettings } from '../../apis/user-settings.api';
import { IUserSettings } from '../../model/interfaces/IUserSettings';

export function useUserSettings() {
  const { data: user } = useOidcAuth();

  return useQuery<IUserSettings, RequesterError, IUserSettings>(
    RQ_KEY.AUTH_USER_SETTINGS,
    () => getUserSettings(user!.externalId),
    {
      enabled: !!user,
      cacheTime: EXTENDED_CACHE_TIME_MS,
      staleTime: EXTENDED_CACHE_TIME_MS,
    },
  );
}

export type UserSettingsQuery = ReturnType<typeof useUserSettings>;
