import cn from 'classnames';
import { Avatar, Text } from '@veo/web-design-system';
import { getUserInitials } from 'src/common/utils/user';
import { IPlayer } from 'src/common/model/interfaces/IPlayer';
import { getPlayerTeam } from 'src/common/utils/player-profile';
import { useMediaQuery } from 'src/common/hooks/useMediaQuery';
import { MEDIA_QUERY } from 'src/common/constants/media-query';
import styles from './player-panel.module.scss';

type Props = {
  player: IPlayer;
  className?: string;
};

export function PlayerPanel({ player, className }: Props) {
  const {
    firstName,
    lastName,
    avatar,
    team,
    jerseyNumber,
  } = player;

  const isMobileLayout = useMediaQuery(MEDIA_QUERY.MOBILE);

  if (!player || isMobileLayout) {
    return null;
  }

  const initials = getUserInitials(firstName, lastName);
  const badgeClassnames = cn(styles.container, className);
  const teamName = getPlayerTeam(player.team);
  const { crest } = player.team.club;

  return (
    <div className={badgeClassnames}>
      <Avatar size="md" initials={initials} image={avatar ?? undefined} />
      { crest && (
        <div className={styles['club-crest']}>
          <Avatar size="sm" initials={initials} image={crest} />
        </div>
      )}
      <div className={styles['jersey-number']}>
        { jerseyNumber }
      </div>
      <div className={styles['text-wrapper']}>
        <div className={styles.fullname}>
          { `${firstName} ${lastName}`}
        </div>
        {team && (
          <Text size="caption" type="div">
            {teamName}
          </Text>
        )}
      </div>
    </div>
  );
}
