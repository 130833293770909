import { CSSProperties } from 'react';
import cn from 'classnames';
import styles from './skeleton.module.scss';

type Props = {
  width?: string;
  height?: string;
  className?: string;
};

export function Skeleton({ width = '0px', height = '0px', className }: Props) {
  const skeletonClassnames = cn(styles.skeleton, className);

  return <div style={{ '--skeleton-width': width, '--skeleton-height': height } as CSSProperties} className={skeletonClassnames} />;
}
