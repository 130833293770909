import { useEffect, useRef } from 'react';
import { debounce } from 'lodash-es';

type IntersectionObserverOptions = {
  rootMargin?: string;
  root?: Element | null;
  threshold?: number;
};

type Props = {
  debounceInterval?: number;
  enabled?: boolean;
  onIntersect: VoidFunction;
  options?: IntersectionObserverOptions;
};

const defaultOptions: IntersectionObserverOptions = {
  root: null,
  rootMargin: '0px 0px 0px 0px',
  threshold: 0,
};

export function useIntersectionObserver<T extends HTMLElement>({
  debounceInterval = 0,
  enabled = true,
  options = defaultOptions,
  onIntersect,
}: Props) {
  const observer = useRef<IntersectionObserver>();
  const target = useRef<T>(null);

  const debouncedOnIntersect = useRef(debounce(onIntersect, debounceInterval)).current;

  useEffect(() => {
    if (target.current) {
      const resultOptions = { ...options, ...defaultOptions };

      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && enabled) {
          debouncedOnIntersect();
        }
      }, resultOptions);

      observer.current.observe(target.current);
    }

    return () => {
      if (observer.current) {
        observer.current.disconnect();
      }

      debouncedOnIntersect.cancel();
    };
  }, [target.current, options, enabled, debouncedOnIntersect]);

  return { ref: target };
}
