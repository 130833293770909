import { useMutation, useQueryClient } from 'react-query';
import { useNotification } from '@veo/web-design-system';
import { updateUserSettings } from 'src/common/apis/user-settings.api';
import { RequesterError } from 'src/common/model/interfaces/IRequesterError';
import { isRequesterError } from 'src/common/utils/requester-errors';
import { IUserSettings } from 'src/common/model/interfaces/IUserSettings';
import { useOidcAuth } from 'src/features/oidc';
import { RQ_KEY } from 'src/common/constants/react-query';

export function useUserSettingsMutation() {
  const { notify } = useNotification();
  const { data: user } = useOidcAuth();
  const queryClient = useQueryClient();

  return useMutation<unknown, RequesterError, IUserSettings>(
    (settings) => {
      if (user) {
        return updateUserSettings(user.externalId, settings);
      }

      return Promise.reject();
    },
    {
      async onSuccess() {
        queryClient.invalidateQueries(RQ_KEY.AUTH_USER_SETTINGS);
      },
      async onError(err) {
        if (isRequesterError(err)) {
          notify('error', err.message);
        }
      },
    },
  );
}
