import { components, DropdownIndicatorProps } from 'react-select';
import cn from 'classnames';
import { Icon, IconName } from '@veo/web-design-system';
import styles from '../dropdown.module.scss';

export function DropdownIndicator<Option, IsMulti extends boolean>(props: DropdownIndicatorProps<Option, IsMulti>) {
  const { selectProps: { menuIsOpen }, isFocused } = props;

  const iconName: IconName = `chevron-${menuIsOpen ? 'up' : 'down'}`;

  const indicatorClassnames = cn(
    styles.indicator,
    { [styles.indicator__focused]: isFocused },
  );

  return (
    <components.DropdownIndicator {...props}>
      <Icon name={iconName} size="xs" className={indicatorClassnames} />
    </components.DropdownIndicator>
  );
}
