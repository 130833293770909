import { RefObject } from 'react';
import { Transform } from '@dnd-kit/utilities';
import { FrameCanvas, ITelestrationItem, ParsedPoint } from 'src/common/model/interfaces/ITelestration';
import { SpotlightBoxModel } from 'src/features/telestration/models/player-spotlight';

type Coords = {
  x: number;
  y: number;
};

const heightWidthRatio = 3;

export function getSVGRectStartPoint(points: ParsedPoint[]) {
  const xCoordinates = points.map(({ x }) => x);
  const yCoordinates = points.map(({ y }) => y);

  const x = Math.min(...xCoordinates);
  const y = Math.min(...yCoordinates);

  return { x, y };
}

export function getSVGRectEndPoint(points: ParsedPoint[]) {
  const xCoordinates = points.map(({ x }) => x);
  const yCoordinates = points.map(({ y }) => y);

  const x = Math.max(...xCoordinates);
  const y = Math.max(...yCoordinates);

  return { x, y };
}

export function getSVGRectWidth(points: ParsedPoint[]) {
  const { x } = getSVGRectStartPoint(points);
  const { x: maxX } = getSVGRectEndPoint(points);

  return maxX - x;
}

export function getSVGRectHeight(points: ParsedPoint[]) {
  const { y } = getSVGRectStartPoint(points);
  const { y: maxY } = getSVGRectEndPoint(points);

  return maxY - y;
}

export function getSpotlightBoundingBox(parsedBox: ITelestrationItem): SpotlightBoxModel {
  const xCoordinates = parsedBox.points.map(({ x: apiX }) => apiX);
  const yCoordinates = parsedBox.points.map(({ y: apiY }) => apiY);

  const x = Math.min(...xCoordinates);
  const y = Math.min(...yCoordinates);

  const maxX = Math.max(...xCoordinates);
  const maxY = Math.max(...yCoordinates);

  return {
    x,
    y,
    width: maxX - x,
    height: maxY - y,
    id: parsedBox.id,
  };
}

export function formatTelestrationTimestamp(value: number) {
  const seconds = Math.floor(value);
  const milliseconds = Math.round((value - seconds) * 100);

  return `${seconds.toString().padStart(2, '0')}:${milliseconds.toString().padStart(2, '0')}`;
}

export function getCoordsToSVGScaleFactor(canvas: FrameCanvas, viewContainerRef: RefObject<HTMLDivElement>) {
  if (viewContainerRef.current) {
    const { width, height } = viewContainerRef.current.getBoundingClientRect();

    const { width: canvasWidth, height: canvasHeight } = canvas;

    const scaleX = canvasWidth / width;
    const scaleY = canvasHeight / height;

    return { scaleX, scaleY };
  }

  return { scaleX: 1, scaleY: 1 };
}

type CoordsToSVGCoords = {
  point: Coords;
  canvas: FrameCanvas;
  viewContainerRef: RefObject<HTMLDivElement>;
  xMod?: number;
  yMod?: number;
};

export function convertCoordsToSvg({
  point,
  canvas,
  viewContainerRef,
  xMod = 1,
  yMod = 1,
}: CoordsToSVGCoords) {
  if (viewContainerRef.current) {
    const { scaleX, scaleY } = getCoordsToSVGScaleFactor(canvas, viewContainerRef);

    const { x, y } = point;

    return {
      x: x * scaleX * xMod,
      y: y * scaleY * yMod,
    };
  }

  return point;
}

export function changeCustomSpotlightSize(
  points: ParsedPoint[],
  action: 'increase' | 'decrease' = 'increase',
) {
  const currentWidth = getSVGRectWidth(points);
  const { x: xEnd, y: yEnd } = getSVGRectEndPoint(points);

  const multiplyer = action === 'increase' ? 1.1 : 0.9;

  const nextWidth = currentWidth * multiplyer;

  if (nextWidth < 12 && action === 'decrease') {
    return points;
  }

  if (nextWidth > 85 && action === 'increase') {
    return points;
  }

  const nextHeight = nextWidth * heightWidthRatio;

  const widthDelta = (nextWidth - currentWidth) / 2;
  const nextXEnd = xEnd + widthDelta;

  return [
    { x: nextXEnd - nextWidth, y: yEnd - nextHeight },
    { x: xEnd + widthDelta, y: yEnd },
  ];
}

export function getCustomSpotlightCoords(width: number, canvas: FrameCanvas) {
  const { width: canvasWidth, height: canvasHeight } = canvas;

  const height = width * heightWidthRatio;

  const x = (canvasWidth / 2) - (width / 2);
  const y = (canvasHeight / 2) - (height / 2);

  return [
    { x, y },
    { x: x + width, y: y + height },
  ];
}

export function getCustomSpotlight(canvas: FrameCanvas) {
  const spotlightWidth = 30;

  return getCustomSpotlightCoords(spotlightWidth, canvas);
}

type RectTransformProps = {
  viewContainerRef: RefObject<HTMLDivElement>;
  canvas: FrameCanvas;
  transform: Transform | null;
  omitConversion?: boolean;
  xMod?: number;
  yMod?: number;
};

export function getDraggableRectTransform({
  viewContainerRef,
  canvas,
  transform,
  omitConversion = false,
  xMod = 1,
  yMod = 1,
}: RectTransformProps) {
  if (!transform) {
    return 'translate(0, 0)';
  }

  const { scaleX, scaleY, ...rest } = transform;

  if (viewContainerRef.current && !omitConversion) {
    const { x, y } = convertCoordsToSvg({
      canvas,
      viewContainerRef,
      point: rest,
      xMod,
      yMod,
    });

    return `translate(${x}px, ${y}px)`;
  }

  return `translate(${rest.x}, ${rest.y})`;
}
