import { useTranslation } from 'react-i18next';
import { useForm, FormProvider } from 'react-hook-form';
import { Modal, ModalHeader } from '@veo/web-design-system';
import { IPlayer } from 'src/common/model/interfaces/IPlayer';
import { useUserSettings } from 'src/common/hooks/user/useUserSettings';
import { useConfirmation, Confirmation } from 'src/components/confirmation';
import { KeyInfoFormValues, getKeyInfoFormValues } from '../../utils/key-information';
import { KeyInformationForm } from './form';
import styles from './key-information-modal.module.scss';

type Props = {
  open: boolean;
  player: IPlayer;
  onClose(open: boolean): void;
};

export function KeyInformationModal({ open, player, onClose }: Props) {
  const { t } = useTranslation();

  const { data: userSettings, isLoading: isSettingsLoading } = useUserSettings();

  const formConfig = useForm<KeyInfoFormValues>({
    values: getKeyInfoFormValues(player, userSettings?.measurementSystem),
    disabled: !open || isSettingsLoading,
    shouldUseNativeValidation: false,
    mode: 'onBlur',
  });

  const { formState, reset } = formConfig;
  const { isDirty } = formState;

  function closeModalAndReset() {
    onClose(false);
    reset();
  }

  /** Will be shown even without changes since DatePicker is broken - its trigger the change for the same value twice
   * since its using useEffect for internally passed value. Nothing we can do without changing the datepicker itself
   */
  const { triggerAction: confirmAndClose, ...restConfirmationProps } = useConfirmation({
    required: isDirty,
    action: closeModalAndReset,
  });

  return (
    <>
      <Confirmation
        title={t('player_key_information.close_confirmation.title')}
        message={t('player_key_information.close_confirmation.description') as string}
        confirmLabel={t('player_key_information.close_confirmation.confirm_button_label') as string}
        cancelLabel={t('player_key_information.close_confirmation.cancel_button_label') as string}
        {...restConfirmationProps}
      />
      <Modal
        open={open}
        onClose={confirmAndClose}
        className={styles.modal}
      >
        <ModalHeader
          title={t('player_key_information.heading')}
          description={t('player_key_information.description') as string}
        />
        <FormProvider<KeyInfoFormValues> {...formConfig}>
          <KeyInformationForm
            onCancel={confirmAndClose}
            onSubmit={closeModalAndReset}
          />
        </FormProvider>
      </Modal>
    </>
  );
}
