import { ValueOf } from 'src/common/model/types/helper.type';
import { OutputFormats } from './constants';

export type AspectCrop = {
  aspect?: number;
};

export type DimensionCrop = {
  outputWidth: number;
  outputHeight: number;
};

export type CropConfig = AspectCrop | DimensionCrop;

export type EditableImage = {
  width: number;
  height: number;
  size: number;
  type: string;
  name: string;
  url: string;
};

export type OutputFormat = ValueOf<typeof OutputFormats>;

export type OutputFormatConfig = {
  format: OutputFormat;
};

export type OutputImageConfig = OutputFormatConfig | (OutputFormatConfig & DimensionCrop);

export function isAspectCrop(config: unknown): config is AspectCrop {
  if (typeof config === 'object' && config && 'aspect' in config) {
    return !!(config as AspectCrop).aspect;
  }

  return false;
}

export function isDimensionCrop(config: unknown): config is DimensionCrop {
  if (typeof config === 'object' && config && 'outputWidth' in config && 'outputHeight' in config) {
    return !!(config as DimensionCrop).outputHeight && !!(config as DimensionCrop).outputWidth;
  }

  return false;
}

export function isCropConfig(config: unknown): config is CropConfig {
  return isAspectCrop(config) || isDimensionCrop(config);
}
