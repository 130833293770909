/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable @typescript-eslint/no-use-before-define */
import {
  useRef,
  useState,
  useMemo,
  useEffect,
  forwardRef,
  SyntheticEvent,
  Children,
} from 'react';
import cn from 'classnames';
import { useHover } from 'src/common/hooks/useHover';
import { mergeRefs } from 'src/common/utils/refs';
import { useMobileDevice } from 'src/common/hooks/useMobileDevice';
import { useResizeObserver } from 'src/common/hooks/useResizeObserver';
import { DefaultControls } from './controls/default-controls';
import {
  togglePlayVideo,
  seekVideo,
  enterFullscreen,
  restartVideo,
  playVideo,
  pauseVideo,
} from './utils/player-actions';
import { useFullscreenEvents } from './hooks/useFullscreenEvents';
import { VeoPlayerProps, VeoPlayerRendererProps, VideoControls } from './types/veo-player.type';
import styles from './veo-player.module.scss';

function noopFn() {}

type VideoSize = {
  width: number;
  height: number;
};

const defaultControlsConfig: VideoControls = {
  play: true,
  next: false,
  previous: false,
  fullscreen: false,
};

export const VeoPlayer = forwardRef<HTMLVideoElement, VeoPlayerProps>(({
  src = '',
  poster,
  autoPlay = true,
  playsInline = true,
  loop = false,
  controls = true,
  preload = 'metadata',
  videoFit = 'cover',
  className,
  videoControls = defaultControlsConfig,
  onVideoStarted = noopFn,
  onVideoEnded = noopFn,
  onVideoPlay = noopFn,
  onVideoPause = noopFn,
  onToggleFullscreen = noopFn,
  onHover = noopFn,
  onPlayNext = noopFn,
  onPlayPrevious = noopFn,
  children,
  overlayRenderer: CustomOverlay,
  controlsRenderer: CustomControls,
  containerClassName,
  ...restVideoProps
}, ref) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [fullscreen, setFullscreen] = useState(false);
  const [videoSize, setVideoSize] = useState<VideoSize | null>(null);

  const playerRef = useRef<HTMLVideoElement>(null);
  const controlsContainerRef = useRef<HTMLDivElement>(null);
  const overlayRef = useRef<HTMLDivElement>(null);

  const { isMobile } = useMobileDevice();
  const [hoverRef, isHovered] = useHover<HTMLElement>(!isMobile);

  const [playerContainerRef, containerSize] = useResizeObserver<HTMLVideoElement>();

  // Track click event on video to play/pause video
  useEffect(() => {
    function clickListener(event: MouseEvent) {
      if (!overlayRef.current || event.target !== overlayRef.current) {
        return;
      }

      togglePlay();
    }

    document.addEventListener('click', clickListener, false);

    function playListener() {
      setIsPlaying(true);
    }

    function pauseListener() {
      setIsPlaying(false);
    }

    if (playerRef.current) {
      playerRef.current.addEventListener('play', playListener);
      playerRef.current.addEventListener('pause', pauseListener);
    }

    return () => {
      document.removeEventListener('click', clickListener);

      if (playerRef.current) {
        playerRef.current.removeEventListener('play', playListener);
        playerRef.current.removeEventListener('pause', pauseListener);
      }
    };
  }, []);

  /** Track whether new video play started */
  useEffect(() => {
    if (src && src !== '') {
      onVideoStarted(src);
    }
  }, [src]);

  /** Notify parent about video were hovered for non-mobile devices */
  useEffect(() => {
    onHover(isHovered);
  }, [isHovered]);

  /** Notify on fullscreen mode change */
  useEffect(() => {
    onToggleFullscreen(fullscreen);
  }, [fullscreen]);

  useFullscreenEvents(playerRef, {
    onEnter: handleEnterFullscreen,
    onExit: handleExitFullscreen,
  });

  function calculateVideoElementSize() {
    if (!videoSize || !containerSize) {
      return {
        width: 0,
        height: 0,
        left: 0,
        right: 0,
      };
    }

    const { width, height } = containerSize;

    const videoAspectRatio = videoSize.width / videoSize.height;
    const containerApspectRatio = width / height;

    if (!height) {
      const videoHeight = width / videoAspectRatio;

      return {
        width,
        height: videoHeight,
        top: 0,
        left: 0,
      };
    }

    const showTopPads = videoAspectRatio > containerApspectRatio;

    if (!showTopPads || videoFit === 'fit') {
      const videoWidth = videoAspectRatio * height;
      const left = (width - videoWidth) / 2;

      return {
        height,
        width: videoWidth,
        left,
        top: 0,
      };
    }

    const videoHeight = width / videoAspectRatio;
    const top = (height - videoHeight) / 2;

    return {
      width,
      height: videoHeight,
      left: 0,
      top,
    };
  }

  function handleRestartVideo() {
    restartVideo(playerRef);
  }

  function handleEnterFullscreen() {
    setFullscreen(true);
  }

  function handleExitFullscreen() {
    setFullscreen(false);
  }

  function openFullscreen() {
    enterFullscreen(playerRef);
  }

  function handlePlayVideo() {
    playVideo(playerRef);
  }

  function handlePauseVideo() {
    pauseVideo(playerRef);
  }

  function togglePlay() {
    togglePlayVideo(playerRef);
  }

  async function handleSeekVideo(timestamp: number) {
    const resolvedTimestamp = await seekVideo(playerRef, timestamp);

    return resolvedTimestamp;
  }

  function handleVideoEnded() {
    if (!loop) {
      setIsPlaying(false);
      onVideoEnded();
    }
  }

  function registerVideoSize() {
    if (playerRef.current) {
      setVideoSize({
        width: playerRef.current.videoWidth,
        height: playerRef.current.videoHeight,
      });
    }
  }

  function handleMetaDataLoaded(e: SyntheticEvent<HTMLVideoElement, Event>) {
    registerVideoSize();

    if (autoPlay) {
      handlePlayVideo();
    }

    if (restVideoProps.onLoadedMetadata) {
      restVideoProps.onLoadedMetadata(e);
    }
  }

  const customRendererProps: VeoPlayerRendererProps = useMemo(() => ({
    videoRef: playerRef,
    containerRef: controlsContainerRef,
    isPlaying,
    videoControls,
    restartVideo: handleRestartVideo,
    requestFullscreen: openFullscreen,
    playNextVideo: onPlayNext,
    playPreviousVideo: onPlayPrevious,
    pauseVideo: handlePauseVideo,
    playVideo: handlePlayVideo,
    seekVideo: handleSeekVideo,
  }), [fullscreen, isPlaying, src]);

  const renderOverlay = () => {
    if (!CustomOverlay) {
      return null;
    }

    return <CustomOverlay {...customRendererProps} />;
  };

  const renderControls = () => {
    if (!CustomControls) {
      return <DefaultControls {...customRendererProps} />;
    }

    return <CustomControls {...customRendererProps} />;
  };

  const containerClassNames = cn(styles.container, containerClassName);

  const videoClassnames = cn(styles.video, className);

  const overlayClassnames = cn(
    styles.overlay,
    {
      [styles.overlay__tinted]: isHovered || !isPlaying,
      [styles.overlay__fullscreen]: fullscreen,
    },
  );

  const controlsClassnames = cn(
    styles.controls,
    {
      [styles.controls__hidden]: !(isHovered || !isPlaying),
      [styles.controls__mobile]: isMobile,
    },
  );

  const videoStyles = useMemo(() => calculateVideoElementSize(), [containerSize, videoSize]);

  return (
    <figure
      ref={mergeRefs([hoverRef, playerContainerRef])}
      className={containerClassNames}
    >
      <div
        className={styles['video-container']}
        style={videoStyles}
      >
        <video
          ref={mergeRefs([playerRef, ref])}
          {...restVideoProps}
          autoPlay={false}
          playsInline={playsInline}
          preload={preload}
          muted
          src={src}
          loop={loop}
          poster={poster}
          onLoadedMetadata={handleMetaDataLoaded}
          className={videoClassnames}
          onPause={onVideoPause}
          onPlay={onVideoPlay}
          onEnded={handleVideoEnded}
        />
        {
        Children.count(children) !== 0 && (
          <div className={styles['video-overlay']}>
            { children }
          </div>
        )
      }
      </div>
      <div
        ref={overlayRef}
        className={overlayClassnames}
      >
        { renderOverlay() }
        { children }
        <div
          ref={controlsContainerRef}
          className={controlsClassnames}
        >
          { controls && renderControls() }
        </div>
      </div>
    </figure>
  );
});

export { DefaultControls as VeoPlayerControls };
