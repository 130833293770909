/* eslint-disable react/jsx-props-no-spreading */
import { forwardRef } from 'react';
import { CrestPlaceholder, Text, Icon } from '@veo/web-design-system';
import { IClub } from 'src/common/model/interfaces/IClub';
import styles from './item.module.scss';

type Props = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  clubhouse?: IClub;
  open?: boolean;
};

export const ClubhouseSelectorItem = forwardRef(({
  clubhouse,
  open = false,
  ...buttonProps
}: Props, ref: React.ForwardedRef<HTMLButtonElement>) => {
  if (!clubhouse) {
    return null;
  }

  const crest = clubhouse.crest ? (
    <img
      src={clubhouse.crest}
      className={styles['clubhouse-selector-item__crest']}
      alt={`${clubhouse.name} crest`}
    />
  ) : (
    <CrestPlaceholder size="xs" />
  );

  return (
    <li>
      <button
        type="button"
        ref={ref}
        {...buttonProps}
        className={styles['clubhouse-selector-item__button']}
        role="menuitem"
      >
        {crest}
        <Text type="div" weight="bold" className={styles['clubhouse-selector-item__name']}>
          {clubhouse.name}
        </Text>
        <Icon name={open ? 'chevron-up' : 'chevron-down'} size="xs" />
      </button>
    </li>
  );
});
