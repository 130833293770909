import { ReactElement, useState } from 'react';
import cn from 'classnames';
import { IconButton, Text } from '@veo/web-design-system';
import styles from './overlay-hint.module.scss';

type Props = {
  show?: boolean;
  className?: string;
  label: string;
  children: ReactElement;
};

export function OverlayHint({
  className, label, show = true, children,
}: Props) {
  const [showBottom, setShowBottom] = useState(false);

  function toggleHintPlacement() {
    setShowBottom((prev) => !prev);
  }

  const containerClassnames = cn(
    styles.container,
    className,
    { [styles.bottom]: showBottom },
  );

  if (!show) {
    return null;
  }

  return (
    <div className={containerClassnames}>
      <div className={styles.wrapper}>
        <IconButton
          className={styles.positioner}
          size="xs"
          variant="text"
          icon={showBottom ? 'chevrons-up' : 'chevrons-down'}
          onClick={toggleHintPlacement}
        />
        <Text size="caption" type="block" className={styles.hint}>{label}</Text>
        {children}
      </div>
    </div>
  );
}
