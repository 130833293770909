import { RefObject } from 'react';
import { CapturedData } from '../models/captured-frame';

export function formatFloat(value: number, precision?: number) {
  return parseFloat(value.toFixed(precision ?? 1));
}

/** Make a square screenshot preserving the original video aspect ratio (stretched on Y axis) */
export function captureStrechedFrame(videoRef: RefObject<HTMLVideoElement>, outputSize: number): Promise<CapturedData> {
  return new Promise((resolve, reject) => {
    if (!videoRef.current) {
      reject(new Error('Video is not provided'));
      return;
    }

    // Create a canvas and draw the current frame from the video on it.
    const canvas = document.createElement('canvas');
    canvas.width = outputSize;
    canvas.height = outputSize;
    const ctx = canvas.getContext('2d')!;

    // Draw the video frame on the canvas, stretching or shrinking it to fit
    ctx.drawImage(videoRef.current, 0, 0, outputSize, outputSize);

    canvas.toBlob((blob) => {
      if (!blob) {
        reject(new Error('Failed to proccess the video frame. Please try again later.'));
        return;
      }

      // Create a Blob URL
      const url = URL.createObjectURL(blob);

      // Resolve the Promise with the Blob URL
      resolve({ url, data: blob });
    }, 'image/jpeg');
  });
}
