import { useQuery } from 'react-query';
import { getUserClubs } from 'src/common/apis/zola.api';
import { RQ_KEY, EXTENDED_CACHE_TIME_MS } from 'src/common/constants/react-query';
import { IClub } from 'src/common/model/interfaces/IClub';
import { RequesterError } from 'src/common/model/interfaces/IRequesterError';
import { useOidcAuth } from 'src/features/oidc';

export function useUserClubs() {
  const { data: user } = useOidcAuth();

  return useQuery<IClub[], RequesterError>(
    [RQ_KEY.OIDC_USER_CLUBS],
    () => getUserClubs(),
    {
      enabled: !!user,
      cacheTime: EXTENDED_CACHE_TIME_MS,
      staleTime: EXTENDED_CACHE_TIME_MS,
    },
  );
}
