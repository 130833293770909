import { OptionProps, components } from 'react-select';
import { Icon } from '@veo/web-design-system';
import cn from 'classnames';
import styles from '../dropdown.module.scss';

export function Option<Option, IsMulti extends boolean>(props: OptionProps<Option, IsMulti>) {
  const {
    children, isDisabled, data, isFocused, isSelected, ...restProps
  } = props;

  const optionClassnames = cn(
    styles.option,
    {
      [styles.option__disabled]: isDisabled,
    },
  );

  const optionProps = {
    isDisabled,
    isFocused,
    isSelected,
    data,
    className: optionClassnames,
    ...restProps,
  };

  const optionContent = (
    <div className={styles['option-wrapper']}>
      <div>{ children }</div>
      { isSelected && <Icon size="xs" name="check" />}
    </div>
  );

  return (
    <components.Option {...optionProps}>
      { optionContent }
    </components.Option>
  );
}
