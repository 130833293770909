import { has } from 'lodash-es';
import { FilterParams } from 'src/common/hooks/useQueryParams';

const FILTER_NAMES_MAP = {
  MATCH: 'matchId',
  BOOKMARKS: 'bookmarked',
  MENTIONS: 'involvedPlayer',
  CREATOR: 'creator',
};

export function getAppliedFilterNames(filter: FilterParams) {
  const filterNames = Object.values(FILTER_NAMES_MAP)
    .filter((fName) => has(filter, fName))
    .join(', ');

  return filterNames;
}

export function getAppliedFilters(filter: FilterParams) {
  const isMatch = has(filter, FILTER_NAMES_MAP.MATCH);
  const isBookmarks = has(filter, FILTER_NAMES_MAP.BOOKMARKS);
  const isMentions = has(filter, FILTER_NAMES_MAP.MENTIONS);
  const isCreator = has(filter, FILTER_NAMES_MAP.CREATOR);

  return {
    isMatch,
    isBookmarks,
    isMentions,
    isCreator,
  };
}
