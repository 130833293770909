import { RefObject } from 'react';
import { useTranslation } from 'react-i18next';
import { Tag, Button } from '@veo/web-design-system';
import { ITelestration } from 'src/common/model/interfaces/ITelestration';
import { Nullable } from 'src/common/model/types/helper.type';
import { seekVideo } from 'src/components/veo-player/utils/player-actions';
import { formatTelestrationTimestamp } from '../../utils/parsed-telestration';
import { useTelestrationDebug } from '../debug-panel/debug-context';
import styles from './telestration-actions.module.scss';

type Props = {
  videoRef: RefObject<HTMLVideoElement>;
  telestration: Nullable<ITelestration>;
  isCapturing: boolean;
  isPickingSpotlight: boolean;
  onFrameCapture(): Promise<void>;
  onRemove(payload: ITelestration): void;
};

export function TelestrationActions({
  videoRef,
  telestration,
  isCapturing,
  isPickingSpotlight,
  onFrameCapture,
  onRemove,
}: Props) {
  const { t } = useTranslation();

  const { resetValues } = useTelestrationDebug();

  function handleRemoveSpotlight() {
    if (!telestration) {
      return;
    }

    resetValues();

    onRemove(telestration);
  }

  function handleSeekTelestration(timestamp: number) {
    const expectedTimestamp = Math.max(0, timestamp - 1);

    seekVideo(videoRef, expectedTimestamp);
  }

  if (telestration && !isCapturing) {
    return (
      <div className={styles.container}>
        {
          telestration.items.map((item) => {
            const { id, timestamp } = item;

            const label = t('telestration.edit.spotlight_timestamp', { timestamp: formatTelestrationTimestamp(timestamp) });

            return (
              <Tag
                key={id}
                label={label}
                iconEnd="x"
                onClick={() => handleSeekTelestration(timestamp)}
                onIconEndClick={handleRemoveSpotlight}
              />
            );
          })
        }
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <Button
        label={t('telestration.frame_capture.add_button_label')}
        loading={isCapturing}
        disabled={isCapturing || isPickingSpotlight}
        variant="secondary"
        size="md"
        iconEnd="plus"
        onClick={onFrameCapture}
      />
    </div>
  );
}
