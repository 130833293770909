import { ReactElement, forwardRef } from 'react';
import cn from 'classnames';
import ReactSelect, { ActionMeta, MultiValue, SingleValue } from 'react-select';
import { ClearIndicator } from './components/clear-indicator';
import { DropdownIndicator } from './components/dropdown-indicator';
import { Menu } from './components/menu';
import { Option } from './components/option';
import { Control } from './components/control';
import { Placeholder } from './components/placeholder';
import { ValueContainer } from './components/value-container';
import { MultiValueContainer } from './components/multi-value-container';
import { SingleDropdownProps, MultiDropdownProps, DefaultOption } from './dropdown.type';
import styles from './dropdown.module.scss';

type Props<O> = SingleDropdownProps<O> | MultiDropdownProps<O>;

function DropdownCmp<OptionType extends object = DefaultOption<string>>(
  props: Props<OptionType>,
  ref: any,
) {
  const {
    multi = false,
    className,
    clearable = false,
    searchable = false,
    disabled = false,
    onChange,
    id,
    ...rest
  } = props;

  function handleChange(
    v: MultiValue<OptionType> | SingleValue<OptionType>,
    meta: ActionMeta<OptionType>,
  ) {
    const actionName = meta.action;

    onChange(v as OptionType & readonly OptionType[], actionName);
  }

  const dropdownClassnames = cn(
    styles.dropdown,
    { [styles.dropdown__disabled]: disabled },
    className,
  );

  return (
    <ReactSelect<OptionType, boolean>
      ref={ref}
      menuPlacement="auto"
      {...rest}
      isMulti={multi}
      isClearable={clearable}
      isDisabled={disabled}
      isSearchable={searchable}
      inputId={id}
      className={dropdownClassnames}
      unstyled
      components={{
        ClearIndicator,
        DropdownIndicator,
        Menu,
        Option,
        Control,
        Placeholder,
        ValueContainer,
        MultiValueContainer,
      }}
      onChange={handleChange}
    />
  );
}

// eslint-disable-next-line max-len
export const Dropdown = forwardRef(DropdownCmp) as <OptionType extends object = DefaultOption<string>>(props: Props<OptionType> & { ref?: any }) => ReactElement;
