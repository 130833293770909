import { RefObject, useEffect } from 'react';
import { getIsFullscreen } from '../utils/player-actions';

type OptionsType = {
  onEnter: VoidFunction;
  onExit: VoidFunction;
};

export function useFullscreenEvents(
  ref: RefObject<HTMLVideoElement>,
  {
    onEnter,
    onExit,
  }: OptionsType,
) {
  useEffect(() => {
    const fullscreenHandler = () => {
      const isFullscreen = getIsFullscreen();

      if (isFullscreen) {
        onEnter();
      } else {
        onExit();
      }
    };

    const iosEnterHandler = () => {
      onEnter();
    };

    const iosExitHandler = () => {
      onExit();
    };

    document.addEventListener('fullscreenchange', fullscreenHandler);
    document.addEventListener('webkitfullscreenchange', fullscreenHandler);
    document.addEventListener('mozfullscreenchange', fullscreenHandler);
    document.addEventListener('msfullscreenchange', fullscreenHandler);
    ref.current?.addEventListener('webkitendfullscreen', iosExitHandler, false);
    ref.current?.addEventListener('webkitbeginfullscreen', iosEnterHandler, false);

    return () => {
      document.removeEventListener('fullscreenchange', fullscreenHandler);
      document.removeEventListener('webkitfullscreenchange', fullscreenHandler);
      document.removeEventListener('mozfullscreenchange', fullscreenHandler);
      document.removeEventListener('msfullscreenchange', fullscreenHandler);
      ref.current?.removeEventListener('webkitendfullscreen', iosExitHandler);
      ref.current?.removeEventListener('webkitbeginfullscreen', iosEnterHandler);
    };
  }, []);
}
