import i18n from 'src/common/services/i18next';
import { omitBy, isNull, isEmpty } from 'lodash-es';
import { IPlayer } from 'src/common/model/interfaces/IPlayer';
import { formatMetricHeight, formatMetriclWeight } from 'src/common/utils/measurement-units';
import { calculateAge } from 'src/common/utils/luxon-utils';
import { IAbility } from 'src/common/model/interfaces/IAbility';

const OgDesctiptionKeyMap = {
  height: 'Height',
  weight: 'Weight',
  age: 'Age',
  strongFoot: 'Strong foot',
  graduationYear: 'Graduation Year',
  gpa: 'GPA',
  position: 'Preferred position',
};

// todo: Rename everything with Category or Highlight
type OgDescriptionKey = keyof typeof OgDesctiptionKeyMap;

export function getProfileTitle() {
  return 'Veo | Player profile';
}

export function getPlayerProfileTitle(player: IPlayer) {
  return `Veo | ${player.firstName} ${player.lastName}'s Player Profile`;
}

export function getProfileDescription() {
  return 'Watch top highlights and profile information.'
    + ' All highlights are captured using Veo\'s intelligent recording solution';
}

export function getOgProfileDescription(player: IPlayer) {
  const {
    about,
    firstName,
    lastName,
  } = player;

  if (about) {
    return about;
  }

  const keyInfoValues = {
    age: player.birthday ? calculateAge(player.birthday) : null,
    height: formatMetricHeight(player.height),
    weight: formatMetriclWeight(player.weight),
    strongFoot: i18n.t(`entity.strong_foot.${player.strongFoot}`, { defaultValue: null }),
    graduationYear: player.graduationYear,
    gpa: player.gpa,
    position: i18n.t(`entity.position.${player.position}`, { defaultValue: null }),
  };

  const filteredData = omitBy(keyInfoValues, isNull);

  if (isEmpty(filteredData)) {
    return `Check out ${firstName} ${lastName} Player Profile for highlights and stats`;
  }

  return Object.entries(filteredData)
    .map(([key, value]) => `${OgDesctiptionKeyMap[key as OgDescriptionKey]}: ${value}`)
    .join(', ');
}

export function getPlayerProfileDescription(player: IPlayer) {
  const playerPosition = player.position
    ? i18n.t(`entity.position.${player.position}`)
    : 'Player';

  const clubTeam = `${player.team?.club.name}, ${player.team?.name}`;

  return `Watch ${player.firstName}’s video highlights on Veo. ${playerPosition} at ${clubTeam}.`;
}

export function getPlayerProfileDescriptionWithHighlights(player: IPlayer, abilities: IAbility[]) {
  const baseDescription = getPlayerProfileDescription(player);

  const categoryNames = abilities.map(({ code }) => i18n.t(`entity.ability.${code}`));

  const hlsDescription = categoryNames.length
    ? ` Highlights include ${categoryNames.join(', ')}.`
    : '';

  return `${baseDescription}${hlsDescription}`;
}
