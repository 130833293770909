import { useEffect } from 'react';

export type MutationObserverProps<T> = {
  /** Element where to track the Dom Mutations */
  root?: T;
  /** Mutation observer params */
  options?: MutationObserverInit;
  /** Ability to pause observer */
  enabled?: boolean;
  /** Callback that will be invoked for mutation */
  onMutate: MutationCallback;
};

export function useMutationObserver<T extends HTMLElement = HTMLBodyElement>(props: MutationObserverProps<T>) {
  const {
    root,
    options,
    enabled = true,
    onMutate,
  } = props;

  useEffect(() => {
    const rootElement = root ?? document.body;

    const observer = new MutationObserver(onMutate);

    if (enabled) {
      observer.observe(rootElement, options);
    }

    return () => {
      observer.disconnect();
    };
  }, [enabled]);
}
