import { centerCrop, makeAspectCrop, Crop } from 'react-image-crop';
import {
  EditableImage,
  CropConfig,
  OutputImageConfig,
  isAspectCrop,
  isDimensionCrop,
} from './types';

export function centerAspectCrop(
  mediaWidth: number,
  mediaHeight: number,
  aspect: number,
) {
  const percentCenterCrop = centerCrop(
    makeAspectCrop(
      {
        unit: '%',
        width: 100,
      },
      aspect,
      mediaWidth,
      mediaHeight,
    ),
    mediaWidth,
    mediaHeight,
  );

  return percentCenterCrop;
}

export function getEditableImage(file: File): Promise<EditableImage> {
  return new Promise((resolve, reject) => {
    const { size, type, name } = file;

    const img = new Image();

    const payload = {
      size,
      type,
      name,
    };

    img.onload = () => {
      resolve({
        ...payload, url: img.src, width: img.width, height: img.height,
      });
    };

    img.onerror = () => {
      reject(new Error('Failed to load image'));
    };

    img.src = URL.createObjectURL(file);
  });
}

export function getCroppedImage(image: HTMLImageElement, crop: Crop, config: OutputImageConfig) {
  const { format, ...restConfig } = config;

  const scaleX = image.naturalWidth / image.width;
  const scaleY = image.naturalHeight / image.height;

  let resultWidth = crop.width! * scaleX;
  let resultHeight = crop.height! * scaleY;

  if (isDimensionCrop(restConfig)) {
    resultWidth = restConfig.outputWidth;
    resultHeight = restConfig.outputHeight;
  }

  const canvas = document.createElement('canvas');
  canvas.width = resultWidth;
  canvas.height = resultHeight;
  const ctx = canvas.getContext('2d')!;

  ctx.drawImage(
    image,
    crop.x! * scaleX,
    crop.y! * scaleY,
    crop.width! * scaleX,
    crop.height! * scaleY,
    0,
    0,
    resultWidth,
    resultHeight,
  );

  return new Promise((resolve, reject) => {
    canvas.toBlob((blob) => {
      if (!blob) {
        reject(new Error('Failed to save image'));
      }

      resolve(blob);
    }, format);
  });
}

export function getCropAspect(config: CropConfig) {
  if (isAspectCrop(config) && config.aspect) {
    return config.aspect;
  }

  if (isDimensionCrop(config)) {
    return config.outputWidth / config.outputHeight;
  }

  return undefined;
}
