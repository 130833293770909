import { IAbility } from 'src/common/model/interfaces/IAbility';
import { playerSvc } from 'src/common/services/player-svc';

export async function getAbilities(): Promise<IAbility[]> {
  const url = '/abilities';

  const data = await playerSvc.request({ method: 'GET', url }) as IAbility[];

  return data;
}

export async function getPlayerAbilities(playerId: string): Promise<IAbility[]> {
  const url = `/abilities/by/playerId/${playerId}`;

  const data = await playerSvc.request({ method: 'GET', url }) as IAbility[];

  return data;
}
