import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { Button } from '@veo/web-design-system';
import { IPlayerClip } from 'src/common/model/interfaces/IPlayerClip';
import { Nullable } from 'src/common/model/types/helper.type';
import { PlayerClipDetails } from 'src/features/player-clip-details/player-clip-details';
import styles from './clip-details.module.scss';

type Props = {
  playerClip: Nullable<IPlayerClip>;
  isPublishing: boolean;
  className?: string;
  onPlayerClipChange(pc: IPlayerClip): void;
  onGoBack: VoidFunction;
  onPublished: VoidFunction
};

export function ClipDetails({
  playerClip,
  className,
  isPublishing,
  onGoBack,
  onPublished,
  onPlayerClipChange,
}: Props) {
  const { t } = useTranslation();

  if (!playerClip) {
    return null;
  }

  return (
    <div className={cn(styles.container, className)}>
      <PlayerClipDetails
        className={styles.details}
        playerClip={playerClip}
        onChange={onPlayerClipChange}
      />
      <div className={styles.actions}>
        <Button
          label={t('add_player_clips.stepper.prev_button_label')}
          variant="outlined"
          iconStart="arrow-left"
          onClick={onGoBack}
        />
        <Button
          label={t('add_player_clips.stepper.publish_button_label')}
          variant="secondary"
          iconEnd="check"
          loading={isPublishing}
          disabled={isPublishing}
          onClick={onPublished}
        />
      </div>
    </div>
  );
}
