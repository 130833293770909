import cn from 'classnames';
import { TelestrationSVGIndicator } from 'src/components/telestration/indicator';
import styles from './telestration-video-controls.module.scss';

type Props = {
  className?: string;
  timestamp: number;
  duration: number;
};

export function SpotlightIndicator({ className, timestamp, duration }: Props) {
  const containerClassnames = cn(styles.indicator, className);

  const leftPosition = (timestamp / duration) * 100;

  return (
    <div className={containerClassnames} style={{ left: `${leftPosition}%` }}>
      <TelestrationSVGIndicator />
    </div>
  );
}
