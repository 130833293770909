import {
  createContext, useContext, useEffect, useMemo, useState,
} from 'react';
import { Analytics, AnalyticsBrowser } from '@segment/analytics-next';
import { config } from 'src/config';
import {
  AnalyticEventsType, ANALYTIC_EVENTS, EventsStatusType, EVENTS_STATUS,
} from '../constants/segment-events';

type AnalyticsContextType = {
  analytics: Analytics | null;
  events: Record<keyof typeof ANALYTIC_EVENTS, AnalyticEventsType>;
  statuses: Record<keyof typeof EVENTS_STATUS, EventsStatusType>;
};

const defaultAnalyticsContext = {
  analytics: null,
  events: {},
  statuses: {},
} as AnalyticsContextType;

const AnalyticsContext = createContext<AnalyticsContextType>(
  defaultAnalyticsContext,
);

export function AnalyticsProvider(props: Record<string, unknown>) {
  const [analytics, setAnalytics] = useState<Analytics | null>(null);

  useEffect(() => {
    async function loadAnalytics() {
      if (!config.VEO_ANALYTICS_WRITE_KEY || analytics) {
        return;
      }

      const [response] = await AnalyticsBrowser.load({ writeKey: config.VEO_ANALYTICS_WRITE_KEY });
      setAnalytics(response);
    }

    loadAnalytics();
  }, []);

  const analyticsValue = useMemo(() => ({
    analytics,
    events: ANALYTIC_EVENTS,
    statuses: EVENTS_STATUS,
  }), [analytics]);

  return (
    <AnalyticsContext.Provider value={analyticsValue} {...props} />
  );
}

export function useAnalytics() {
  return useContext(AnalyticsContext);
}
