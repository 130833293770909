import { Helmet } from 'react-helmet';
import { useImage } from 'src/common/hooks/useImage';
import { IPlayer } from 'src/common/model/interfaces/IPlayer';
import { PROFILE_OG_PLACEHOLDER_IMAGE } from 'src/common/constants/player';
import { getOgProfileDescription } from '../../utils/meta-helpers';

type Props = {
  player?: IPlayer;
};

export function OgMeta({ player }: Props) {
  const image = useImage(player?.image ?? '', PROFILE_OG_PLACEHOLDER_IMAGE);

  if (!player) {
    return null;
  }

  const {
    firstName,
    lastName,
    twitter,
  } = player;

  const title = `${firstName} ${lastName}'s "Player Profile on Veo"`;
  const description = getOgProfileDescription(player);
  const imageAlt = `${firstName} ${lastName}'s soccer player profile`;

  return (
    <Helmet>
      <meta property="og:title" content={title} />
      <meta property="og:type" content="website" />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={image.imageSrc} />
      <meta property="og:url" content={window.location.href} />
      <meta property="og:image:alt" content={imageAlt} />
      {/* twitter specific */}
      <meta name="twitter:card" content="summary" />
      { twitter && <meta name="twitter:site" content={`@${twitter}`} />}
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={image.imageSrc} />
      <meta name="twitter:image:alt" content={imageAlt} />
    </Helmet>
  );
}
