import { useMemo } from 'react';
import { useInfiniteQuery } from 'react-query';
import { getAuthPlayerZolaClips } from 'src/common/apis/zola-clips';
import { RQ_KEY } from 'src/common/constants/react-query';
import { IPaginatedResponse } from 'src/common/model/interfaces/IPaginatedResponse';
import { IZolaClip } from 'src/common/model/interfaces/IZolaClip';
import { RequesterError } from 'src/common/model/interfaces/IRequesterError';
import { useAuthPlayer } from 'src/features/auth';
import { useAuthPlayerTeams } from '../player/useAuthPlayerTeams';
import { QueryParams } from '../useQueryParams';

const defaultQueryParams: QueryParams = {
  page: 1,
  pageSize: 10,
  filter: null,
  search: null,
  sort: null,
};

export function useAuthPlayerZolaClips(queryParams: QueryParams = defaultQueryParams) {
  const { filter, pageSize } = queryParams;

  const { data: playerTeams } = useAuthPlayerTeams();
  const { data: authPlayer } = useAuthPlayer();

  // Player team ids for hard filtering
  const playerTeamIds = useMemo(() => playerTeams?.map(({ id }) => id), [playerTeams]);

  return useInfiniteQuery<IPaginatedResponse<IZolaClip>, RequesterError>(
    [RQ_KEY.AUTH_PLAYER_ZOLA_CLIPS, { filter, playerTeamIds }],
    ({ signal, pageParam = 1 }) => getAuthPlayerZolaClips(
      authPlayer?.id as string,
      { pageSize, page: pageParam, filter: { ...filter, teamId: playerTeamIds! } },
      signal,
    ),
    {
      enabled: !!authPlayer && !!playerTeamIds?.length,
      getNextPageParam: (lastPage) => {
        const totalPages = Math.ceil(lastPage.meta.totalCount / pageSize);
        const nextPageExists = totalPages > lastPage.meta.pageNumber;

        return nextPageExists ? lastPage.meta.pageNumber + 1 : undefined;
      },
    },
  );
}
