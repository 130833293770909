import { useTranslation } from 'react-i18next';
import { IconName, Icon, Text } from '@veo/web-design-system';
import { Paper } from 'src/components/paper/paper';
import { TextSkeleton } from 'src/components/skeletons/text-skeleton';
import { FilterParams } from 'src/common/hooks/useQueryParams';
import { useAuthPlayerZolaClips } from 'src/common/hooks/zola-clips/useAuthPlayerZolaClips';
import styles from './quick-filters.module.scss';

type Props = {
  label: string;
  icon: IconName;
  filter: FilterParams;
  onClick(filter: FilterParams): void;
};

export function FilterItem({
  label,
  icon,
  filter,
  onClick,
}: Props) {
  const { t } = useTranslation();

  const { data: zolaClips, isLoading } = useAuthPlayerZolaClips({
    filter, pageSize: 10, page: 1, search: null, sort: null,
  });

  return (
    <Paper
      role="button"
      tabIndex={0}
      elevation={2}
      className={styles.item}
      onClick={() => onClick(filter)}
    >
      <Paper elevation={3} className={styles['icon-wrapper']}>
        <Icon name={icon} size="lg" />
      </Paper>
      <div className={styles.info}>
        <Text
          type="block"
          size="h6"
          className={styles['filter-title']}
          weight="semiBold"
        >
          {label}
        </Text>
        {
          !isLoading && (
            <Text
              className={styles.count}
              type="block"
              weight="medium"
            >
              { t('add_player_clips.filters.count', { count: zolaClips?.pages[0]?.meta.totalCount }) }
            </Text>
          )
        }
        { isLoading && (<TextSkeleton words={1} />)}
      </div>
    </Paper>
  );
}
