/* eslint-disable react/jsx-props-no-spreading */
import {
  ForwardedRef, HTMLAttributes, MouseEventHandler, ReactNode, forwardRef,
} from 'react';
import cn from 'classnames';
import styles from './ability.module.scss';

type Props = {
  children: ReactNode;
  className?: string;
  active?: boolean;
  onClick?: VoidFunction;
};

function Tag(
  {
    children,
    className,
    active = false,
    onClick,
  }: Props,
  ref: ForwardedRef<HTMLDivElement>,
) {
  const handleClick: MouseEventHandler<HTMLDivElement> = (e) => {
    e.preventDefault();
    e.stopPropagation();

    e.currentTarget.scrollIntoView({
      behavior: 'smooth',
      block: 'nearest',
    });

    if (onClick) {
      onClick();
    }
  };

  const tagClassnames = cn(
    styles.tag,
    {
      [styles.interactive]: !!onClick,
      [styles.active]: active,
    },
    className,
  );

  const containerProps: HTMLAttributes<HTMLDivElement> = onClick
    ? {
      role: 'button',
      tabIndex: 0,
      className: tagClassnames,
      onClick: handleClick,
    }
    : { className: tagClassnames };

  return (
    <div ref={ref} {...containerProps}>
      { children }
    </div>
  );
}

export const AbilityTag = forwardRef<HTMLDivElement, Props>(Tag);
