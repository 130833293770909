import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { useNotification } from '@veo/web-design-system';
import { createPlayerClip } from 'src/common/apis/player-clips';
import { RequesterError } from 'src/common/model/interfaces/IRequesterError';
import { isRequesterError } from 'src/common/utils/requester-errors';
import { useAnalytics } from 'src/features/analytics/hooks/useAnalytics';
import { RQ_KEY } from 'src/common/constants/react-query';
import { IPlayerClip } from 'src/common/model/interfaces/IPlayerClip';
import { useAuthPlayer } from 'src/features/auth';

export function useCreatePlayerClipMutation() {
  const { data: authPlayer } = useAuthPlayer();
  const { t } = useTranslation();
  const { notify } = useNotification();
  const { analytics, events, statuses } = useAnalytics();
  const queryClient = useQueryClient();

  return useMutation<IPlayerClip, RequesterError, IPlayerClip>(
    (newPlayerClip) => createPlayerClip(newPlayerClip),
    {
      async onSuccess(data) {
        notify('success', t('add_player_clips.success_notification'));
        /** Invalidating all player clips queries (both own and public) since there might be different query params in each of them */
        queryClient.invalidateQueries(RQ_KEY.PLAYER_CLIPS);
        /** Invalidating player abilities query since it might add new one */
        queryClient.invalidateQueries([RQ_KEY.PLAYER_ABILITIES, { playerId: authPlayer?.id }]);
        /** Manually set single player clip data for auth player */
        queryClient.setQueryData<IPlayerClip>([RQ_KEY.AUTH_PLAYER_CLIP, { playerClipId: data.id }], data);
      },
      async onError(err) {
        if (isRequesterError(err)) {
          notify('error', err.message);
        }
      },
      async onSettled(data, err) {
        const status = err ? statuses.FAILED : statuses.SUCCESS;
        const action = 'add';

        analytics?.track(events.PLAYER_CLIPS_CHANGED, { action, status });
      },
    },
  );
}
