import i18n from 'src/common/services/i18next';

type AddClipStep = {
  label: string;
};

export const ADD_CLIP_STEPS: AddClipStep[] = [
  {
    label: i18n.t('add_player_clips.stepper.find_label'),
  },
  {
    label: i18n.t('add_player_clips.stepper.confirm_label'),
  },
  {
    label: i18n.t('add_player_clips.stepper.enchance_label'),
  },
];
