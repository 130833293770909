import { useTranslation } from 'react-i18next';
import { Icon } from '@veo/web-design-system';
import { config } from 'src/config';
import { LinkWrapper } from 'src/components/link/link';
import styles from './new-clubhouse.module.scss';

const newClubLink = `${config.ZOLA_URL}/clubhouses/create/`;

export function NewClubhouse() {
  const { t } = useTranslation();
  return (
    <LinkWrapper external link={newClubLink} className={styles['new-clubhouse']}>
      <Icon name="plus-circle" size="lg" />
      { t('layout.sidemenu_new_club_link') }
    </LinkWrapper>
  );
}
