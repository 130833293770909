import { ValueOf } from '../types/helper.type';

export const MeasurementSystems = {
  Metric: 'metric',
  Imperial: 'imperial',
} as const;

export type MeasurementSystem = ValueOf<typeof MeasurementSystems>;

export const LengthUnits = {
  Meter: 'meter',
  Inch: 'inch',
} as const;

export type LengthUnit = ValueOf<typeof LengthUnits>;

export const WeightUnits = {
  Kg: 'kg',
  Pound: 'pound',
} as const;

export type WeightUnit = ValueOf<typeof WeightUnits>;

export const LengthUnitMap = {
  [MeasurementSystems.Metric]: LengthUnits.Meter,
  [MeasurementSystems.Imperial]: LengthUnits.Inch,
} as const;

export const WeightUnitMap = {
  [MeasurementSystems.Metric]: WeightUnits.Kg,
  [MeasurementSystems.Imperial]: WeightUnits.Pound,
} as const;

export const MeasurementUnitsMap = {
  [MeasurementSystems.Metric]: [WeightUnits.Kg, LengthUnits.Meter],
  [MeasurementSystems.Imperial]: [WeightUnits.Pound, LengthUnits.Inch],
} as const;
