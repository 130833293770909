/* eslint-disable no-param-reassign */
import { RefObject, useEffect, useRef } from 'react';
import { seekVideo } from 'src/components/veo-player/utils/player-actions';
import { SLOWDOWN_STATIC_THRESHOLD, SLOWDOWN_TOLERANCE } from '../constants/telestration';
import { formatFloat } from '../utils/video';
import { useTelestrationDebug, debugParams } from '../components/debug-panel/debug-context';

type Props = {
  ref: RefObject<HTMLVideoElement>;
  from: number;
  duration?: number;
  enabled?: boolean;
  timeOffset?: number;
  onStart?(time: number): void;
  onEnd?(time: number): void;
};

export function useSlowdownVideo({
  ref,
  from,
  duration = 3,
  enabled = false,
  timeOffset = 0,
  onStart,
  onEnd,
}: Props) {
  const initialRateResetterRef = useRef<ReturnType<typeof setTimeout>>();
  const changeRateInitiatedRef = useRef<boolean>(false);
  const animationRef = useRef<number>();

  const { registerValue } = useTelestrationDebug();

  function handleSlowdownStart() {
    registerValue(debugParams.telestrationShowedAt, ref.current?.currentTime);

    if (onStart) {
      onStart(ref.current?.currentTime ?? 0);
    }
  }

  function handleSlowdownEnd() {
    if (onEnd) {
      onEnd(ref.current?.currentTime ?? 0);
    }
  }

  function resetInitialPlayback() {
    if (ref.current) {
      handleSlowdownEnd();
      ref.current.playbackRate = 1.0;
    }

    changeRateInitiatedRef.current = false;
  }

  /** Terminate the telestration and resume playback rate once the telestration marked for show and user uses seekbar to change video time */
  useEffect(() => {
    const terminateTelestration = () => {
      if (initialRateResetterRef.current) {
        clearTimeout(initialRateResetterRef.current);
        resetInitialPlayback();
      }
    };

    if (changeRateInitiatedRef.current && ref.current) {
      ref.current.addEventListener('seeked', terminateTelestration);
    }

    return () => {
      if (ref.current) {
        ref.current.removeEventListener('seeked', terminateTelestration);
      }
    };
  }, [changeRateInitiatedRef.current]);

  useEffect(() => {
    const resetDuration = duration * 1000;

    // todo: since offset is not anymore the case for chrome browser, consider to remove it
    const fromWithTimeOffset = from + timeOffset;

    const slowdownTimeWithTolerance = formatFloat(fromWithTimeOffset - SLOWDOWN_TOLERANCE, 2);

    const initTimeStart = formatFloat(slowdownTimeWithTolerance - SLOWDOWN_STATIC_THRESHOLD, 2);
    const initTimeEnd = slowdownTimeWithTolerance;

    async function executeSlowdown() {
      if (!ref.current) {
        return;
      }

      /** Playback rate change start */
      if (
        changeRateInitiatedRef.current === false
        && ref.current.currentTime > initTimeStart
        && ref.current.currentTime < initTimeEnd
      ) {
        /** Playback rate decrese */
        ref.current.playbackRate = 0.0;

        /** Change video timestamp to the one stored in telestration */
        await seekVideo(ref, fromWithTimeOffset)
          .then(() => {
            handleSlowdownStart();
          });

        /** Store the flag that slowdown initiated. Its important to do it after seekVideo to prevent telestration from being dismissed */
        changeRateInitiatedRef.current = true;

        /** Playback rate reset to initial */
        initialRateResetterRef.current = setTimeout(() => {
          resetInitialPlayback();
        }, resetDuration);
      }
    }

    const invokeTimeTracking = () => {
      /** Invoke execute slowdown only if there is no pendind telestrations */
      if (ref.current && changeRateInitiatedRef.current === false) {
        executeSlowdown();
      }

      animationRef.current = requestAnimationFrame(invokeTimeTracking);
    };

    if (ref.current && enabled) {
      animationRef.current = requestAnimationFrame(invokeTimeTracking);

      registerValue(debugParams.telestrationTimestamp, from);
      registerValue(debugParams.timeTrackerAttachedAt, ref.current.currentTime);
      registerValue(debugParams.enabled, enabled);
      registerValue(debugParams.triggerTimestamp, initTimeStart);
      registerValue(debugParams.videoOffset, timeOffset);
      registerValue(debugParams.telestrationShowedAt, 'n/a');
    }

    return () => {
      if (initialRateResetterRef.current) {
        clearTimeout(initialRateResetterRef.current);
        resetInitialPlayback();
      }
      if (animationRef.current) {
        cancelAnimationFrame(animationRef.current);
      }
    };
  }, [enabled, timeOffset]);
}
