/* eslint-disable max-len */
import { ForwardedRef, forwardRef } from 'react';

type Props = {
  x: number;
  y: number;
  width: number;
  height: number;
};

function SpotlightCircleCmp(
  {
    x, y, width, height,
  }: Props,
  ref: ForwardedRef<SVGSVGElement>,
) {
  return (
    <svg
      ref={ref}
      x={x}
      y={y}
      width={width}
      height={height}
      viewBox="0 0 64 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="none"
    >
      <g filter="url(#filter0_d_5822_79419)">
        <ellipse cx="32.3883" cy="12.8657" rx="25.6119" ry="10.8657" fill="url(#paint0_linear_5822_79419)" fillOpacity="0.6" />
        <path d="M56.0599 12.8657C56.0599 13.7027 55.6683 14.6671 54.6135 15.7252C53.5508 16.791 51.9158 17.84 49.7409 18.7627C45.3986 20.6048 39.2648 21.791 32.3883 21.791C25.5118 21.791 19.378 20.6048 15.0357 18.7627C12.8608 17.84 11.2258 16.791 10.1631 15.7252C9.10829 14.6671 8.71667 13.7027 8.71667 12.8657C8.71667 12.0286 9.10829 11.0642 10.1631 10.0062C11.2258 8.94035 12.8608 7.89136 15.0357 6.96869C19.378 5.1265 25.5118 3.9403 32.3883 3.9403C39.2648 3.9403 45.3986 5.1265 49.7409 6.96869C51.9158 7.89136 53.5508 8.94035 54.6135 10.0062C55.6683 11.0642 56.0599 12.0286 56.0599 12.8657Z" stroke="url(#paint1_linear_5822_79419)" strokeOpacity="0.6" strokeWidth="3.8806" />
        <path d="M57.9996 12.3728C57.9996 7.07228 46.5328 2.77539 32.3877 2.77539" stroke="url(#paint2_linear_5822_79419)" strokeWidth="3.8806" />
        <path d="M57.9996 13.3574C57.9996 18.6579 46.5328 22.9548 32.3877 22.9548" stroke="#FAFAFA" strokeWidth="3.8806" />
        <path d="M31.6119 22.9548C17.4669 22.9548 6 18.6579 6 13.3574" stroke="#3DD64F" strokeWidth="3.8806" />
        <path d="M6 12.8669C6 7.56642 17.4669 3.26953 31.6119 3.26953" stroke="url(#paint3_linear_5822_79419)" strokeWidth="3.8806" />
      </g>
      <defs>
        <filter id="filter0_d_5822_79419" x="0.955093" y="0.835938" width="62.0888" height="30.2675" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset dy="3.10448" />
          <feGaussianBlur stdDeviation="1.55224" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_5822_79419" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_5822_79419" result="shape" />
        </filter>
        <linearGradient id="paint0_linear_5822_79419" x1="32.3883" y1="2" x2="32.3883" y2="23.7313" gradientUnits="userSpaceOnUse">
          <stop offset="0.1464" stopColor="white" stopOpacity="0" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
        <linearGradient id="paint1_linear_5822_79419" x1="32.3883" y1="2" x2="32.3883" y2="23.7313" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FAFAFA" stopOpacity="0" />
          <stop offset="1" stopColor="#FAFAFA" />
        </linearGradient>
        <linearGradient id="paint2_linear_5822_79419" x1="31.6116" y1="3.26756" x2="37.4824" y2="17.6022" gradientUnits="userSpaceOnUse">
          <stop offset="0.285324" stopColor="#FAFAFA" stopOpacity="0" />
          <stop offset="1" stopColor="#3DD64F" />
        </linearGradient>
        <linearGradient id="paint3_linear_5822_79419" x1="31.6119" y1="3.26953" x2="11.7185" y2="20.546" gradientUnits="userSpaceOnUse">
          <stop offset="0.376257" stopColor="#FAFAFA" stopOpacity="0" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export const SpotlightCircle = forwardRef<SVGSVGElement, Props>(SpotlightCircleCmp);
