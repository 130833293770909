import { useEffect } from 'react';
import { useUserSettings } from 'src/common/hooks/user/useUserSettings';
import { useUserSettingsMutation } from 'src/common/hooks/user/useUserSettingsMutation';
import { useAnalytics } from 'src/features/analytics/hooks/useAnalytics';
import { useAuthPlayer } from 'src/features/auth';

type Props = {
  /** Controls whether the onboarding event should be fired. Typically whether its own profile or not */
  enabled?: boolean;
};

export function useOnboarding({ enabled = false }: Props) {
  const { analytics, events } = useAnalytics();
  const { data: authPlayer } = useAuthPlayer();
  const { data: settings } = useUserSettings();
  const { mutate: updateSettings } = useUserSettingsMutation();

  useEffect(() => {
    if (settings?.onboardingToDo && authPlayer && enabled) {
      analytics?.track(
        events.ONBOARDING,
        () => updateSettings({ ...settings, onboardingToDo: false }),
      );
    }
  }, [authPlayer, settings, enabled]);
}
