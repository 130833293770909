import { useTranslation } from 'react-i18next';
import { Text } from '@veo/web-design-system';
import { useAuthPlayer } from 'src/features/auth';
import { FilterParams } from 'src/common/hooks/useQueryParams';
import { FilterItem } from './quick-filter-item';
import styles from './quick-filters.module.scss';

type Props = {
  onChange(filter: FilterParams): void;
};

export function QuickFilters({ onChange }: Props) {
  const { data: authPlayer } = useAuthPlayer();

  const { t } = useTranslation();

  return (
    <div className={styles.wrapper}>
      <Text type="h4" size="h4" weight="medium">
        { t('add_player_clips.filters.quick_filters_label') }
      </Text>
      <div className={styles.container}>
        <FilterItem
          label={t('add_player_clips.filters.bookmarked_filter_label')}
          icon="bookmark"
          filter={{ bookmarked: true }}
          onClick={onChange}
        />
        <FilterItem
          label={t('add_player_clips.filters.mentions_filter_label')}
          icon="at-sign"
          filter={{ involvedPlayer: authPlayer!.externalId }}
          onClick={onChange}
        />
        <FilterItem
          label={t('add_player_clips.filters.created_by_me_filter_label')}
          icon="plus-square"
          filter={{ creator: authPlayer!.externalId }}
          onClick={onChange}
        />
      </div>
    </div>
  );
}
