import { IconButton } from '@veo/web-design-system';
import { Nullable } from 'src/common/model/types/helper.type';
import { LinkWrapper } from '../link/link';
import { SOCIAL_MEDIA_ICONS, SocialMedia, SocialMedias } from './constants';
import { getSocialMediaUrl, isKnownSocialMedia } from './utils';

type Props = {
  id?: Nullable<string>;
  media?: Nullable<SocialMedia>;
  onClick?(media: SocialMedia): void;
};

export function SocialLink({ id, media, onClick }: Props) {
  if (!id || !media || !isKnownSocialMedia(media)) {
    return null;
  }

  const url = getSocialMediaUrl(media, id);

  const icon = SOCIAL_MEDIA_ICONS[media];

  function handleClick() {
    if (onClick && media) {
      onClick(media);
    }
  }

  return (
    <LinkWrapper
      link={url}
      target="_blank"
      rel="noopener noreferrer"
      external
    >
      <IconButton
        size="sm"
        icon={icon}
        variant="outlined"
        onClick={handleClick}
      />
    </LinkWrapper>
  );
}

export { type SocialMedia };
export { SocialMedias };
