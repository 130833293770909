/* eslint-disable no-plusplus */
import { useRef, useState, useCallback } from 'react';
import { useMutationObserver, MutationObserverProps } from 'src/common/hooks/useMutationObserver';

function calculateOverlapPercentage(rectA: DOMRect, rectB: DOMRect): number {
  // Find the area of each rectangle
  const areaA = (rectA.right - rectA.left) * (rectA.bottom - rectA.top);
  const areaB = (rectB.right - rectB.left) * (rectB.bottom - rectB.top);

  // Calculate the dimensions of the overlap area
  const xOverlap = Math.max(0, Math.min(rectA.right, rectB.right) - Math.max(rectA.left, rectB.left));
  const yOverlap = Math.max(0, Math.min(rectA.bottom, rectB.bottom) - Math.max(rectA.top, rectB.top));

  // Calculate the area of overlap
  const overlapArea = xOverlap * yOverlap;

  // Calculate the percentage of overlap relative to each rectangle
  const overlapPercentageA = (overlapArea / areaA) * 100;
  const overlapPercentageB = (overlapArea / areaB) * 100;

  // Return the smaller of the two percentages
  return Math.min(overlapPercentageA, overlapPercentageB);
}

function isOverlapMoreThanPercentage(rectA: DOMRect, rectB: DOMRect, percentage = 10): boolean {
  const overlapPercentage = calculateOverlapPercentage(rectA, rectB);
  return overlapPercentage > percentage;
}

const defaultMutationOptions: MutationObserverInit = {
  childList: true,
  subtree: true,
};

type Props<R> = Omit<MutationObserverProps<R>, 'onMutate'>;

export function useObscuredElement<T extends HTMLElement, R extends HTMLElement = HTMLBodyElement>(props: Props<R> = {}) {
  const { root, options } = props;

  const [isObscured, setIsObscured] = useState(false);
  const targetRef = useRef<T>(null);
  const obscureElementsRef = useRef<Node[]>([]);

  const handleTrackMutation = useCallback((mutations: MutationRecord[]) => {
    mutations.forEach((mutation) => {
      if (mutation.type === 'childList' && targetRef.current) {
        const addedNodes = Array.from(mutation.addedNodes);
        const removedNodes = Array.from(mutation.removedNodes);

        const targetDomRect = targetRef.current.getBoundingClientRect();

        const getNewOverlapNodes = () => {
          const newOverlapped = [];

          for (let index = 0; index < addedNodes.length; index++) {
            const node = addedNodes[index];

            if (node.nodeType === Node.ELEMENT_NODE) {
              const nodeDomRect = (node as HTMLElement).getBoundingClientRect();

              const isOverlapped = isOverlapMoreThanPercentage(targetDomRect, nodeDomRect);
              const isNewElement = isOverlapped && !obscureElementsRef.current.some((n) => n.contains(node));

              if (isNewElement) {
                newOverlapped.push(node);
              }
            }
          }

          return newOverlapped;
        };

        const cleanupRemovedOverlapNodes = () => {
          let newOverlapped = [...obscureElementsRef.current];

          removedNodes.forEach((node) => {
            if (node.nodeType === Node.ELEMENT_NODE) {
              newOverlapped = newOverlapped.filter((n) => n !== node);
            }
          });

          return newOverlapped;
        };

        const newOverlapItems = getNewOverlapNodes();
        const previousItemsWithoutRemoved = cleanupRemovedOverlapNodes();

        const resultedList = [...previousItemsWithoutRemoved, ...newOverlapItems];

        obscureElementsRef.current = resultedList;

        setIsObscured(() => !!resultedList.length);
      }
    });
  }, []);

  useMutationObserver({
    root,
    options: { ...defaultMutationOptions, ...options },
    onMutate: handleTrackMutation,
  });

  return {
    ref: targetRef,
    isObscured,
  };
}
