import { useTranslation } from 'react-i18next';
import { ErrorPage } from '@veo/web-design-system';
import { usePageViewEvent } from 'src/features/analytics/hooks/usePageViewEvent';
import { PAGE_VIEW_EVENTS } from 'src/features/analytics/constants/segment-events';
import { useOidcAuth } from '../../hooks/useOidcAuth';

export function Unauthorized() {
  const { signinRedirect } = useOidcAuth();
  const { t } = useTranslation();

  usePageViewEvent(PAGE_VIEW_EVENTS.UNAUTHORIZED_VISITED);

  return (
    <ErrorPage
      illustration="users"
      title={t('auth.unauthorized.title')}
      description={t('auth.unauthorized.description')}
      onButtonClick={() => signinRedirect()}
      buttonText={t('auth.unauthorized.action_label') as string}
      buttonIconEnd="log-in"
    />
  );
}
