import { useState, useRef } from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { uniqueId } from 'lodash-es';
import { Text, Button } from '@veo/web-design-system';
import { Skeleton } from 'src/components/skeletons/skeleton';
import { useAuthPlayerMatches } from 'src/common/hooks/player/useAuthPlayerMatches';
import { IMatch } from 'src/common/model/interfaces/IMatch';
import styles from './match-filters.module.scss';
import { MatchItem } from './match-item';

type Props = {
  onChange(match: IMatch): void;
};

export function MatchFilters({ onChange }: Props) {
  const [page, setPage] = useState(1);
  const listRef = useRef<HTMLDivElement>(null);

  const { data: matches, isLoading } = useAuthPlayerMatches({ page, pageSize: 6 });

  const { t } = useTranslation();

  const hasPreviousPage = matches?.meta.pageNumber !== 1;
  const hasNextPage = (matches?.meta.totalCount ?? 1) / (matches?.meta.pageSize ?? 1) > page;

  function scrollListTop() {
    if (listRef.current) {
      listRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }

  function prevPage() {
    scrollListTop();
    setPage((prev) => (hasPreviousPage ? prev - 1 : prev));
  }

  function nextPage() {
    scrollListTop();
    setPage((prev) => (hasNextPage ? prev + 1 : prev));
  }

  return (
    <div ref={listRef} className={styles.wrapper}>
      <Text type="h4" size="h4" weight="medium">
        { t('add_player_clips.filters.match_filters_label') }
      </Text>
      <div className={styles.container}>
        {
          matches?.items.map((m) => <MatchItem key={m.slug} match={m} filter={{ matchId: m.id }} onClick={onChange} />)
        }
        {
          (matches?.items.length ?? 6) < 6 && !isLoading
            && new Array(6 - (matches?.items.length ?? 0)).fill(1).map(() => <div className={cn(styles.item, styles.empty)} />)
        }
        {
          isLoading && (
            new Array(6).fill(0).map(() => <Skeleton key={uniqueId('skeleton')} width="100%" height="100%" />)
          )
        }
      </div>
      <div className={styles.pagination}>
        <Button
          size="md"
          label={t('add_player_clips.filters.match_prev_button_label')}
          iconStart="chevron-left"
          variant="outlined"
          disabled={!hasPreviousPage}
          onClick={prevPage}
        />
        <Button
          size="md"
          label={t('add_player_clips.filters.match_next_button_label')}
          iconEnd="chevron-right"
          variant="outlined"
          disabled={!hasNextPage}
          onClick={nextPage}
        />
      </div>
    </div>
  );
}
