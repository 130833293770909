import { useQuery, useQueryClient } from 'react-query';
import { syncUserData } from 'src/common/apis/user-settings.api';
import { EXTENDED_CACHE_TIME_MS, RQ_KEY } from 'src/common/constants/react-query';
import { RequesterError } from 'src/common/model/interfaces/IRequesterError';
import { useOidcAuth } from './useOidcAuth';

export function useOidcUserSync() {
  const queryClient = useQueryClient();

  const { isAuthenticated, user } = useOidcAuth();

  return useQuery<unknown, RequesterError>(
    RQ_KEY.AUTH_USER_SYNC,
    () => syncUserData(user!.profile.sub),
    {
      onSettled() {
        queryClient.invalidateQueries([RQ_KEY.AUTH_USER]);
      },
      enabled: isAuthenticated && !!user?.profile,
      cacheTime: EXTENDED_CACHE_TIME_MS,
      staleTime: EXTENDED_CACHE_TIME_MS,
    },
  );
}
