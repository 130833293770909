import { useMutation, useQueryClient } from 'react-query';
import { useNotification } from '@veo/web-design-system';
import { updatePlayerImage, removePlayerImage } from 'src/common/apis/player.api';
import { RequesterError } from 'src/common/model/interfaces/IRequesterError';
import { isRequesterError } from 'src/common/utils/requester-errors';
import { RQ_KEY } from 'src/common/constants/react-query';
import { IPlayerImage } from 'src/common/model/interfaces/IPlayer';
import { useAnalytics } from 'src/features/analytics/hooks/useAnalytics';

export function useAuthPlayerImageMutation() {
  const { notify } = useNotification();
  const { analytics, events, statuses } = useAnalytics();
  const queryClient = useQueryClient();

  return useMutation<unknown, RequesterError, IPlayerImage>(
    (data) => (data.image ? updatePlayerImage(data) : removePlayerImage({ id: data.id })),
    {
      async onSuccess() {
        queryClient.invalidateQueries(RQ_KEY.AUTH_USER);
      },
      async onError(err) {
        if (isRequesterError(err)) {
          notify('error', err.message);
        }
      },
      async onSettled(data, err) {
        const status = err ? statuses.FAILED : statuses.SUCCESS;

        analytics?.track(events.PROFILE_IMAGE_UPLOADED, { status });
      },
    },
  );
}
