import { useTranslation } from 'react-i18next';
import { useFormContext, useController } from 'react-hook-form';
import { DateTime } from 'luxon';
import { FormField, DateInput } from '@veo/web-design-system';
import { isDateInRangeValidator } from 'src/common/utils/form-validators';
import { KeyInfoFormValues } from '../../../utils/key-information';
import styles from './key-information-form.module.scss';

export function BirthdayField() {
  const { t } = useTranslation();

  const { control, trigger } = useFormContext<KeyInfoFormValues>();

  const { field, fieldState } = useController<KeyInfoFormValues, 'birthday'>({
    control,
    name: 'birthday',
    rules: {
      validate: (v) => {
        const maxBirthdayDate = DateTime.now()
          .minus({ years: 13 })
          .toISODate();

        return isDateInRangeValidator(undefined, maxBirthdayDate)(v);
      },
    },
  });

  const {
    isTouched,
    error,
  } = fieldState;

  const {
    value,
    onChange,
    onBlur,
  } = field;

  function handleBlur() {
    onBlur();
    trigger('birthday');
  }

  return (
    <FormField
      id="birthday"
      className={styles.field}
      label={t('player_key_information.birthdate_title')}
      error={isTouched ? error?.message : undefined}
    >
      <DateInput
        selectRange={false}
        clearable
        updateOnChange
        value={value}
        onBlur={handleBlur}
        onUpdate={onChange}
      />
    </FormField>
  );
}
