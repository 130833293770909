import { QueryParams } from '../hooks/useQueryParams';
import { IPaginatedResponse } from '../model/interfaces/IPaginatedResponse';
import { ITeam } from '../model/interfaces/ITeam';
import { ITeamMember } from '../model/interfaces/ITeamMember';
import { playerSvc } from '../services/player-svc';

export async function getPlayerTeams(id: string): Promise<ITeam[]> {
  const url = `teams/by/playerId/${id}`;

  const res = await playerSvc.request({ method: 'GET', url });

  return res as ITeam[];
}

export async function getUserTeams(externalId: string): Promise<ITeam[]> {
  const url = `teams/by/externalId/${externalId}`;

  const res = await playerSvc.request({ method: 'GET', url });

  return res as ITeam[];
}

export async function getTeamMembers(teamMeta: any, queryParams: QueryParams, signal?: AbortSignal): Promise<IPaginatedResponse<ITeamMember>> {
  const { clubSlug, teamSlug } = teamMeta;
  const { page, pageSize } = queryParams;
  const ordering = 'first_name,last_name';

  const params = new URLSearchParams({
    ordering,
    pageSize: `${pageSize}`,
    pageNumber: `${page}`,
  });

  const url = `teams/by/slug/${clubSlug}/${teamSlug}/members`;

  const res = await playerSvc.request({
    method: 'GET', url, signal, params,
  });

  return res as IPaginatedResponse<ITeamMember>;
}
