import { useMemo } from 'react';
import { Helmet } from 'react-helmet';
import { IPlayer } from 'src/common/model/interfaces/IPlayer';
import { usePlayerAbilities } from 'src/common/hooks/abilities/usePlayerAbilities';
import {
  getProfileTitle,
  getProfileDescription,
  getPlayerProfileTitle,
  getPlayerProfileDescription,
  getPlayerProfileDescriptionWithHighlights,
} from '../../utils/meta-helpers';

type Props = {
  player?: IPlayer;
};

export function PageMeta({ player }: Props) {
  const { data: highlightPlaylist } = usePlayerAbilities(player?.id);

  const meta = useMemo(() => {
    let title = getProfileTitle();
    let description = getProfileDescription();

    if (player && !highlightPlaylist) {
      title = getPlayerProfileTitle(player);
      description = getPlayerProfileDescription(player);
    }

    if (player && highlightPlaylist) {
      title = getPlayerProfileTitle(player);
      description = getPlayerProfileDescriptionWithHighlights(player, highlightPlaylist);
    }

    return { title, description };
  }, [player, highlightPlaylist]);

  return (
    <Helmet>
      <title>{meta.title}</title>
      <meta name="description" content={meta.description} />
      <link rel="canonical" href={window.location.href} />
    </Helmet>
  );
}
