import { playerSvc } from '../services/player-svc';
import { IUserSettings } from '../model/interfaces/IUserSettings';
import { IUser } from '../model/interfaces/IUser';

export async function getAuthUser(): Promise<IUser> {
  const res = await playerSvc.request({ method: 'GET', url: 'users/me' });

  return res as IUser;
}

export async function getUserSettings(externalId: string): Promise<IUserSettings> {
  const url = `/users/${externalId}/settings`;

  const settings = await playerSvc.request({ method: 'GET', url });

  return settings as IUserSettings;
}

export async function updateUserSettings(externalId: string, data: IUserSettings): Promise<unknown> {
  const url = `/users/${externalId}/settings`;

  return playerSvc.request({ method: 'PATCH', url, data });
}

export async function syncUserData(externalId: string): Promise<unknown> {
  const res = await playerSvc.request({ method: 'POST', url: `users/${externalId}/sync` });

  return res;
}
