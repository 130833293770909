import { useQuery } from 'react-query';
import { RequesterError } from 'src/common/model/interfaces/IRequesterError';
import { useOidcAuth } from 'src/features/oidc';
import { AuthUser, AuthUserRoles, IUser } from 'src/common/model/interfaces/IUser';
import { EXTENDED_CACHE_TIME_MS, RQ_KEY } from 'src/common/constants/react-query';
import { getAuthUser } from 'src/common/apis/user-settings.api';

export function useAuth() {
  const { data: oidcUser, isAuthenticated } = useOidcAuth();

  const userQuery = useQuery<IUser, RequesterError, AuthUser>(
    [RQ_KEY.AUTH_USER],
    () => getAuthUser(),
    {
      enabled: isAuthenticated && !!oidcUser,
      retryOnMount: false,
      select: (data) => {
        const { player } = data;

        if (player) {
          return { role: AuthUserRoles.player, data: player };
        }

        return { role: AuthUserRoles.player, data: {} } as AuthUser;
      },
      retry: false,
      cacheTime: EXTENDED_CACHE_TIME_MS,
      staleTime: EXTENDED_CACHE_TIME_MS,
    },
  );

  return userQuery;
}
