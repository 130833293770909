import {
  useRef, useEffect, useState, RefObject, ChangeEvent,
} from 'react';
import cn from 'classnames';
import { useMobileDevice } from 'src/common/hooks/useMobileDevice';
import { pauseVideo, playVideo } from '../../utils/player-actions';
import styles from './seek-bar.module.scss';

type SeekBarProps = {
  videoRef: RefObject<HTMLVideoElement>,
  onSeekVideo: (timestamp: number) => Promise<unknown>;
};

export function SeekBar({
  videoRef,
  onSeekVideo,
}: SeekBarProps) {
  const [timestamp, setTimestamp] = useState(0);
  const [duration, setDuration] = useState(videoRef.current?.duration ?? 0);
  const animationRef = useRef<number>();
  const videoPlayedStateRef = useRef(false);

  const { isMobile } = useMobileDevice();

  useEffect(() => {
    function trackTimestamp() {
      if (videoRef.current && !videoRef.current.paused) {
        setTimestamp(videoRef.current.currentTime);
      }

      animationRef.current = requestAnimationFrame(trackTimestamp);
    }

    function trackDuration() {
      if (videoRef.current) {
        setDuration(videoRef.current.duration);
      }
    }

    if (videoRef.current) {
      animationRef.current = requestAnimationFrame(trackTimestamp);

      videoRef.current.addEventListener('durationchange', trackDuration);
    }

    return () => {
      if (animationRef.current) {
        cancelAnimationFrame(animationRef.current);
      }
      if (videoRef.current) {
        videoRef.current.removeEventListener('durationchange', trackDuration);
      }
    };
  }, []);

  async function handleTimeChange(e: ChangeEvent<HTMLInputElement>) {
    e.stopPropagation();
    pauseVideo(videoRef);

    const nextTimestamp = parseFloat(e.target.value);

    setTimestamp(nextTimestamp);

    await onSeekVideo(nextTimestamp);
  }

  function handleVideoSeeked() {
    if (videoPlayedStateRef.current) {
      playVideo(videoRef);
    }
  }

  function handleStartVideoSeek() {
    if (videoRef.current) {
      videoPlayedStateRef.current = !videoRef.current.paused;
    }
  }

  function getProgressWidth() {
    const progressValue = timestamp / duration;

    const thumbWidth = 8;

    let widthDelta = 0;

    /* Sync with thumb movement */
    if (progressValue > 0.5 && progressValue < 1) {
      widthDelta = parseFloat(`-${thumbWidth * ((progressValue - 0.5) * 2)}`);
    }
    if (progressValue < 0.5) {
      widthDelta = thumbWidth - (thumbWidth * progressValue * 2);
    }

    return `calc(${progressValue * 100}% + ${widthDelta}px)`;
  }

  const containerClassnames = cn(
    styles.container,
    { [styles.desktop]: !isMobile },
  );

  const seekBarClassnames = cn(styles.slider);

  const progressWidth = getProgressWidth();

  return (
    <div className={containerClassnames}>
      <input
        className={seekBarClassnames}
        onChange={handleTimeChange}
        onMouseDown={handleStartVideoSeek}
        onTouchStart={handleStartVideoSeek}
        onTouchEnd={handleVideoSeeked}
        onMouseUp={handleVideoSeeked}
        step="0.01"
        type="range"
        min="0"
        max={duration}
        value={timestamp}
      />
      <div className={styles.progress} style={{ width: progressWidth }} />
    </div>
  );
}
