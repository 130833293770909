/* eslint-disable @typescript-eslint/no-explicit-any */
import axios, {
  AxiosError, AxiosInstance, isAxiosError, Method,
} from 'axios';
import { omit } from 'lodash-es';
import { getTokenFromStorage } from 'src/features/oidc';
import { RequesterErrorType } from '../model/enums/RequesterErrorType';
import { IRequester, IRequesterRequest } from '../model/interfaces/IRequester';
import { IValidationError, IRequesterError } from '../model/interfaces/IRequesterError';
import { isRequesterError } from '../utils/requester-errors';

export class AxiosRequester implements IRequester {
  private axiosInstance: AxiosInstance;

  constructor(private baseURL: string) {
    this.axiosInstance = axios.create({
      baseURL: this.baseURL,
      timeout: 10000,
      withCredentials: true,
    });

    this.addErrorInterceptor();
    this.addTokenInterceptor();
  }

  async request<T, K>({
    method, url, headers, data, params, signal,
  }: IRequesterRequest<T>): Promise<K | undefined> {
    let res;
    try {
      res = await this.axiosInstance.request({
        method: method as Method,
        url,
        headers,
        data,
        params,
        signal,
      });
    } catch (e) {
      return Promise.reject(e);
    }

    return res?.data;
  }

  addErrorResponseInterceptor(fn: (e: IRequesterError) => void): void {
    this.axiosInstance.interceptors.response.use((response) => response, (error: unknown) => {
      if (isRequesterError(error) && error.type === RequesterErrorType.Response && typeof fn === 'function') {
        fn(error);
      }

      return Promise.reject(error);
    });
  }

  private addTokenInterceptor() {
    this.axiosInstance.interceptors.request.use(
      (config) => {
        const token = getTokenFromStorage();

        if (token) {
          return {
            ...config,
            headers: {
              ...config.headers,
              Authorization: `Bearer ${token}`,
            },
          };
        }

        return {
          ...config,
          headers: {
            ...omit(config.headers, 'Authorization'),
          },
        };
      },
      (error) => Promise.reject(error),
    );
  }

  private addErrorInterceptor() {
    this.axiosInstance.interceptors.response.use((response) => response, (error: AxiosError | Error) => {
      let formattedError: IRequesterError | IValidationError | Error;
      if (isAxiosError(error) && error?.response) {
        const { status, data } = error.response;

        const { fields, message } = data;

        formattedError = {
          type: RequesterErrorType.Response,
          statusCode: status,
          message,
          fields,
          config: error.config,
        };
      } else if (isAxiosError(error) && error.request) {
        formattedError = {
          type: RequesterErrorType.Request,
          statusCode: null,
          message: error.message,
          config: error.config,
        };
      } else {
        formattedError = error;
      }

      return Promise.reject(formattedError);
    });
  }
}
