import { useController, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Message, Text } from '@veo/web-design-system';
import { Position } from 'src/common/model/enums/Position';
import { PitchPositions } from 'src/features/pitch/components/pitch-positions/pitch-positions';
import { SoccerPitch } from 'src/features/pitch/soccer-pitch';
import { PreferredPositions } from '../preferred-positions';
import { PositionPlaystyleFormValues, getDefaultPlaystyle } from '../../../utils/position-information';
import styles from './position-information-form.module.scss';

const MAX_POSITIONS = 3;

export function PositionField() {
  const { t } = useTranslation();

  const {
    control, setError, setValue, watch,
  } = useFormContext<PositionPlaystyleFormValues>();

  const { field, fieldState } = useController<PositionPlaystyleFormValues, 'positions'>({
    control,
    name: 'positions',
    rules: {
      validate: () => true,
    },
  });

  const {
    value,
    onBlur,
    onChange,
  } = field;

  const {
    isDirty,
    error,
  } = fieldState;

  const positionsValue = watch('positions');

  function checkAndUpdatePlaystyle(cp?: Position) {
    if (positionsValue?.[0] !== cp) {
      setValue(
        'playstyle',
        getDefaultPlaystyle(cp),
        {
          shouldDirty: true,
          shouldTouch: true,
          shouldValidate: false,
        },
      );
    }
  }

  function handlePositionChange(p: Position) {
    let resultPositions: Position[] = [...value, p];

    if (value.length === MAX_POSITIONS && !value.includes(p)) {
      setError('positions', { message: t('player_position.max_positions_error') as string, type: 'info' });
      return;
    }

    if (value.includes(p)) {
      resultPositions = value.filter((pos) => pos !== p);
    }

    checkAndUpdatePlaystyle(resultPositions[0]);
    onChange(resultPositions);
    onBlur();
  }

  return (
    <>
      <Text
        className={styles['field-label']}
        size="h6"
        type="h6"
        weight="semiBold"
      >
        { t('player_position.form.position_field_label') }
      </Text>
      <div className={styles.pitch}>
        <SoccerPitch>
          <PitchPositions position={value} onChange={handlePositionChange} />
        </SoccerPitch>
      </div>
      <div className={styles.positions}>
        <PreferredPositions showEmpty positions={value} />
        { isDirty && <Message variant={error?.type === 'info' ? 'info' : 'error'}>{error?.message ?? ''}</Message>}
      </div>
      <Text
        className={styles['field-label']}
        size="h6"
        type="h6"
        weight="semiBold"
      >
        {
            t(
              'player_position.form.playstyle_field_label',
              {
                position: t(
                  `entity.position.${value[0]}`,
                  { defaultValue: t('player_position.form.position_field_label') },
                ),
              },
            )
          }
      </Text>
    </>
  );
}
