import i18n from 'src/common/services/i18next';
import { StrongFoot } from 'src/common/model/enums/StrongFoot';
import { IPlayer, IPlayerKeyInfo } from 'src/common/model/interfaces/IPlayer';
import { isNumberInRangeValidator } from 'src/common/utils/form-validators';
import { convertFormNumberToNumber, replaceEmptyValues } from 'src/common/utils/form-data';
import { Nullable } from 'src/common/model/types/helper.type';
import { MeasurementSystem, MeasurementSystems } from 'src/common/model/enums/MeasurementSystem';
import {
  convertHeightToImperial,
  convertHeightToMetric,
  convertWeightToImperial,
  convertWeightToMetric,
  stringifyUnitNumber,
} from 'src/common/utils/measurement-units';
import { formatFloat } from 'src/common/utils/dev-utils';
import {
  MAX_HEIGHT, MAX_WEIGHT, MIN_HEIGHT, MIN_WEIGHT,
} from '../constants/player';

export type KeyInfoFormValues = {
  id: string,
  strongFoot: Nullable<StrongFoot>,
  measurementSystem: string,
  gpa: string,
  graduationYear: string,
  weight?: string,
  height?: string,
  birthday: string,
  heightFeet?: string,
  heightInches?: string,
  weightLbs?: string,
};

export const getKeyInfoFormValues = (
  player: IPlayer,
  system?: Nullable<MeasurementSystem>,
): KeyInfoFormValues => {
  const {
    id,
    strongFoot,
    gpa,
    graduationYear,
    weight: playerWeight,
    height: playerHeight,
    birthday,
  } = player;

  let heightFeet;
  let heightInches;
  let weightLbs;
  let weight;
  let height;

  if (system === MeasurementSystems.Imperial && playerHeight) {
    const [feet, inches] = convertHeightToImperial(playerHeight) as number[];

    heightFeet = stringifyUnitNumber(feet, 0);
    heightInches = stringifyUnitNumber(inches, 0);
  }

  if (system === MeasurementSystems.Metric && playerHeight) {
    height = playerHeight;
  }

  if (system === MeasurementSystems.Imperial && playerWeight) {
    const imperialWeight = convertWeightToImperial(playerWeight);

    weightLbs = stringifyUnitNumber(imperialWeight!, 1);
  }

  if (system === MeasurementSystems.Metric && playerWeight) {
    weight = playerWeight;
  }

  return ({
    id,
    strongFoot,
    weight: weight ? String(weight) : undefined,
    height: height ? String(height) : undefined,
    birthday: birthday ?? '',
    gpa: gpa ?? '',
    measurementSystem: system ?? '',
    graduationYear: graduationYear ?? '',
    heightFeet,
    heightInches,
    weightLbs,
  });
};

type KeyInfoFormPayload = IPlayerKeyInfo & { measurementSystem: Nullable<MeasurementSystem> };

export const getKeyInfoPayload = (keyInfo: KeyInfoFormValues): KeyInfoFormPayload => {
  const {
    weight,
    weightLbs,
    height,
    heightFeet,
    heightInches,
    measurementSystem,
    birthday,
    ...rest
  } = replaceEmptyValues(keyInfo);

  let resultHeight = convertFormNumberToNumber(height);
  let resultWeight = convertFormNumberToNumber(weight);

  if (measurementSystem === MeasurementSystems.Imperial && heightFeet && heightInches) {
    const numFeet = convertFormNumberToNumber(heightFeet);
    const numInches = convertFormNumberToNumber(heightInches);

    resultHeight = convertHeightToMetric([numFeet ?? 0, numInches ?? 0]);
  }

  if (measurementSystem === MeasurementSystems.Imperial && weightLbs) {
    const lbsToKg = convertFormNumberToNumber(weightLbs);
    resultWeight = convertWeightToMetric(lbsToKg);
  }

  return {
    ...rest,
    birthday: birthday ?? null,
    measurementSystem: measurementSystem as MeasurementSystem ?? null,
    weight: resultWeight,
    height: resultHeight,
  };
};

const minLbs = formatFloat(convertWeightToImperial(MIN_WEIGHT) as number, 1);
const maxLbs = formatFloat(convertWeightToImperial(MAX_WEIGHT) as number, 1);

const [minFeet, minInches] = convertHeightToImperial(MIN_HEIGHT) as number[];
const [maxFeet, maxInches] = convertHeightToImperial(MAX_HEIGHT) as number[];

const MIN_INCHES = Math.ceil(minInches);
const MAX_INCHES = Math.floor(maxInches);

export function isValidFeetValidator(value?: string, message?: string) {
  return isNumberInRangeValidator(minFeet, maxFeet)(value, message);
}

export function isValidInchesValidator(value?: string, message?: string) {
  return isNumberInRangeValidator(0, 11)(value, message);
}

export function isValidHeightValidator(value?: string, message?: string) {
  return isNumberInRangeValidator(MIN_HEIGHT, MAX_HEIGHT)(value, message);
}

export function isValidWeightValidator(value?: string, message?: string) {
  return isNumberInRangeValidator(0, 200)(value, message);
}

export function isValidWeightLbsValidator(value?: string, message?: string) {
  return isNumberInRangeValidator(minLbs, maxLbs)(value, message);
}

export function isValidImperialHeightValidator(
  feet?: string | null,
  inches?: string | null,
): string | boolean {
  if (!feet || !inches) {
    return true;
  }

  const error = i18n.t(
    'validators.imperial_height',
    {
      minFeet,
      minInches: MIN_INCHES,
      maxFeet,
      maxInches: MAX_INCHES,
    },
  );

  const feetNum = convertFormNumberToNumber(feet);
  const inchesNum = convertFormNumberToNumber(inches);

  if (!feetNum && !inchesNum) {
    return true;
  }

  const coercedInches = inchesNum ?? 0;

  if (feetNum === minFeet && coercedInches < minInches) {
    return error;
  }

  if (feetNum === maxFeet && coercedInches > maxInches) {
    return error;
  }

  return true;
}

export function getStrongFootOptions() {
  return Object.values(StrongFoot)
    .map((strongFoot) => ({
      value: strongFoot,
      label: i18n.t(`entity.strong_foot.${strongFoot}`),
    }));
}

export {
  minFeet as MIN_FEET,
  maxFeet as MAX_FEET,
  minLbs as MIN_LBS,
  maxLbs as MAX_LBS,
};
