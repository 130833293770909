import { useState, useEffect } from 'react';
import { IPlayerClip } from 'src/common/model/interfaces/IPlayerClip';

type Props = {
  clips: IPlayerClip[];
};

export function useActiveClipManager({ clips }: Props) {
  const [activeClip, setActiveClip] = useState<IPlayerClip | null>(null);

  useEffect(() => {
    if (clips.length && !activeClip) {
      setActiveClip(clips[0]);
    }
  }, [clips]);

  const activeClipIndex = clips.findIndex((c) => c.id === activeClip?.id);

  return {
    activeClip,
    activeClipIndex,
    setActiveClip,
  };
}
