import { isEmpty, isString } from 'lodash-es';
import { formatFloat } from './dev-utils';

export function replaceEmptyValues<T extends Record<string, any>>(data: T): T {
  return Object.entries(data)
    .reduce((acc, [key, value]) => {
      const isEmptyValue = isEmpty(value);
      const formattedValues = isString(value) ? value.trim() : value;

      return {
        ...acc,
        [key]: isEmptyValue ? null : formattedValues,
      };
    }, {}) as T;
}

export function convertFormNumberToNumber(value?: string, precision?: number) {
  if (!value || value === '') {
    return null;
  }

  return formatFloat(parseFloat(value), precision);
}
