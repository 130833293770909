import { ReactNode } from 'react';
import { Text } from '@veo/web-design-system';
import styles from './key-information.module.scss';

type Props = {
  title: string;
  children?: ReactNode;
};

export function AttributeItem({ title, children }: Props) {
  return (
    <div className={styles.attribute}>
      <Text size="h6" weight="medium" type="h6">
        { title }
      </Text>
      <Text size="h2" weight="medium" type="block">{ children }</Text>
    </div>
  );
}
