import { useEffect, useState, useRef } from 'react';

type Props = {
  interval?: number;
  duration?: number;
  enabled?: boolean;
  repeat?: number;
};

export function useReminder(config: Props = {}) {
  const {
    interval = 3000, enabled = true, duration = 500, repeat = Infinity,
  } = config;

  const [showCount, setShowCount] = useState(0);
  const [remind, setRemind] = useState(false);

  const intervalCancelerRef = useRef<ReturnType<typeof setInterval>>();
  const timeoutCancelerRef = useRef<ReturnType<typeof setTimeout>>();

  function clearReminder() {
    setRemind(false);

    if (intervalCancelerRef.current) {
      clearInterval(intervalCancelerRef.current);
    }
    if (timeoutCancelerRef.current) {
      clearTimeout(timeoutCancelerRef.current);
    }
  }

  useEffect(() => {
    if (enabled) {
      intervalCancelerRef.current = setInterval(() => {
        setRemind(true);
        setShowCount((prev) => prev + 1);

        timeoutCancelerRef.current = setTimeout(() => {
          setRemind(false);
        }, duration);
      }, interval);
    }

    return () => {
      if (intervalCancelerRef.current) {
        clearInterval(intervalCancelerRef.current);
      }
      if (timeoutCancelerRef.current) {
        clearTimeout(timeoutCancelerRef.current);
      }
    };
  }, [enabled]);

  useEffect(() => {
    if (showCount > repeat) {
      clearReminder();
    }
  }, [showCount]);

  return {
    reminder: remind,
    clearReminder,
  };
}
