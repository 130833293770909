import { useTranslation } from 'react-i18next';
import { useController, useFormContext } from 'react-hook-form';
import { FormField, Input } from '@veo/web-design-system';
import { MeasurementSystems } from 'src/common/model/enums/MeasurementSystem';
import { MIN_HEIGHT, MAX_HEIGHT } from 'src/features/player-profile/constants/player';
import { isNumberValidator } from 'src/common/utils/form-validators';
import {
  MIN_FEET,
  MAX_FEET,
  KeyInfoFormValues,
  isValidFeetValidator,
  isValidInchesValidator,
  isValidHeightValidator,
  isValidImperialHeightValidator,
} from '../../../utils/key-information';
import styles from './key-information-form.module.scss';

export function HeightField() {
  const { t } = useTranslation();

  const { control, trigger, watch } = useFormContext<KeyInfoFormValues>();
  const measurementSystem = watch('measurementSystem');

  const isImperialSystem = measurementSystem === MeasurementSystems.Imperial;

  const {
    field: feetField,
    fieldState: feetFieldState,
  } = useController<KeyInfoFormValues, 'heightFeet'>({
    control,
    name: 'heightFeet',
    disabled: !measurementSystem || !isImperialSystem,
    rules: {
      validate: {
        isNumber: (v) => isNumberValidator(v),
        isValidRange: (v) => isValidFeetValidator(v),
        isValidHeight: (v, fv) => isValidImperialHeightValidator(fv.heightFeet, fv.heightInches),
      },
    },
  });

  const {
    field: inchesField,
    fieldState: inchesFieldState,
  } = useController<KeyInfoFormValues, 'heightInches'>({
    control,
    name: 'heightInches',
    disabled: !measurementSystem || !isImperialSystem,
    rules: {
      validate: {
        isNumber: (v) => isNumberValidator(v),
        isValidRange: (v) => isValidInchesValidator(v),
      },
    },
  });
  const {
    field: heightField,
    fieldState: heightFieldState,
  } = useController<KeyInfoFormValues, 'height'>({
    control,
    name: 'height',
    disabled: !measurementSystem || isImperialSystem,
    rules: {
      validate: {
        isNumber: (v) => isNumberValidator(v),
        isValidRange: (v) => isValidHeightValidator(v),
      },
    },
  });

  const { onBlur: onFeetBlur } = feetField;

  const {
    isTouched: isFeetTouched,
    error: feetError,
  } = feetFieldState;

  const { onBlur: onInchesBlur } = inchesField;

  const {
    isTouched: isInchesTouched,
    error: inchesError,
  } = inchesFieldState;

  const { onBlur: onHeightBlur } = heightField;

  const {
    isTouched: isHeightTouched,
    error: heightError,
  } = heightFieldState;

  function handleFeetBlur() {
    onFeetBlur();
    trigger(['heightFeet', 'heightInches']);
  }

  function handleInchesBlur() {
    onInchesBlur();
    trigger(['heightFeet', 'heightInches']);
  }

  function handleHeightBlur() {
    onHeightBlur();
    trigger('height');
  }

  if (isImperialSystem) {
    return (
      <>
        <FormField
          id="heightFeet"
          className={styles['field-small']}
          label={t('player_key_information.height_title', { unit: 'ft' })}
          error={isFeetTouched ? feetError?.message : undefined}
        >
          <Input
            type="number"
            min={MIN_FEET}
            max={MAX_FEET}
            {...feetField}
            onBlur={handleFeetBlur}
          />
        </FormField>
        <FormField
          id="heightInches"
          className={styles['field-small']}
          label={t('player_key_information.height_title', { unit: 'in' })}
          error={isInchesTouched ? inchesError?.message : undefined}
        >
          <Input
            type="number"
            min="0"
            max="11"
            {...inchesField}
            onBlur={handleInchesBlur}
          />
        </FormField>
      </>
    );
  }

  return (
    <FormField
      id="height"
      className={styles.field}
      label={t('player_key_information.height_title', { unit: 'cm' })}
      hint={measurementSystem ? undefined : t('player_key_information.form.measurement_system_hint') as string}
      error={isHeightTouched ? heightError?.message : undefined}
    >
      <Input
        type="number"
        min={MIN_HEIGHT}
        max={MAX_HEIGHT}
        {...heightField}
        onBlur={handleHeightBlur}
      />
    </FormField>
  );
}
