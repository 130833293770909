import { SocialMedia, SOCIAL_MEDIA_BASE_URL, SocialMedias } from './constants';

export const getSocialMediaUrl = (target: SocialMedia, id: string) => `${SOCIAL_MEDIA_BASE_URL[target]}${id}`;

export function isKnownSocialMedia(target: unknown): target is SocialMedia {
  if (typeof target === 'string' && target !== '') {
    return Object.values(SocialMedias).includes(target as unknown as SocialMedia);
  }

  return false;
}
