import { UseQueryOptions, useQuery } from 'react-query';
import { getPlayerTeams } from 'src/common/apis/teams.api';
import { EXTENDED_CACHE_TIME_MS, RQ_KEY } from 'src/common/constants/react-query';
import { RequesterError } from 'src/common/model/interfaces/IRequesterError';
import { ITeam } from 'src/common/model/interfaces/ITeam';
import { useAuthPlayer } from 'src/features/auth';

type Props<T> = Omit<UseQueryOptions<ITeam[], RequesterError, T>, 'queryKey' | 'queryFn'>;

export function useAuthPlayerTeams<T extends unknown = ITeam[]>(options?: Props<T>) {
  const { data: authPlayer } = useAuthPlayer();

  return useQuery<ITeam[], RequesterError, T>(
    [RQ_KEY.AUTH_PLAYER_TEAMS, authPlayer?.id],
    () => getPlayerTeams(authPlayer?.id as string),
    {
      ...options,
      enabled: !!authPlayer,
      cacheTime: EXTENDED_CACHE_TIME_MS,
      staleTime: EXTENDED_CACHE_TIME_MS,
    },
  );
}
