import { useState } from 'react';
import { get } from 'lodash-es';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { Button, Text } from '@veo/web-design-system';
import { IPlayer } from 'src/common/model/interfaces/IPlayer';
import { useImage } from 'src/common/hooks/useImage';
import { useAnalytics } from 'src/features/analytics/hooks/useAnalytics';
import { SocialLink, SocialMedia, SocialMedias } from 'src/components/social-link/social-link';
import { PlayerProtected } from 'src/features/auth';
import emptyFallbackImage from 'src/styles/svg/empty-image.svg';
import { AboutInformationModal } from './about-information-modal';
import { ProfileImageModal } from './profile-image-modal';
import styles from './about-information.module.scss';

type Props = {
  player: IPlayer
};

export function AboutInformation({ player }: Props) {
  const [editAbout, setEditAbout] = useState(false);
  const [editImage, setEditImage] = useState(false);

  const { t } = useTranslation();

  const { analytics, events } = useAnalytics();

  const { imageSrc } = useImage(player?.image ?? '', emptyFallbackImage);

  function handleMediaClicked(media: SocialMedia) {
    analytics?.track(events.SOCIAL_MEDIA_LINK_CLICKED, { platform: media });
  }

  const jerseyNumberClassnames = cn(
    styles['jersey-wrapper'],
    { [styles.hidden]: !player?.jerseyNumber },
  );

  return (
    <>
      <div className={styles.container}>
        <div className={styles.picture}>
          <img
            className={styles.image}
            src={imageSrc}
            alt={`${player?.firstName} ${player?.lastName}`}
          />
          <div className={jerseyNumberClassnames}>
            <div className={styles['jersey-number']}>
              {player?.jerseyNumber}
              <span className={styles['number-sign']}>#</span>
            </div>
          </div>
          <PlayerProtected predicate={(ap) => ap.id === player.id}>
            <Button
              className={styles['edit-picture']}
              size="md"
              iconEnd="camera"
              label={t('player_about.upload_image_button_label')}
              variant="outlined"
              onClick={() => setEditImage(true)}
            />
          </PlayerProtected>
        </div>
        <div className={styles.name}>
          <div className={styles.wrapper}>
            <div className={styles['player-name']}>{player?.firstName}</div>
            <div className={styles['player-name']}>{player?.lastName}</div>
          </div>
        </div>
        <div className={styles.social}>
          <div className={styles.icons}>
            {
              Object.values(SocialMedias).map((media) => {
                if (player && get(player, media)) {
                  return (
                    <SocialLink
                      key={media}
                      id={get(player, media)}
                      media={media}
                      onClick={handleMediaClicked}
                    />
                  );
                }

                return null;
              })
          }
          </div>
        </div>
        <div className={styles.about}>
          <Text
            className={styles.heading}
            size="h1"
            type="h2"
          >
            {t('player_about.heading')}
          </Text>
          {
          player?.about && (
            <Text
              className={styles.text}
              size="body"
              type="block"
              weight="medium"
            >
              {player.about}
            </Text>
          )
        }
          <PlayerProtected predicate={(ap) => ap.id === player.id}>
            <Button
              className={styles['edit-about']}
              flexible={false}
              size="md"
              iconEnd="edit-2"
              label={t('player_about.edit_button_label')}
              variant="outlined"
              onClick={() => setEditAbout(true)}
            />
          </PlayerProtected>
        </div>
      </div>
      <PlayerProtected predicate={(ap) => ap.id === player.id}>
        <AboutInformationModal
          player={player}
          open={editAbout}
          onClose={setEditAbout}
        />
        <ProfileImageModal
          open={editImage}
          player={player}
          onClose={setEditImage}
        />
      </PlayerProtected>
    </>
  );
}
