import { ReactNode, useMemo } from 'react';
import { Navigate } from 'react-router-dom';
import { APP_ROUTES } from 'src/common/constants/routes';
import { AuthUserRoles } from 'src/common/model/interfaces/IUser';
import { IPlayer } from 'src/common/model/interfaces/IPlayer';
import { useAuth } from '../../hooks/useAuth';

type Props = {
  children: ReactNode;
  redirect?: boolean;
  predicate?: (user: IPlayer) => boolean;
};

export function PlayerProtected({
  children,
  redirect = false,
  predicate = () => true,
}: Props) {
  const { data: user, isLoading } = useAuth();

  const isRestricted = useMemo(() => {
    if (!user) {
      return true;
    }

    const isPlayerRole = user.role === AuthUserRoles.player;

    return isPlayerRole && !predicate(user.data);
  }, [user]);

  if (isRestricted && !redirect) {
    return null;
  }

  if (!isLoading && isRestricted && redirect) {
    return <Navigate to={APP_ROUTES.forbidden} replace />;
  }

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{ children }</>;
}
