import { useEffect, useState } from 'react';
import { PageViewEventsType } from '../constants/segment-events';
import { useAnalytics } from './useAnalytics';

export function usePageViewEvent(page: PageViewEventsType) {
  const [visitedPage, setVisitedPage] = useState<string | null>(page);
  const { analytics } = useAnalytics();

  // Deffer the analytics call if it is not yet initialized
  useEffect(() => {
    if (analytics?.initialized && visitedPage) {
      analytics?.track(visitedPage);
      analytics?.page(visitedPage);

      setVisitedPage(null);
    }
  }, [analytics?.initialized, visitedPage]);
}
