/* eslint-disable react/jsx-props-no-spreading */
import React, { ReactNode } from 'react';
import cn from 'classnames';
import { Link } from 'react-router-dom';
import styles from './link.module.scss';

type BaseProps = {
  link: string;
  children: ReactNode;
  className?: string;
  external?: boolean;
  onClick?: () => void;
};

type ExternalProps = BaseProps & {
  external: true,
} & Omit<React.AnchorHTMLAttributes<HTMLAnchorElement>, 'onClick'>;

type InternalProps = BaseProps & {
  external?: false,
};

export function LinkWrapper({
  link, children, className, external = false, onClick, ...rest
}: InternalProps | ExternalProps) {
  const linkClassnames = cn(styles.container, className);

  if (external) {
    return (
      <a
        href={link}
        className={linkClassnames}
        rel="noreferrer"
        {...rest}
      >
        { children }
      </a>
    );
  }

  return (
    <Link className={linkClassnames} onClick={onClick} to={link} {...rest}>
      { children }
    </Link>
  );
}
