/** Canvas size that will be used to detect players and show the telestration overlay */
export const DEFAULT_CANVAS = {
  width: 640,
  height: 640,
};

/** Min and max allowed video timestamp threshold to add/show telestration */
export const MIN_TELESTRATION_TIMESTAMP_THRESHOLD = 1.5;
export const MAX_TELESTRATION_TIMESTAMP_THRESHOLD = 0.5;

/** Types of the telestration model, may be shape in the future */
export const TELESTRATION_TYPES = {
  BOX: 'box',
};
export const DEFAULT_TELESTRATION_TYPE = TELESTRATION_TYPES.BOX;

/** Spotlight Text block aspect ratio (based on svg from figma design).
  * Calculated from svg viewbox of that element.
  * Required due to the fact that parsed image and source video have different aspect ratios
  * Example: viewBox="0 0 100 200" aspectRatio = 100 / 200.
  */
export const SPOTLIGHT_TEXTBOX_ASPECT_RATIO = 9.3;

/** Configuration variables to define video slowdown behavior.
  * SLOWDOWN_DURATION - timeframe (in seconds) for slowing down the video before telestration should be shown
  * SLOWDOWN_STEPS - amount of steps the playback rate should be decresed
  * SLOWDOWN_STEP_DURATION - interval for each slowdown decrement step
  * SLOWDOWN_STATIC_THRESHOLD - determine the amount of seconds that should be deducted from slowdowntime
  *   to define the start point for triggering slowdown
  * SLOWDOWN_TOLERANCE - determine the amount of seconds that should be deducted from slowdowntime to define the end point for triggering slowdown.
  *   It appeared that for some videos Safari does not respect the exact time while changing Media time. For ex. for 1.64 it may change to 1.639999.
  *   So to prevent the endless loop this value used as the latest time to trigger the slowdown instead of actual timestamp
  */
export const SLOWDOWN_DURATION = 1;
/** @deprecated */
export const SLOWDOWN_STEPS = 10;
/** @deprecated */
export const SLOWDOWN_STEP_DURATION = SLOWDOWN_DURATION / SLOWDOWN_STEPS;
export const SLOWDOWN_STATIC_THRESHOLD = 0.08;
export const SLOWDOWN_TOLERANCE = 0.01;
