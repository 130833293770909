import {
  useState, useEffect, useRef, useMemo,
} from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { flatten, takeRight, sortBy } from 'lodash-es';
import { Text, Button, IconButton } from '@veo/web-design-system';
import { IPlayerClip } from 'src/common/model/interfaces/IPlayerClip';
import { Paper } from 'src/components/paper/paper';
import { useMediaQuery } from 'src/common/hooks/useMediaQuery';
import { MEDIA_QUERY } from 'src/common/constants/media-query';
import { useOrderPlayerClipsMutation } from 'src/common/hooks/player-clips/useOrderPlayerClipsMutation';
import { useIntersectionObserver } from 'src/common/hooks/useIntersectionObserver';
import { PlayerClipsFilter, usePlayerClips } from 'src/common/hooks/player-clips/usePlayerClips';
import {
  OrderAction, OrderableDndOptions, OrderableList, arrayMove,
} from 'src/components/orderable';
import { SortablePlayerClip } from './sortable-player-clip';
import { PlayerClipPanel } from './player-clip-panel';
import { PlayerClipsSkeleton } from '../player-clips-skeleton/player-clips-skeleton';
import styles from './sortable-player-clips.module.scss';

type Payload = Record<string, IPlayerClip>;

type Props = {
  filter: PlayerClipsFilter;
  onClose: VoidFunction;
  onReorder?: VoidFunction;
};

export function SortablePlayerClips({ filter, onClose, onReorder }: Props) {
  const reorderPayloadRef = useRef<Payload>({});
  const isMobileLayout = useMediaQuery(MEDIA_QUERY.MOBILE);

  const {
    data: infiniteList,
    isLoading: isPlayerClipsLoading,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
  } = usePlayerClips({ pageSize: 10, filter });

  const totalCount = infiniteList?.pages[0].meta.totalCount ?? 0;

  const playerClips = useMemo(() => {
    if (infiniteList) {
      const allPagesItems = infiniteList.pages.map(({ items }) => items);
      const allHighlights = flatten(allPagesItems);

      return allHighlights;
    }

    return [];
  }, [infiniteList]);

  const isLoading = isPlayerClipsLoading || isFetchingNextPage;

  const { ref: lastItemRef } = useIntersectionObserver<HTMLDivElement>({
    enabled: infiniteList && hasNextPage && !isLoading,
    onIntersect: fetchNextPage,
  });

  const [pcOrder, setPcOrder] = useState(playerClips);

  const { t } = useTranslation();

  const { mutate: orderPlayerClips, isLoading: isOrdering } = useOrderPlayerClipsMutation();

  useEffect(() => {
    if (playerClips.length !== pcOrder.length) {
      const addedIds = takeRight(playerClips, playerClips.length - pcOrder.length);

      /** Order ascending when new batch fetched - important if we move bottom or top */
      setPcOrder((prev) => {
        const merged = [...prev, ...addedIds];

        return sortBy(merged, (pc) => pc.order);
      });
    }
  }, [playerClips]);

  function handleSortChange(newOrder: IPlayerClip[], meta: OrderAction<IPlayerClip>) {
    const { data: movedPlayerClip, to } = meta;

    const updatedOrder = newOrder.map((pc) => {
      const { id } = pc;

      if (id !== movedPlayerClip.id) {
        return pc;
      }

      let newTimestamp: number;

      if (to === 0) {
        newTimestamp = -(new Date().getTime());
      } else if (to === (totalCount - 1)) {
        newTimestamp = new Date().getTime();
      } else {
        const prev = newOrder[to - 1];
        const next = newOrder[to + 1];

        newTimestamp = prev.order + Math.floor(Math.abs(next.order - prev.order) / 2);
      }

      const result = {
        ...pc,
        order: newTimestamp,
      };

      reorderPayloadRef.current[movedPlayerClip.id] = result;

      return result;
    });

    setPcOrder(updatedOrder);
  }

  function handleMoveTop(item: IPlayerClip) {
    const from = pcOrder.findIndex(({ id }) => item.id === id);

    const nextOrder = arrayMove([...pcOrder], from, 0);

    handleSortChange(nextOrder, { data: item, from, to: 0 });
  }

  function handleMoveBottom(item: IPlayerClip) {
    const from = pcOrder.findIndex(({ id }) => item.id === id);

    // todo: Only works within existing indexes
    const nextOrder = arrayMove([...pcOrder], from, totalCount - 1);

    handleSortChange(nextOrder, { data: item, from, to: totalCount - 1 });
  }

  async function handleSaveOrder() {
    const affectedPlayerClips = Object.values(reorderPayloadRef.current);

    if (affectedPlayerClips.length) {
      orderPlayerClips(affectedPlayerClips, {
        onSuccess() {
          if (onReorder) {
            onReorder();
          }
          onClose();
        },
      });
    } else {
      onClose();
    }
  }

  function renderDragOverlay(item: IPlayerClip, options: OrderableDndOptions) {
    const { isDragging } = options;

    const overlayClassnames = cn(
      styles.item,
      { [styles.dragging]: isDragging },
    );

    return (
      <PlayerClipPanel
        clip={item}
        className={overlayClassnames}
        showStackedAbilityTooltip={isDragging ? false : undefined}
      >
        <IconButton
          variant="text"
          size="sm"
          icon="more-vertical"
        />
      </PlayerClipPanel>
    );
  }

  return (
    <Paper elevation={3} className={styles.container}>
      <div className={styles.header}>
        <Text
          weight="medium"
          size="h5"
          type="block"
        >
          { t('profile_video.reorder.title') }
        </Text>
        <Text
          type="block"
          size="caption"
        >
          { t('profile_video.reorder.description') }
        </Text>
        <div className={styles.actions}>
          <Button
            size="md"
            variant="outlined"
            disabled={isOrdering}
            label={t('profile_video.reorder.cancel_order_button_label')}
            onClick={onClose}
          />
          <Button
            size="md"
            variant="secondary"
            loading={isOrdering}
            disabled={isOrdering}
            label={t('profile_video.reorder.save_order_button_label')}
            iconEnd="check"
            onClick={handleSaveOrder}
          />
        </div>
      </div>
      <OrderableList<IPlayerClip>
        items={pcOrder}
        renderDragOverlay={renderDragOverlay}
        onSorted={handleSortChange}
        activationDelay={150}
      >
        <div className={styles.list}>
          {
            pcOrder.map((pc) => {
              const isLast = pc.id === pcOrder[pcOrder.length - 1].id;

              const disabled = {
                draggable: false,
                droppable: isLast && pcOrder.length !== totalCount,
              };

              return (
                <SortablePlayerClip
                  ref={isLast ? lastItemRef : undefined}
                  key={pc.id}
                  clip={pc}
                  disabled={isOrdering || disabled}
                  onMoveBottom={handleMoveBottom}
                  onMoveTop={handleMoveTop}
                />
              );
            })
          }
          {
            isFetchingNextPage && <PlayerClipsSkeleton mobile={isMobileLayout} />
          }
        </div>
      </OrderableList>
    </Paper>
  );
}
