import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Spinner, ErrorPage } from '@veo/web-design-system';
import { PAGE_VIEW_EVENTS } from 'src/features/analytics/constants/segment-events';
import { isRequesterError } from 'src/common/utils/requester-errors';
import { usePublicProfile } from 'src/common/hooks/player/usePublicProfile';
import { ProfileVideo } from 'src/features/profile-video/profile-video';
import { useOnboarding } from 'src/features/onboarding';
import { useAuthPlayer } from 'src/features/auth';
import { KeyInformation } from './components/key-information/key-information';
import { usePageViewEvent } from '../analytics/hooks/usePageViewEvent';
import { NotFoundProfile } from './components/not-found-profile/not-found-profile';
import { PositionInformation } from './components/position-information/position-information';
import { AboutInformation } from './components/about-information/about-information';
import { MetaTags } from './components/meta-tags/meta-tags';
import { ShareProfile } from './components/share-profile';
import { PlayerPanel } from './components/player-panel';
import styles from './player-profile.module.scss';

export function PlayerProfile() {
  const { id: playerIdentifier } = useParams();
  const { data: authPlayer } = useAuthPlayer();

  const { t } = useTranslation();

  usePageViewEvent(PAGE_VIEW_EVENTS.PROFILE_VISITED);

  const {
    data: playerInfo,
    isLoading: isPlayerLoading,
    error,
  } = usePublicProfile(playerIdentifier);

  useOnboarding({ enabled: playerInfo?.id === authPlayer?.id });

  if (isRequesterError(error) && error.statusCode === 404) {
    return <NotFoundProfile />;
  }

  if (isRequesterError(error) && error.statusCode !== 404) {
    return (
      <ErrorPage
        illustration="something-went-wrong"
        title={t('error_state.title')}
        description={t('error_state.description')}
        buttonText={t('error_state.reload_page_button_label') as string}
        onButtonClick={() => window.location.reload()}
      />
    );
  }

  if (isPlayerLoading) {
    return (
      <Spinner className={styles.loader} size="lg" />
    );
  }

  if (!playerInfo) {
    return null;
  }

  return (
    <>
      <MetaTags />
      <div className={styles.container}>
        <div className={styles['player-panel']}>
          <PlayerPanel player={playerInfo} />
        </div>
        <div className={styles.share}>
          <ShareProfile player={playerInfo} />
        </div>
        <div className={styles.video}>
          <ProfileVideo player={playerInfo} />
        </div>
        <div className={styles.about}>
          <AboutInformation player={playerInfo} />
        </div>
        <div className={styles['key-info']}>
          <KeyInformation player={playerInfo} />
        </div>
        <div className={styles.position}>
          <PositionInformation player={playerInfo} />
        </div>
      </div>
    </>
  );
}
