import { ITelestration, ICapturedFramePayload } from 'src/common/model/interfaces/ITelestration';
import { playerSvc } from '../services/player-svc';

export async function createPlayerClipTelestration(playerClipId: string, playerId: string, data: ITelestration): Promise<unknown> {
  const url = `telestrations/playerClip/${playerClipId}/players/${playerId}`;

  const res = await playerSvc.request({ method: 'POST', url, data });

  return res;
}

export async function deletePlayerClipTelestration(id: string): Promise<unknown> {
  const url = `/telestrations/${id}`;

  const res = await playerSvc.request({ method: 'DELETE', url });

  return res;
}

export async function detectTelestration(data: ICapturedFramePayload): Promise<ITelestration> {
  const {
    timestamp, type, image, canvas,
  } = data;
  const url = '/telestrations/detect';
  const formData = new FormData();

  formData.append('image', image, `frame_${type}_${timestamp}.jpeg`);
  formData.append('timestamp', JSON.stringify(timestamp));
  formData.append('type', type);
  formData.append('width', `${canvas.width}`);
  formData.append('height', `${canvas.height}`);

  const res = await playerSvc.request({
    method: 'POST',
    url,
    data: formData,
  });

  return res as ITelestration;
}
