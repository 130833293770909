import { useTranslation } from 'react-i18next';
import { useFormContext, useController } from 'react-hook-form';
import { FormField } from '@veo/web-design-system';
import { Dropdown } from 'src/components/dropdown/dropdown';
import { KeyInfoFormValues, getStrongFootOptions } from '../../../utils/key-information';
import styles from './key-information-form.module.scss';

export function StrongFootField() {
  const { t } = useTranslation();

  const { control } = useFormContext<KeyInfoFormValues>();

  const { field, fieldState } = useController<KeyInfoFormValues, 'strongFoot'>({
    control,
    name: 'strongFoot',
  });

  const {
    isTouched,
    error,
  } = fieldState;

  const {
    value,
    onChange,
    ...restFieldProps
  } = field;

  return (
    <FormField
      id="strongFoot"
      className={styles.field}
      label={t('player_key_information.strong_foot_title')}
      error={isTouched ? error?.message : undefined}
    >
      <Dropdown
        multi={false}
        clearable
        placeholder={t('player_key_information.form.strong_foot_field_placeholder')}
        value={value && { value, label: t(`entity.strong_foot.${value}`) }}
        options={getStrongFootOptions()}
        onChange={(option) => onChange(option?.value ?? null)}
        {...restFieldProps}
      />
    </FormField>
  );
}
