import { UseQueryOptions, useQuery } from 'react-query';
import { getUserTeams } from 'src/common/apis/teams.api';
import { EXTENDED_CACHE_TIME_MS, RQ_KEY } from 'src/common/constants/react-query';
import { RequesterError } from 'src/common/model/interfaces/IRequesterError';
import { ITeam } from 'src/common/model/interfaces/ITeam';
import { useOidcAuth } from 'src/features/oidc';

type Props<T> = Omit<UseQueryOptions<ITeam[], RequesterError, T>, 'queryKey' | 'queryFn'>;

export function useUserTeams<T extends unknown = ITeam[]>(options?: Props<T>) {
  const { data: authUser } = useOidcAuth();

  return useQuery<ITeam[], RequesterError, T>(
    [RQ_KEY.AUTH_USER_TEAMS, authUser?.externalId],
    () => getUserTeams(authUser?.externalId as string),
    {
      ...options,
      enabled: !!authUser,
      cacheTime: EXTENDED_CACHE_TIME_MS,
      staleTime: EXTENDED_CACHE_TIME_MS,
    },
  );
}
