import { QueryParams } from '../hooks/useQueryParams';
import { IPaginatedResponse } from '../model/interfaces/IPaginatedResponse';
import { IZolaClip } from '../model/interfaces/IZolaClip';
import { playerSvc } from '../services/player-svc';
import { getUrlQueryParams } from '../utils/query-params';

export async function getAuthPlayerZolaClips(
  playerId: string,
  queryParams?: Partial<QueryParams>,
  signal?: any,
): Promise<IPaginatedResponse<IZolaClip>> {
  let params;

  if (queryParams) {
    params = getUrlQueryParams(queryParams);
  }

  const url = `/zolaClips/by/playerId/${playerId}`;

  const res = await playerSvc.request({
    method: 'GET', url, params, signal,
  });

  return res as IPaginatedResponse<IZolaClip>;
}
