import { QueryClientConfig } from 'react-query';

export const RQ_KEY = {
  OIDC_USER_CLUBS: 'oidc_user_clubs',
  AUTH_USER: 'auth_user',
  AUTH_USER_SETTINGS: 'auth_user_settings',
  AUTH_USER_TEAMS: 'auth_user_teams',
  AUTH_USER_SYNC: 'auth_user_sync',
  AUTH_PLAYER_TEAMS: 'auth_player_teams',
  AUTH_PLAYER_MATCHES: 'auth_player_matches',
  AUTH_PLAYER_PAGINATED_MATCHES: 'auth_player_paginated_matches',
  AUTH_PLAYER_ZOLA_CLIPS: 'auth_player_zola_clips',
  AUTH_PLAYER_CLIP: 'auth_player_clip',
  ABILITIES: 'abilities',
  CLUB_TAGS: 'club_tags',
  PLAYER_ABILITIES: 'player_abilities',
  PLAYER_CLIPS: 'player_clips',
  PROFILE_TELESTRATIONS: 'profile_telestrations',
  PROFILE_INFO: 'profile_info',
  TEAM_MEMBERS: 'team_members',
};

export const DEFAULT_CACHE_TIME_MS = 1000 * 60;
export const EXTENDED_CACHE_TIME_MS = 1000 * 60 * 5;

export const DEFAULT_QUERY_CLIENT_CONFIG: QueryClientConfig = {
  defaultOptions: {
    queries: {
      suspense: false,
      enabled: true,
      retry: false,
      staleTime: 1000,
      cacheTime: DEFAULT_CACHE_TIME_MS,
      refetchOnWindowFocus: false,
      refetchInterval: false,
      refetchOnMount: true,
      useErrorBoundary: false,
    },
  },
};
