import { useController, useFormContext } from 'react-hook-form';
import { PositionPlaystyleFormValues } from 'src/features/player-profile/utils/position-information';
import { PlayStyle } from 'src/common/model/enums/Position';
import { PlayStylePicker } from '../playstyle-picker';
import styles from './position-information-form.module.scss';

export function PlaystyleField() {
  const { control, watch } = useFormContext<PositionPlaystyleFormValues>();

  const { field } = useController<PositionPlaystyleFormValues, 'playstyle'>({
    control,
    name: 'playstyle',
    rules: {
      validate: () => true,
    },
  });

  const positions = watch('positions');

  const {
    value,
    onChange,
    onBlur,
  } = field;

  function handlePlaystyleChange(ps: PlayStyle) {
    onChange(ps);
    onBlur();
  }

  return (
    <div className={styles.playstyle}>
      <PlayStylePicker
        position={positions[0]}
        playstyle={value}
        onChange={handlePlaystyleChange}
      />
    </div>
  );
}
