import { useTranslation } from 'react-i18next';
import { useForm, FormProvider } from 'react-hook-form';
import { Modal, ModalHeader } from '@veo/web-design-system';
import { Confirmation, useConfirmation } from 'src/components/confirmation';
import { IPlayer } from 'src/common/model/interfaces/IPlayer';
import { PositionInformationForm } from './form';
import { PositionPlaystyleFormValues, getPositionPlaystyleFormValues } from '../../utils/position-information';
import styles from './position-information-modal.module.scss';

type Props = {
  open: boolean;
  player: IPlayer;
  onClose(): void;
};

export function PositionInformationModal({ open, player, onClose }: Props) {
  const { t } = useTranslation();

  const formConfig = useForm<PositionPlaystyleFormValues>({
    values: getPositionPlaystyleFormValues(player),
    mode: 'onSubmit',
  });

  const { formState, reset } = formConfig;
  const { isDirty } = formState;

  function handleCloseModal() {
    onClose();
    reset();
  }

  const { triggerAction: confirmAndClose, ...restConfirmationProps } = useConfirmation({
    action: handleCloseModal,
    required: isDirty,
  });

  return (
    <>
      <Confirmation
        title={t('player_position.close_confirmation.title')}
        message={t('player_position.close_confirmation.description') as string}
        confirmLabel={t('player_position.close_confirmation.confirm_button_label') as string}
        cancelLabel={t('player_position.close_confirmation.cancel_button_label') as string}
        {...restConfirmationProps}
      />
      <Modal
        className={styles.modal}
        open={open}
        onClose={confirmAndClose}
      >
        <ModalHeader
          title={t('player_position.heading')}
          description={t('player_position.description') as string}
        />
        <FormProvider<PositionPlaystyleFormValues> {...formConfig}>
          <PositionInformationForm
            onSubmit={handleCloseModal}
            onCancel={confirmAndClose}
          />
        </FormProvider>
      </Modal>
    </>
  );
}
