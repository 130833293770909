import { useTranslation } from 'react-i18next';
import { useFormContext, useController } from 'react-hook-form';
import { FormField, Input } from '@veo/web-design-system';
import { isJerseyNumberValidator } from 'src/common/utils/form-validators';
import { AboutInfoFormValues } from '../../../utils/about-information';
import styles from './about-information-form.module.scss';

export function JerseyField() {
  const { t } = useTranslation();

  const { control, register } = useFormContext<AboutInfoFormValues>();

  const { fieldState } = useController<AboutInfoFormValues, 'jerseyNumber'>({
    control,
    name: 'jerseyNumber',
    rules: {
      validate: (v) => isJerseyNumberValidator(v),
    },
  });

  const {
    isTouched,
    error,
  } = fieldState;

  return (
    <FormField
      className={styles.field}
      id="jerseyNumber"
      label={t('player_about.form.jersey_number_field_label')}
      error={isTouched ? error?.message : undefined}
    >
      <Input
        type="number"
        min="1"
        max="99"
        {...register('jerseyNumber')}
      />
    </FormField>
  );
}
