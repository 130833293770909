import { Text } from '@veo/web-design-system';
import { useTranslation } from 'react-i18next';
import { AbilityCode } from 'src/common/model/enums/AbilityCode';
import { useAbilities } from 'src/common/hooks/abilities/useAbilities';
import { ColorBar } from './color-bar';
import { AbilityTag } from './ability-tag';

type Props = {
  code: AbilityCode;
  active?: boolean;
  className?: string;
  onClick?: VoidFunction;
};

export function AbilityItem({
  code, active = false, className, onClick,
}: Props) {
  const { t } = useTranslation();

  const { data } = useAbilities();

  if (!data) {
    return null;
  }

  const color = data.find(({ code: abilityCode }) => code === abilityCode)?.color;

  return (
    <AbilityTag
      className={className}
      onClick={onClick}
      active={active}
    >
      <ColorBar active={active} color={color!} />
      <Text
        type="block"
        size="overline"
        weight="semiBold"
      >
        { t(`entity.category.${code}`) }
      </Text>
    </AbilityTag>
  );
}
