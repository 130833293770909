import { ValueOf } from '../types/helper.type';

export const AbilityCodes = {
  Acceleration: 'Acceleration',
  AerialAbility: 'Aerial Ability',
  Aggression: 'Aggression',
  BallControl: 'Ball control',
  Corners: 'Corners',
  Crossing: 'Crossing',
  Dribbling: 'Dribbling',
  Finishing: 'Finishing',
  FreeKicks: 'Free kicks',
  Heading: 'Heading',
  Interceptions: 'Interceptions',
  Jumping: 'Jumping',
  Kicking: 'Kicking',
  Leadership: 'Leadership',
  LongPassing: 'Long passing',
  LongShots: 'Long shots',
  LongThrows: 'Long throws',
  Marking: 'Marking',
  OneOnOnes: 'One-on-ones',
  Passing: 'Passing',
  PenaltyTaking: 'Penalty taking',
  Positioning: 'Positioning',
  SlidingTackles: 'Sliding tackles',
  ShotStopping: 'Shot stopping',
  Speed: 'Speed',
  Strength: 'Strength',
  Tackling: 'Tackling',
  Technique: 'Technique',
  Vision: 'Vision',
} as const;

export type AbilityCode = ValueOf<typeof AbilityCodes>;
