import { Text, Button } from '@veo/web-design-system';
import { useTranslation } from 'react-i18next';
import { AddPlayerClip } from 'src/features/add-player-clip/components/add-player-clip';
import { Paper } from 'src/components/paper/paper';
import { PlayerProtected, useAuthPlayer } from 'src/features/auth';
import { IPlayer } from 'src/common/model/interfaces/IPlayer';
import styles from './empty-player-clips.module.scss';

type Props = {
  player: IPlayer
};

export function EmptyPlayerClips({ player }: Props) {
  const { t } = useTranslation();
  const { data: authPlayer } = useAuthPlayer();

  const title = authPlayer?.id === player?.id
    ? t('profile_video.empty.own_title')
    : t('profile_video.empty.public_title');

  const description = authPlayer?.id === player?.id
    ? t('profile_video.empty.own_description')
    : t('profile_video.empty.public_description', { name: player.firstName });

  return (
    <Paper
      elevation={1}
      className={styles.container}
    >
      <Text
        size="h2"
        type="h2"
        weight="semiBold"
        className={styles.text}
      >
        { title }
      </Text>
      <Text
        type="block"
        weight="medium"
        className={styles.text}
      >
        { description }
      </Text>
      <PlayerProtected predicate={(ap) => ap.id === player.id}>
        <AddPlayerClip>
          <Button
            size="lg"
            variant="secondary"
            iconEnd="plus"
            label={t('profile_video.empty.add_button_label')}
          />
        </AddPlayerClip>
      </PlayerProtected>
    </Paper>
  );
}
