import { RefObject, useEffect } from 'react';
import { IPlayer } from 'src/common/model/interfaces/IPlayer';
import { ITelestration } from 'src/common/model/interfaces/ITelestration';
import { Nullable } from 'src/common/model/types/helper.type';
import { SpotlightManager, RectRef } from '../spotlight-manager/spotlight-manager';
import { Spotlight } from '../spotlight/spotlight';
import { TelestrationCanvas } from '../telestration-canvas/telestration-canvas';
import { useTimeOffset } from '../../hooks/useTimeOffset';
import { useTelestrationDebug } from '../debug-panel/debug-context';

type Props = {
  data: Nullable<ITelestration>;
  player?: IPlayer;
  className?: string;
  enabled?: boolean;
  videoRef: RefObject<HTMLVideoElement>;
  onSpotlightShow?(rectRef: RectRef): void;
  onSpotlightHide?(rectRef: RectRef): void;
};

export function TelestrationOverlay({
  data, player, className, videoRef, enabled = true, onSpotlightHide, onSpotlightShow,
}: Props) {
  const playerName = `${player?.jerseyNumber ?? ''} ${player?.firstName} ${player?.lastName}`.trim();

  const { resetValues } = useTelestrationDebug();

  const timeOffset = useTimeOffset({ videoRef });

  useEffect(() => () => {
    resetValues();
  }, [data]);

  if (!data) {
    return null;
  }

  return (
    <TelestrationCanvas {...data.canvas} className={className}>
      {
        data.items.map((telestration) => (
          <SpotlightManager
            key={telestration.id}
            timeOffset={timeOffset}
            videoRef={videoRef}
            enabled={enabled}
            timestamp={telestration.timestamp}
            onShow={onSpotlightShow}
            onHide={onSpotlightHide}
          >
            <Spotlight boundingBox={telestration} title={playerName} />
          </SpotlightManager>
        ))
      }
    </TelestrationCanvas>
  );
}
