import { ValueOf } from '../types/helper.type';
import { IPlayer } from './IPlayer';

export interface IUser {
  player?: IPlayer;
  coach?: unknown;
}

export interface IOidcUser {
  id: string;
  email: string;
  externalId: string;
  firstName: string;
  lastName: string;
  avatar?: string;
  birthdate?: string;
}

export const AuthUserRoles = {
  player: 'player',
  coach: 'coach',
} as const;

export type AuthUserRole = ValueOf<typeof AuthUserRoles>;

export type AuthPlayer = {
  role: 'player';
  data: IPlayer;
};

export type AuthCoach = {
  role: 'coach';
  data: unknown;
};

export type AuthUser = AuthPlayer | AuthCoach;
