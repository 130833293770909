export function getRandomInt(min: number, max: number): number {
  return Math.floor(Math.random() * (max - min + 1) + min);
}

export async function sleep(timeout: number = 500) {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(true);
    }, timeout);
  });
}

// todo: Change precision to default undefined and fix default precision 1 in telestration
export function formatFloat(value: number, precision?: number) {
  return parseFloat(value.toFixed(precision ?? 1));
}
