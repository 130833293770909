import { Modal, ModalHeader, ModalActions } from '@veo/web-design-system';
import i18n from 'src/common/services/i18next';

export type ConfirmationProps = {
  open: boolean;
  title: string;
  message?: string;
  onConfirm(): void;
  onCancel(): void;
  confirmLabel?: string;
  cancelLabel?: string;
};

export function Confirmation({
  open,
  title,
  message,
  confirmLabel = i18n.t('confirmation.confirm_label') as string,
  cancelLabel = i18n.t('confirmation.cancel_label') as string,
  onConfirm,
  onCancel,
}: ConfirmationProps) {
  return (
    <Modal
      open={open}
      closeOnClickOutside={false}
      closeOnEscape={false}
      onClose={onCancel}
    >
      <ModalHeader
        title={title}
        description={message}
      />
      <ModalActions
        confirmLabel={confirmLabel}
        cancelLabel={cancelLabel}
        onConfirm={onConfirm}
      />
    </Modal>
  );
}
