import { ForwardedRef, forwardRef } from 'react';
import cn from 'classnames';
import { mergeRefs } from 'src/common/utils/refs';
import { IPlayerClip } from 'src/common/model/interfaces/IPlayerClip';
import { OrderableDisabled, useOrderable } from 'src/components/orderable';
import { SortMenu } from './sort-menu';
import { PlayerClipPanel } from './player-clip-panel';
import styles from './sortable-player-clips.module.scss';

type Props = {
  clip: IPlayerClip,
  disabled: OrderableDisabled;
  onMoveTop(pc: IPlayerClip): void;
  onMoveBottom(pc: IPlayerClip): void;
};

function SortablePlayerClipCmp(
  {
    clip: pc,
    disabled,
    onMoveBottom,
    onMoveTop,
  }: Props,
  ref: ForwardedRef<HTMLDivElement>,
) {
  const {
    setNodeRef,
    attributes,
    listeners,
    style,
    options,
  } = useOrderable<IPlayerClip>({ item: pc, disabled });

  const { isHovered } = options;

  const panelClassnames = cn(
    styles.item,
    { [styles.over]: isHovered },
  );

  return (
    <PlayerClipPanel
      ref={mergeRefs([setNodeRef, ref])}
      clip={pc}
      {...attributes}
      {...listeners}
      style={style}
      elevation={1}
      className={panelClassnames}
    >
      <SortMenu
        className={styles.menu}
        item={pc}
        onMoveBottom={onMoveBottom}
        onMoveTop={onMoveTop}
      />
    </PlayerClipPanel>
  );
}

export const SortablePlayerClip = forwardRef<HTMLDivElement, Props>(SortablePlayerClipCmp);
