import { uniqueId } from 'lodash-es';
import { Skeleton } from 'src/components/skeletons/skeleton';

type Props = {
  mobile?: boolean;
  horizontal?: boolean;
  count?: number;
};

export function PlayerClipsSkeleton({
  mobile = false,
  horizontal = false,
  count = 5,
}: Props) {
  function getWidth() {
    if (mobile && horizontal) {
      return '48px';
    }

    return '100%';
  }

  function getHeight() {
    if (mobile && horizontal) {
      return '48px';
    }

    if (mobile) {
      return '58px';
    }

    return '60px';
  }

  return (
    <>
      {
        new Array(count).fill(1).map(() => (
          <Skeleton
            key={uniqueId('skeleton')}
            width={getWidth()}
            height={getHeight()}
          />
        ))
      }
    </>
  );
}
