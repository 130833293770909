import { useTranslation } from 'react-i18next';
import { useFormContext, useController } from 'react-hook-form';
import { FormField, Input } from '@veo/web-design-system';
import { isRequiredValidator } from 'src/common/utils/form-validators';
import { AboutInfoFormValues } from '../../../utils/about-information';
import styles from './about-information-form.module.scss';

export function LastnameField() {
  const { t } = useTranslation();

  const { control, register } = useFormContext<AboutInfoFormValues>();

  const { fieldState } = useController<AboutInfoFormValues, 'lastName'>({
    control,
    name: 'lastName',
    rules: {
      validate: (v) => isRequiredValidator(v),
    },
  });

  const {
    isTouched,
    error,
  } = fieldState;

  return (
    <FormField
      className={styles.field__small}
      label={t('player_about.form.lastname_field_label')}
      id="lastName"
      error={isTouched ? error?.message : undefined}
    >
      <Input
        type="text"
        {...register('lastName')}
      />
    </FormField>
  );
}
