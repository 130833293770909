/* eslint-disable react/jsx-props-no-spreading */
import {
  useRef, useEffect, RefObject, SyntheticEvent,
} from 'react';
import cn from 'classnames';
import { DraggableSyntheticListeners, useDraggable } from '@dnd-kit/core';
import {
  useFloating,
  autoUpdate,
  offset,
  flip,
  FloatingPortal,
  shift,
} from '@floating-ui/react';
import { IconButton, Icon } from '@veo/web-design-system';
import { FrameCanvas, ITelestrationItem } from 'src/common/model/interfaces/ITelestration';
import { Spotlight } from '../spotlight/spotlight';
import { getSVGRectWidth, changeCustomSpotlightSize, getDraggableRectTransform } from '../../utils/parsed-telestration';
import styles from './custom-spotlight.module.scss';

type Props = {
  data: ITelestrationItem;
  canvas: FrameCanvas;
  title: string;
  containerRef: RefObject<HTMLDivElement>;
  onChange(spotlight: ITelestrationItem): void;
};

export function CustomSpotlight({
  data, canvas, title, containerRef, onChange,
}: Props) {
  const rectRef = useRef<SVGRectElement>(null);

  const {
    listeners, setNodeRef, transform, isDragging,
  } = useDraggable({
    id: 'draggable-rect',
  });

  const { refs, floatingStyles } = useFloating({
    open: !!data && !isDragging,
    middleware: [
      offset(getSVGRectWidth(data.points)),
      flip({ boundary: containerRef.current as Element, fallbackAxisSideDirection: 'start' }),
      shift(),
    ],
    whileElementsMounted: autoUpdate,
  });

  const { setFloating } = refs;

  useEffect(() => {
    if (rectRef.current) {
      refs.setReference(rectRef.current);
      setNodeRef(rectRef.current as unknown as HTMLElement);
    }
  }, []);

  function decreaseSize(e: SyntheticEvent) {
    e.stopPropagation();

    const nextPoints = changeCustomSpotlightSize(data.points, 'decrease');

    onChange({ ...data, points: nextPoints });
  }

  function increaseSize(e: SyntheticEvent) {
    e.stopPropagation();

    const nextPoints = changeCustomSpotlightSize(data.points);

    onChange({ ...data, points: nextPoints });
  }

  const rectTransform = getDraggableRectTransform({
    canvas,
    transform,
    viewContainerRef: containerRef,
  });

  const rectProps = {
    ...(listeners as DraggableSyntheticListeners),
    style: {
      cursor: isDragging ? 'move' : 'grab',
      transform: rectTransform,
    },
  };

  const positionControlClassnames = cn(
    styles['position-control'],
    { [styles.moving]: isDragging },
  );

  return (
    <>
      <FloatingPortal root={containerRef}>
        <div
          ref={setFloating}
          className={styles['controls-container']}
          style={floatingStyles}
        >
          <div className={styles['size-control']}>
            <IconButton
              size="xs"
              variant="text"
              icon="minus"
              onClick={decreaseSize}
            />
            <IconButton
              size="xs"
              variant="text"
              icon="plus"
              onClick={increaseSize}
            />
          </div>
          <div
            className={positionControlClassnames}
            {...(listeners as DraggableSyntheticListeners)}
          >
            <Icon name="move" size="sm" />
          </div>
        </div>
      </FloatingPortal>
      <Spotlight
        ref={rectRef}
        visible={!isDragging}
        boundingBoxVisible
        boundingBox={data}
        title={title}
        rectProps={rectProps}
      />
    </>
  );
}
