import { useMemo } from 'react';
import cn from 'classnames';
import { Text } from '@veo/web-design-system';
import { StepperSize, StepperSizes } from './types';
import styles from './stepper.module.scss';

type Props = {
  label: string;
  active: boolean;
  size: StepperSize;
};

export function Step({ label, active, size }: Props) {
  const stepClassNames = cn(
    styles.step,
    { [styles.step__active]: active },
  );

  const textSize = useMemo(() => {
    switch (size) {
      case StepperSizes.sm:
        return 'overline';

      case StepperSizes.md:
        return 'caption';

      case StepperSizes.lg:
        return 'body';

      default:
        return 'overline';
    }
  }, []);

  return (
    <div className={stepClassNames}>
      <Text weight="semiBold" size={textSize}>
        { label }
      </Text>
    </div>
  );
}
