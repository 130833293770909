import { ChangeEvent, useRef } from 'react';
import cn from 'classnames';
import { Icon } from '@veo/web-design-system';
import { useReminder } from '../../hooks/useReminder';
import styles from './frame-paner.module.scss';

type Props = {
  value: number;
  img: string;
  max: number;
  min?: number;
  onChange(position: number): void;
};

export function FramePaner({
  value = 0, img, min = 0, max, onChange,
}: Props) {
  const showAnimationRef = useRef(true);
  const { reminder } = useReminder({ interval: 5000, duration: 3000, repeat: 2 });

  function handleChange(e: ChangeEvent<HTMLInputElement>) {
    showAnimationRef.current = false;
    onChange(parseInt(e.target.value, 10) as number);
  }

  const hintClassnames = cn(styles.hint, { [styles.animated]: reminder && showAnimationRef.current });
  const sliderClassnames = cn(styles.panner, { [styles.animated]: reminder && showAnimationRef.current });
  const chevronClassnames = cn(styles.chevron, { [styles.animated]: reminder && showAnimationRef.current });

  return (
    <div className={styles.container}>
      <input
        className={sliderClassnames}
        style={{ backgroundImage: `url(${img})` }}
        onChange={handleChange}
        type="range"
        min={min}
        max={max}
        value={value}
      />
      <div className={hintClassnames}>
        <Icon name="code" className={chevronClassnames} size="sm" />
      </div>
    </div>
  );
}
