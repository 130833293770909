import {
  ReactElement, RefObject, useState, cloneElement, useRef,
} from 'react';
import { isUndefined } from 'lodash-es';
import { Spotlight } from '../spotlight/spotlight';
import { useSlowdownVideo } from '../../hooks/useStaticSlowdownVideo';

export type RectRef = RefObject<SVGRectElement>;

type Props = {
  timestamp: number;
  timeOffset?: number;
  videoRef: RefObject<HTMLVideoElement>;
  children: ReactElement;
  enabled?: boolean;
  onShow?(rectRef: RectRef): void;
  onHide?(rectRef: RectRef): void;
};

export function SpotlightManager({
  timestamp, videoRef, children, timeOffset, enabled = true, onShow, onHide,
}: Props) {
  const [spotlightVisible, setSpotlightVisible] = useState(false);
  const rectRef = useRef<SVGRectElement>(null);

  useSlowdownVideo({
    ref: videoRef,
    from: timestamp,
    timeOffset,
    enabled: children.type === Spotlight && !isUndefined(timeOffset) && enabled,
    onStart() {
      if (onShow) {
        onShow(rectRef);
      }
      setSpotlightVisible(true);
    },
    onEnd() {
      if (onHide) {
        onHide(rectRef);
      }
      setSpotlightVisible(false);
    },
  });

  if (children.type !== Spotlight) {
    return null;
  }

  return cloneElement(children, { visible: spotlightVisible, ref: rectRef });
}
