import { MouseEventHandler, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  useMenu,
  IconButton,
  Menu,
  MenuList,
  MenuListItem,
} from '@veo/web-design-system';
import { IPlayerClip } from 'src/common/model/interfaces/IPlayerClip';
import styles from './sort-menu.module.scss';

type Props = {
  item: IPlayerClip;
  onMoveTop(pc: IPlayerClip): void;
  onMoveBottom(pc: IPlayerClip): void;
  className?: string;
};

export function SortMenu({
  item, onMoveBottom, onMoveTop, className,
}: Props) {
  const [menuOpen, setMenuOpen] = useState(false);

  const { t } = useTranslation();

  const {
    setButton, setMenu, getButtonProps, getMenuProps,
  } = useMenu(
    menuOpen,
    setMenuOpen,
    'bottom',
  );

  function toggleMenu() {
    setMenuOpen((prev) => !prev);
  }

  const moveTop: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.preventDefault();
    e.stopPropagation();

    setMenuOpen(false);
    onMoveTop(item);
  };

  const moveBottom: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.preventDefault();
    e.stopPropagation();

    setMenuOpen(false);
    onMoveBottom(item);
  };

  return (
    <>
      <IconButton
        ref={setButton}
        variant="text"
        size="sm"
        icon="more-vertical"
        onClick={toggleMenu}
        className={className}
        {...getButtonProps()}
      />

      <Menu
        className={styles.container}
        ref={setMenu}
        {...getMenuProps()}
      >
        <MenuList>
          <MenuListItem
            title={t('profile_video.reorder.order_top_button_label')}
            iconStart="arrow-up"
            onClick={moveTop}
            size="sm"
          />
          <MenuListItem
            title={t('profile_video.reorder.order_bottom_button_label')}
            iconStart="arrow-down"
            onClick={moveBottom}
            size="sm"
          />
        </MenuList>
      </Menu>
    </>
  );
}
