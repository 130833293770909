import { useTranslation } from 'react-i18next';
import { Button } from '@veo/web-design-system';
import { useOidcAuth } from 'src/features/oidc';
import { useImage } from 'src/common/hooks/useImage';
import { LinkWrapper } from 'src/components/link/link';
import { APP_ROUTES } from 'src/common/constants/routes';
import { useAuthPlayer, PlayerProtected } from 'src/features/auth';
import { getProfileIdentifier } from 'src/common/utils/player-profile';
import { MEDIA_QUERY } from 'src/common/constants/media-query';
import { useMediaQuery } from 'src/common/hooks/useMediaQuery';
import emptyFallbackImage from 'src/styles/svg/empty-image.svg';
import styles from './user-banner.module.scss';

export function UserBanner() {
  const { t } = useTranslation();
  const { data: authUser } = useOidcAuth();
  const { data: authPlayer } = useAuthPlayer();

  const isMobileLayout = useMediaQuery(MEDIA_QUERY.MOBILE);

  const { imageSrc: userImageSrc } = useImage(authUser?.avatar ?? '', emptyFallbackImage);

  if (!authUser) {
    return null;
  }

  return (
    <div className={styles.container}>
      <img
        className={styles.image}
        src={userImageSrc}
        alt={`${authUser?.firstName} ${authUser.lastName}`}
      />

      <div className={styles.info}>
        <div className={styles.title}>
          <PlayerProtected>
            {
              authPlayer?.jerseyNumber && (
                <div className={styles['jersey-number']}>
                  {authPlayer.jerseyNumber}
                  <span className={styles['number-sign']}>#</span>
                </div>
              )
            }
          </PlayerProtected>

          <div className={styles['full-name']}>
            { authUser.firstName }
            {' '}
            { authUser.lastName }
          </div>
        </div>

        <PlayerProtected>
          <LinkWrapper link={`/${APP_ROUTES.playerProfile}/${getProfileIdentifier(authPlayer)}`}>
            <Button
              variant="outlined"
              label={t('home.player-banner.profile_button_label')}
              size={isMobileLayout ? 'md' : 'lg'}
              iconEnd="user"
            />
          </LinkWrapper>
        </PlayerProtected>
      </div>
    </div>
  );
}
