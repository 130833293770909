import { useEffect, useState, useRef } from 'react';

export const useImage = (src: string, fallbackSrc: string) => {
  const isMountedRef = useRef(false);
  const [imageSrc, setImageSrc] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    isMountedRef.current = true;

    const setImage = (nextImageSrc: string) => {
      setImageSrc(nextImageSrc);
      setIsLoading(false);
    };

    const checkImage = () => {
      const img = new Image();

      img.src = src;

      img.onload = () => {
        if (isMountedRef.current) {
          setImage(src);
        }
      };

      img.onerror = () => {
        if (isMountedRef.current) {
          setImage(fallbackSrc);
        }
      };
    };

    setIsLoading(true);
    checkImage();

    return () => {
      isMountedRef.current = false;
    };
  }, [src]);

  return { imageSrc, isLoading };
};
