import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import { Button, Text } from '@veo/web-design-system';
import { config } from 'src/config';
import styles from './not-found-profile.module.scss';

export function NotFoundProfile() {
  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        <title>Veo | Player Profile not found</title>
        <meta name="description" content="We could not find the Player Profile you were looking for" />
      </Helmet>
      <div className={styles.container}>
        <div className={styles.content}>
          <Text type="h2" size="h2" weight="semiBold">
            { t('player_profile.not_found.title') }
          </Text>
          <Text size="body">
            { t('player_profile.not_found.message') }
          </Text>
          <a href={config.ZOLA_URL}>
            <Button
              iconStart="external-link"
              label={t('player_profile.not_found.button_label')}
              variant="outlined"
            />
          </a>
        </div>
      </div>
    </>
  );
}
