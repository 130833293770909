import { uniqueId } from 'lodash-es';
import cn from 'classnames';
import { ValueOf } from 'src/common/model/types/helper.type';
import { Skeleton } from './skeleton';
import styles from './skeleton.module.scss';

export const textSizes = {
  h1: 'h1',
  h2: 'h2',
  h3: 'h3',
  h4: 'h4',
  h5: 'h5',
  h6: 'h6',
  body: 'body',
  caption: 'caption',
  overline: 'overline',
} as const;

export type TextSize = ValueOf<typeof textSizes>;

type Props = {
  size?: TextSize;
  words?: number;
  inline?: boolean;
  className?: string;
};

export function TextSkeleton({
  size = textSizes.body,
  words = 1,
  inline = false,
  className,
}: Props) {
  const wrapperClassnames = cn(
    styles['text-skeleton-wrapper'],
    { [styles['text-skeleton-wrapper__inline']]: inline },
  );

  const wordClassnames = cn(
    styles[`text-skeleton-${size}`],
    className,
  );

  const items = new Array(words)
    .fill(1)
    .map((v) => uniqueId(v));

  return (
    <div className={wrapperClassnames}>
      { items.map((w) => <Skeleton key={w} className={wordClassnames} />) }
    </div>
  );
}
