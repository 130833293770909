import { useEffect } from 'react';
import { config } from 'src/config';

export function useVersion() {
  useEffect(() => {
    if (window.console && config.STAGE !== 'prod' && config.STAGE !== 'test') {
      const {
        STAGE,
        APP_VERSION,
        APP_BRANCH,
        DEPLOYMENT_DATE,
      } = config;

      // eslint-disable-next-line no-console
      console.log(`Env: ${STAGE}(${APP_VERSION}) - Deployed on ${DEPLOYMENT_DATE} from ${APP_BRANCH} branch`);
    }
  }, []);
}
