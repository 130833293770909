import convert from 'convert-units';
import { isNull } from 'lodash-es';
import i18n from '../services/i18next';
import { formatFloat } from './dev-utils';
import { MeasurementSystems } from '../model/enums/MeasurementSystem';

// metric cm to imperial ft/in
export function convertHeightToImperial(value?: number | null) {
  if (isNull(value) || Number.isNaN(value)) {
    return null;
  }

  const inches = convert(value).from('cm').to('in');
  const feet = Math.floor(inches / 12);
  const restInches = inches - (feet * 12);

  return [feet, restInches];
}

// imperial ft/in to metric cm
export function convertHeightToMetric(values?: number[] | null) {
  if (!values || !values.length) {
    return null;
  }

  const [feet, inches] = values;

  const feetToMeters = convert(feet).from('ft-us').to('cm');
  const inchesToMeters = convert(inches).from('in').to('cm');

  return feetToMeters + inchesToMeters;
}

// metric kg to imperial lbs
export function convertWeightToImperial(value?: number | null) {
  if (isNull(value) || Number.isNaN(value)) {
    return null;
  }

  return convert(value).from('kg').to('lb');
}

// imperial lbs to metric kg
export function convertWeightToMetric(value?: number | null) {
  if (!value) {
    return null;
  }

  return convert(value).from('lb').to('kg');
}

export function stringifyUnitNumber(value: number, precision = 0) {
  return `${formatFloat(value, precision)}`;
}

export function parseStringUnitNumber(value: string) {
  if (value === '') {
    return null;
  }

  return parseFloat(value);
}

// format 6'11''
export function formatImperialHeight(values: number[] | null) {
  if (!values || !values[0]) {
    return null;
  }

  const [feet, inches] = values;

  return `${feet}'${stringifyUnitNumber(inches)}"`;
}

// format 175 cm
export function formatMetricHeight(value: number | null) {
  if (!value) {
    return null;
  }

  return `${stringifyUnitNumber(value)} cm`;
}

// format 100 lbs
export function formatImperialWeight(value: number | null) {
  if (!value) {
    return null;
  }

  return `${stringifyUnitNumber(value)} lbs`;
}

// format 100 kg
export function formatMetriclWeight(value: number | null) {
  if (!value) {
    return null;
  }

  return `${stringifyUnitNumber(value)} kg`;
}

export function getDropdownMeasurementSystemOptions() {
  return [
    {
      label: i18n.t('measurement_system.metric'),
      value: MeasurementSystems.Metric,
    },
    {
      label: i18n.t('measurement_system.imperial'),
      value: MeasurementSystems.Imperial,
    },
  ];
}
