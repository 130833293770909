import { isString } from 'lodash-es';
import { getUserInitials } from './user';

export function getInitialsFromName(name?: string | null) {
  if (!isString(name)) {
    return 'FL';
  }

  const [firstName, lastName] = name.split(' ');

  return getUserInitials(firstName, lastName);
}
