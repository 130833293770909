import { useState, useCallback } from 'react';
import { ConfirmationProps } from '../confirmation';

type Props = {
  required?: boolean;
  action: () => void | Promise<void>;
};

type ReturnProps = Pick<ConfirmationProps, 'onConfirm' | 'onCancel' | 'open'> & {
  triggerAction: () => void | Promise<void>;
};

export function useConfirmation({ required = true, action }: Props): ReturnProps {
  const [showConfirmation, setShowConfirmation] = useState(false);

  const executeAction = useCallback(async () => {
    const res = await action();

    return res;
  }, [action]);

  const handleTriggerAction = useCallback(() => {
    if (required) {
      setShowConfirmation(true);
    } else {
      executeAction();
    }
  }, [required, executeAction]);

  const handleConfirm = useCallback(() => {
    executeAction();
    setShowConfirmation(false);
  }, [executeAction]);

  const handleCancel = useCallback(() => {
    setShowConfirmation(false);
  }, []);

  return {
    open: showConfirmation,
    triggerAction: handleTriggerAction,
    onConfirm: handleConfirm,
    onCancel: handleCancel,
  };
}
