import { useEffect, useMemo, useState } from 'react';
import { flatten, uniqueId } from 'lodash-es';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { Button } from '@veo/web-design-system';
import { FilterParams } from 'src/common/hooks/useQueryParams';
import { useAuthPlayerZolaClips } from 'src/common/hooks/zola-clips/useAuthPlayerZolaClips';
import { IMatch } from 'src/common/model/interfaces/IMatch';
import { useTrackEventOnMount, ANALYTIC_EVENTS } from 'src/features/analytics';
import { MEDIA_QUERY } from 'src/common/constants/media-query';
import { useMediaQuery } from 'src/common/hooks/useMediaQuery';
import { VeoPlayer } from 'src/components/veo-player/veo-player';
import { Nullable } from 'src/common/model/types/helper.type';
import { Paper } from 'src/components/paper/paper';
import { useAuthPlayer } from 'src/features/auth';
import { convertZolaClipToPlayerClip } from 'src/common/utils/player-clips';
import { useIntersectionObserver } from 'src/common/hooks/useIntersectionObserver';
import { IZolaClip } from 'src/common/model/interfaces/IZolaClip';
import { IPlayerClip } from 'src/common/model/interfaces/IPlayerClip';
import { Skeleton } from 'src/components/skeletons/skeleton';
import { ListHeader } from './list-header';
import { ClipItem } from './clip-item';
import { getAppliedFilterNames } from '../../utils/filter';
import { EmptyClipsState } from './empty-clips-state';
import styles from './clip-picker.module.scss';

type Props = {
  filter: FilterParams;
  match: Nullable<IMatch>;
  className?: string;
  onGoBack: VoidFunction;
  onPlayerClipChange(pc: IPlayerClip): void;
};

export function ClipPicker({
  filter,
  match,
  className,
  onGoBack,
  onPlayerClipChange,
}: Props) {
  const [activeClip, setActiveClip] = useState<IZolaClip>();

  useTrackEventOnMount({
    name: ANALYTIC_EVENTS.ADD_CLIP_FIND_VIEW,
    properties: { filter: getAppliedFilterNames(filter) },
  });

  const isMobileLayout = useMediaQuery(MEDIA_QUERY.MOBILE);

  const { t } = useTranslation();

  const { data: authPlayer } = useAuthPlayer();

  const {
    data: hlInfiniteQuery,
    isLoading: isLoadingHighlights,
    isFetched: isClipsFetched,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
  } = useAuthPlayerZolaClips({
    filter, pageSize: 10, page: 1, search: null, sort: null,
  });

  const zolaClips: IZolaClip[] = useMemo(() => {
    if (hlInfiniteQuery) {
      const allPagesItems = hlInfiniteQuery.pages.map(({ items }) => items);
      const allHighlights = flatten(allPagesItems);

      return allHighlights;
    }

    return [];
  }, [hlInfiniteQuery]);

  const { ref: lastItemRef } = useIntersectionObserver<HTMLDivElement>({
    enabled: !isFetchingNextPage && !isLoadingHighlights && hasNextPage,
    onIntersect: fetchNextPage,
  });

  useEffect(() => {
    if (!activeClip && zolaClips.length) {
      const newActiveClip = zolaClips.find((zc) => !zc.addedToPlayerClips);
      setActiveClip(newActiveClip);
    }
  }, [zolaClips, activeClip]);

  function handleClipChange(clip: IZolaClip) {
    setActiveClip(clip);
  }

  function handleCreatePlayerClip() {
    if (!activeClip || !authPlayer) {
      return;
    }

    const payload = convertZolaClipToPlayerClip(activeClip, authPlayer.id);

    onPlayerClipChange(payload);
  }

  return (
    <div className={cn(styles.container, className)}>
      <div className={styles.video}>
        <VeoPlayer
          loop
          src={activeClip?.url}
          poster={activeClip?.thumbnail}
        />
      </div>
      <Paper
        elevation={isMobileLayout ? 1 : 2}
        className={styles['list-wrapper']}
      >
        <ListHeader
          match={match}
          filter={filter}
        />
        <div className={styles['infinite-list']}>
          {
            zolaClips.map((zc, index) => {
              const isActive = zc.id === activeClip?.id;
              const isLast = index === (zolaClips.length - 1);

              return (
                <ClipItem
                  ref={isLast ? lastItemRef : undefined}
                  onClick={handleClipChange}
                  active={isActive}
                  key={zc.id}
                  clip={zc}
                />
              );
            })
          }
          {
            isFetchingNextPage && (
              new Array(5).fill(0).map(() => (
                <Skeleton
                  key={uniqueId('skeleton')}
                  width="100%"
                  height="6.75rem"
                  className={styles.skeleton}
                />
              ))
            )
          }
          {
            isClipsFetched && !zolaClips.length && (
              <EmptyClipsState match={match} />
            )
          }
        </div>
      </Paper>
      <div className={styles.actions}>
        <Button
          label={t('add_player_clips.stepper.prev_button_label')}
          variant="outlined"
          iconStart="arrow-left"
          onClick={onGoBack}
        />
        <Button
          label={t('add_player_clips.stepper.next_button_label')}
          variant="secondary"
          iconEnd="arrow-right"
          disabled={!activeClip || activeClip.addedToPlayerClips}
          onClick={handleCreatePlayerClip}
        />
      </div>
    </div>
  );
}
