import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Text, Button } from '@veo/web-design-system';
import { IPlayer } from 'src/common/model/interfaces/IPlayer';
import { PlayerProtected, useAuthPlayer } from 'src/features/auth';
import { useUserSettings } from 'src/common/hooks/user/useUserSettings';
import { getIsPossibleUSPlayer } from 'src/common/utils/player-profile';
import { MeasurementSystems } from 'src/common/model/enums/MeasurementSystem';
import {
  convertHeightToImperial,
  convertWeightToImperial,
  formatImperialHeight,
  formatMetricHeight,
  formatImperialWeight,
  formatMetriclWeight,
} from 'src/common/utils/measurement-units';
import { calculateAge } from 'src/common/utils/luxon-utils';
import { AttributeItem } from './attribute-item';
import { KeyInformationModal } from './key-information-modal';
import styles from './key-information.module.scss';

type Props = {
  player: IPlayer;
};

export function KeyInformation({ player }: Props) {
  const [editMode, setEditMode] = useState(false);

  const { data: authPlayer } = useAuthPlayer();
  const { data: userSettings } = useUserSettings();

  const { t } = useTranslation();

  function handleShowForm() {
    if (authPlayer) {
      setEditMode(true);
    }
  }

  function getPlayerHeight() {
    if (!player.height) {
      return '-';
    }

    const imperialHeight = convertHeightToImperial(player.height);
    const formattedImperialHeight = formatImperialHeight(imperialHeight);
    const formattedMetricHeight = formatMetricHeight(player.height);

    if (userSettings?.measurementSystem && userSettings.measurementSystem === MeasurementSystems.Imperial) {
      return formattedImperialHeight;
    }

    if (userSettings?.measurementSystem && userSettings.measurementSystem === MeasurementSystems.Metric) {
      return formattedMetricHeight;
    }

    const isPossibleUs = getIsPossibleUSPlayer();

    const primary = isPossibleUs ? formattedImperialHeight : formattedMetricHeight;
    const secondary = isPossibleUs ? `(${formattedMetricHeight})` : `(${formattedImperialHeight})`;

    return (
      <div className={styles['generic-unit']}>
        <Text
          size="h2"
          weight="medium"
        >
          {primary}
        </Text>
        <Text
          size="h2"
          weight="medium"
          className={styles.secondary}
        >
          {secondary}
        </Text>
      </div>
    );
  }

  function getPlayerWeight() {
    if (!player?.weight) {
      return '-';
    }

    const imperialWeight = convertWeightToImperial(player.weight);
    const formattedImperialWeight = formatImperialWeight(imperialWeight);
    const formattedMetricWeight = formatMetriclWeight(player.weight);

    if (userSettings?.measurementSystem && userSettings.measurementSystem === MeasurementSystems.Imperial) {
      return formattedImperialWeight;
    }

    if (userSettings?.measurementSystem && userSettings.measurementSystem === MeasurementSystems.Metric) {
      return formattedMetricWeight;
    }

    const isPossibleUs = getIsPossibleUSPlayer();

    const primary = isPossibleUs ? formattedImperialWeight : formattedMetricWeight;
    const secondary = isPossibleUs ? `(${formattedMetricWeight})` : `(${formattedImperialWeight})`;

    return (
      <div className={styles['generic-unit']}>
        <Text
          size="h2"
          weight="medium"
        >
          {primary}
        </Text>
        <Text
          size="h2"
          weight="medium"
          className={styles.secondary}
        >
          {secondary}
        </Text>
      </div>
    );
  }

  function getPlayerAge() {
    if (!player?.birthday) {
      return '-';
    }

    return calculateAge(player.birthday);
  }

  return (
    <>
      <div className={styles.container}>
        <Text
          size="h1"
          weight="bold"
          type="h2"
          className={styles.title}
        >
          {t('player_key_information.heading')}
        </Text>
        <div className={styles.attributes}>
          <AttributeItem title={t('player_key_information.strong_foot_title')}>
            {t(`entity.strong_foot.${player?.strongFoot}`, { defaultValue: '-' })}
          </AttributeItem>
          <AttributeItem title={t('player_key_information.height_title', { unit: null })}>
            {getPlayerHeight()}
          </AttributeItem>
          <AttributeItem title={t('player_key_information.weight_title', { unit: null })}>
            {getPlayerWeight()}
          </AttributeItem>
          <AttributeItem title={t('player_key_information.age_title')}>
            {getPlayerAge()}
          </AttributeItem>
          <AttributeItem title={t('player_key_information.graduation_year_title')}>
            {player?.graduationYear ?? '-'}
          </AttributeItem>
          <AttributeItem title={t('player_key_information.gpa_title')}>
            {player?.gpa ?? '-'}
          </AttributeItem>
        </div>
        <PlayerProtected predicate={(ap) => ap.id === player.id}>
          <Button
            className={styles['edit-button']}
            flexible={false}
            size="md"
            iconEnd="edit-2"
            label={t('player_key_information.edit_button_label')}
            variant="outlined"
            onClick={handleShowForm}
          />
        </PlayerProtected>
      </div>
      <PlayerProtected predicate={(ap) => ap.id === player.id}>
        <KeyInformationModal
          open={editMode}
          player={player}
          onClose={(value) => setEditMode(value)}
        />
      </PlayerProtected>
    </>
  );
}
