import { ControlProps, components } from 'react-select';
import cn from 'classnames';
import styles from '../dropdown.module.scss';

export function Control<Option, IsMulti extends boolean>(props: ControlProps<Option, IsMulti>) {
  const {
    children, isFocused, isMulti, isDisabled, ...restProps
  } = props;

  const controlClassnames = cn(
    styles['dropdown-control'],
    {
      [styles['dropdown-control__focused']]: isFocused,
      [styles['dropdown-control__disabled']]: isDisabled,
      [styles['dropdown-control__multi']]: isMulti,
    },
  );

  return (
    <components.Control
      {...restProps}
      isMulti={isMulti}
      isDisabled={isDisabled}
      isFocused={isFocused}
      className={controlClassnames}
    >
      { children }
    </components.Control>
  );
}
