import { useState, useLayoutEffect, useRef } from 'react';

export function useResizeObserver<T extends HTMLElement>(): [React.RefObject<T>, DOMRectReadOnly | undefined] {
  const ref = useRef<T>(null);
  const [bounds, setBounds] = useState<DOMRectReadOnly>();

  useLayoutEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      const [targetElement] = entries;
      setBounds(targetElement.contentRect);
    });

    if (ref.current) {
      resizeObserver.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        resizeObserver.unobserve(ref.current);
      }
    };
  }, [ref.current]);

  return [ref, bounds];
}
