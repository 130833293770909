import cn from 'classnames';
import styles from './ability.module.scss';

type Props = {
  color: string;
  active?: boolean;
  className?: string;
};

export function ColorBar({ color, className, active = true }: Props) {
  const colorBarClassnames = cn(
    styles['color-bar'],
    { [styles.default]: !active },
    className,
  );

  return (
    <div className={colorBarClassnames} style={{ backgroundColor: color }} />
  );
}
