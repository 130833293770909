import { ValueOf } from '../types/helper.type';

export enum Position {
  Goalkeeper = 'Goalkeeper',
  RightFullBack = 'RightFullBack',
  LeftFullBack = 'LeftFullBack',
  CentreBack = 'CentreBack',
  RightWingBack = 'RightWingBack',
  LeftWingBack = 'LeftWingBack',
  DefensiveMidfielder = 'DefensiveMidfielder',
  RightMidfielder = 'RightMidfielder',
  LeftMidfielder = 'LeftMidfielder',
  CentralMidfielder = 'CentralMidfielder',
  RightWing = 'RightWing',
  LeftWing = 'LeftWing',
  AttackingMidfielder = 'AttackingMidfielder',
  Striker = 'Striker',
}

export const PlayStyles = {
  CompleteKeeper: 'CompleteKeeper',
  ShotStopper: 'ShotStopper',
  SweeperKeeper: 'SweeperKeeper',
  Goalplayer: 'Goalplayer',
  Stopper: 'Stopper',
  Distributor: 'Distributor',
  AerialDominator: 'AerialDominator',
  Sweeper: 'Sweeper',
  BalancedFullBack: 'BalancedFullBack',
  AttackingFullBack: 'AttackingFullBack',
  DefensiveFullBack: 'DefensiveFullBack',
  InvertedFullBack: 'InvertedFullBack',
  AttackingWingBack: 'AttackingWingBack',
  DefensiveWingBack: 'DefensiveWingBack',
  WingBackPlaymaker: 'WingBackPlaymaker',
  HybridWingBack: 'HybridWingBack',
  HoldingMidfielder: 'HoldingMidfielder',
  DeepLayingPlaymaker: 'DeepLayingPlaymaker',
  Disruptor: 'Disruptor',
  Organizer: 'Organizer',
  BoxToBox: 'BoxToBox',
  Playmaker: 'Playmaker',
  HalfspaceOperator: 'HalfspaceOperator',
  TraditionalWinger: 'TraditionalWinger',
  SpeedyWinger: 'SpeedyWinger',
  WidePlaymaker: 'WidePlaymaker',
  DefensiveWinger: 'DefensiveWinger',
  ClassicNumber10: 'ClassicNumber10',
  ShadowStriker: 'ShadowStriker',
  False9: 'False9',
  CuttingInsideWinger: 'CuttingInsideWinger',
  HighPressingWinger: 'HighPressingWinger',
  CompleteForward: 'CompleteForward',
  TargetStriker: 'TargetStriker',
  Poacher: 'Poacher',
} as const;

export type PlayStyle = ValueOf<typeof PlayStyles>;

export const PlayStyleMap: Record<Position, PlayStyle[]> = {
  [Position.Goalkeeper]: [
    PlayStyles.CompleteKeeper,
    PlayStyles.ShotStopper,
    PlayStyles.SweeperKeeper,
    PlayStyles.Goalplayer,
  ],
  [Position.CentreBack]: [
    PlayStyles.Stopper,
    PlayStyles.Distributor,
    PlayStyles.AerialDominator,
    PlayStyles.Sweeper,
  ],
  [Position.RightFullBack]: [
    PlayStyles.BalancedFullBack,
    PlayStyles.AttackingFullBack,
    PlayStyles.DefensiveFullBack,
    PlayStyles.InvertedFullBack,
  ],
  [Position.LeftFullBack]: [
    PlayStyles.BalancedFullBack,
    PlayStyles.AttackingFullBack,
    PlayStyles.DefensiveFullBack,
    PlayStyles.InvertedFullBack,
  ],
  [Position.RightWingBack]: [
    PlayStyles.AttackingWingBack,
    PlayStyles.DefensiveWingBack,
    PlayStyles.WingBackPlaymaker,
    PlayStyles.HybridWingBack,
  ],
  [Position.LeftWingBack]: [
    PlayStyles.AttackingWingBack,
    PlayStyles.DefensiveWingBack,
    PlayStyles.WingBackPlaymaker,
    PlayStyles.HybridWingBack,
  ],
  [Position.DefensiveMidfielder]: [
    PlayStyles.HoldingMidfielder,
    PlayStyles.DeepLayingPlaymaker,
    PlayStyles.Disruptor,
    PlayStyles.Organizer,
  ],
  [Position.CentralMidfielder]: [
    PlayStyles.BoxToBox,
    PlayStyles.Playmaker,
    PlayStyles.Disruptor,
    PlayStyles.HalfspaceOperator,
  ],
  [Position.RightMidfielder]: [
    PlayStyles.TraditionalWinger,
    PlayStyles.SpeedyWinger,
    PlayStyles.WidePlaymaker,
    PlayStyles.DefensiveWinger,
  ],
  [Position.LeftMidfielder]: [
    PlayStyles.TraditionalWinger,
    PlayStyles.SpeedyWinger,
    PlayStyles.WidePlaymaker,
    PlayStyles.DefensiveWinger,
  ],
  [Position.AttackingMidfielder]: [
    PlayStyles.Playmaker,
    PlayStyles.ClassicNumber10,
    PlayStyles.ShadowStriker,
    PlayStyles.False9,
  ],
  [Position.RightWing]: [
    PlayStyles.TraditionalWinger,
    PlayStyles.CuttingInsideWinger,
    PlayStyles.SpeedyWinger,
    PlayStyles.HighPressingWinger,
  ],
  [Position.LeftWing]: [
    PlayStyles.TraditionalWinger,
    PlayStyles.CuttingInsideWinger,
    PlayStyles.SpeedyWinger,
    PlayStyles.HighPressingWinger,
  ],
  [Position.Striker]: [
    PlayStyles.CompleteForward,
    PlayStyles.TargetStriker,
    PlayStyles.Poacher,
    PlayStyles.False9,
  ],
};

export const PlaystylePlaceholder = 'Placeholder';
