import { ValueOf } from 'src/common/model/types/helper.type';

export type Step = {
  label: string;
};

export const StepperSizes = {
  sm: 'sm',
  md: 'md',
  lg: 'lg',
} as const;

export type StepperSize = ValueOf<typeof StepperSizes>;
