import { useMenu } from '@veo/web-design-system';
import { useState } from 'react';
import { IClub } from 'src/common/model/interfaces/IClub';
import { ClubhouseSelectorMenu } from './menu';
import { ClubhouseSelectorItem } from './item';

type Props = {
  options?: IClub[];
  value: IClub;
};

export function ClubhouseSelector({ options = [], value }: Props) {
  const [open, setOpen] = useState(false);
  const {
    setMenu, setButton, getMenuProps, getButtonProps,
  } = useMenu(
    open,
    setOpen,
    'bottom',
  );

  const close = () => setOpen(false);

  return (
    <div>
      <ClubhouseSelectorItem
        ref={setButton}
        open={open}
        clubhouse={value}
        {...getButtonProps()}
      />

      <ClubhouseSelectorMenu
        ref={setMenu}
        clubhouses={options}
        selectedClubhouse={value}
        onChange={close}
        {...getMenuProps()}
      />
    </div>
  );
}
