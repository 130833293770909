import { useState } from 'react';
import { useMenu } from '@veo/web-design-system';
import { ITeam } from 'src/common/model/interfaces/ITeam';
import { TeamSelectorMenu } from './menu';
import { TeamSelectorItem } from './item';
import styles from './team-selector.module.scss';

type Props = {
  options?: ITeam[];
  value: ITeam;
  className?: string;
  onSelectedTeam(selectedTeam: ITeam): void;
};

export function TeamSelector({ options = [], value, onSelectedTeam }: Props) {
  const [open, setOpen] = useState(false);
  const {
    setMenu, setButton, getMenuProps, getButtonProps,
  } = useMenu(
    open,
    setOpen,
    'bottom',
  );

  const close = () => setOpen(false);
  const onChange = (selectedTeam: ITeam) => {
    close();
    onSelectedTeam(selectedTeam);
  };

  return (
    <div className={styles.container}>
      <TeamSelectorItem
        ref={setButton}
        open={open}
        team={value}
        {...getButtonProps()}
      />

      <TeamSelectorMenu
        ref={setMenu}
        teams={options}
        selectedTeam={value}
        onChange={onChange}
        {...getMenuProps()}
      />
    </div>
  );
}
