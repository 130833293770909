import { IconName } from '@veo/web-design-system';
import { ValueOf } from 'src/common/model/types/helper.type';

export const SocialMedias = {
  instagram: 'instagram',
  facebook: 'facebook',
  twitter: 'twitter',
  tiktok: 'tiktok',
} as const;

export type SocialMedia = ValueOf<typeof SocialMedias>;

export const SOCIAL_MEDIA_BASE_URL = {
  [SocialMedias.instagram]: 'https://www.instagram.com/',
  [SocialMedias.facebook]: 'https://www.facebook.com/',
  [SocialMedias.twitter]: 'https://twitter.com/',
  [SocialMedias.tiktok]: 'https://www.tiktok.com/@',
} as const;

export const SOCIAL_MEDIA_ICONS: Record<SocialMedia, IconName> = {
  [SocialMedias.facebook]: 'facebook',
  [SocialMedias.instagram]: 'instagram',
  [SocialMedias.tiktok]: 'tik-tok',
  [SocialMedias.twitter]: 'x-twitter',
} as const;
