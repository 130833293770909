export const config = {
  STAGE: process.env.REACT_APP_STAGE as string,
  PLAYER_API_ENDPOINT: process.env.REACT_APP_PLAYER_API_ENDPOINT as string,
  ZOLA_URL: process.env.REACT_APP_ZOLA_URL as string,
  ZOLA_API_ENDPOINT: `${process.env.REACT_APP_ZOLA_URL}/api/app`,
  VEO_ANALYTICS_WRITE_KEY: process.env.REACT_APP_SEGMENT_WRITE_KEY as string,
  APP_VERSION: process.env.REACT_APP_VERSION,
  APP_BRANCH: process.env.REACT_APP_BRANCH,
  DEPLOYMENT_DATE: process.env.REACT_APP_DEPLOYMENT_DATE,
  OIDC_URL: process.env.REACT_APP_OIDC_AUTHORITY,
  OIDC_CLIENT_ID: process.env.REACT_APP_OIDC_CLIENT_ID,
};
