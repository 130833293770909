import {
  RefObject, useState, useEffect, useMemo, createContext, ReactNode, useContext,
} from 'react';
import { isString } from 'lodash-es';
import { createPortal } from 'react-dom';
import { ValueOf } from 'src/common/model/types/helper.type';
import styles from './debug-panel.module.scss';

export const debugParams = {
  // video current time
  currentTime: 'videoTime',
  // time tracked allowed to attach
  enabled: 'trackingEnabled',
  // telestration timestamp stored in db
  telestrationTimestamp: 'telestrationTimestamp',
  // video current time at which playback rate change should be initiated
  triggerTimestamp: 'triggerTimestamp',
  // video offset for specific video in current browser
  videoOffset: 'videoOffset',
  // video offset registered when video current time is
  videoOffsetRegisteredAt: 'videoOffsetRegisteredAt',
  // video time tracker registered when video current time is
  timeTrackerAttachedAt: 'timeTrackerAttachedAt',
  // video time when the telestartion is shown
  telestrationShowedAt: 'telestrationShowedAt',
} as const;

type DebugParams = ValueOf<typeof debugParams>;

export type DebugPayload = Record<Partial<DebugParams>, string>;

const initialValue = 'n/a';

const initialPayloadState: Record<DebugParams, string> = {
  [debugParams.currentTime]: initialValue,
  [debugParams.enabled]: initialValue,
  [debugParams.telestrationTimestamp]: initialValue,
  [debugParams.triggerTimestamp]: initialValue,
  [debugParams.videoOffset]: initialValue,
  [debugParams.timeTrackerAttachedAt]: initialValue,
  [debugParams.videoOffsetRegisteredAt]: initialValue,
  [debugParams.telestrationShowedAt]: initialValue,
};

type DebugContextType = {
  data: DebugPayload;
  registerValue(param: DebugParams, value: any): void;
  resetValues(newDefaults?: Partial<DebugPayload>): void;
};

const DebugContext = createContext<DebugContextType>({
  data: initialPayloadState,
  registerValue() {},
  resetValues() {},
});

type Props = {
  children: ReactNode;
  videoRef: RefObject<HTMLVideoElement>;
};

export function TelestrationDebugProvider({ children, videoRef }: Props) {
  const [data, setData] = useState<DebugPayload>(initialPayloadState);

  function registerValue(name: DebugParams, value: any) {
    setData((prev) => {
      if (prev[name] !== value) {
        const paramValue = isString(value) ? value : JSON.stringify(value);

        return { ...prev, [name]: paramValue };
      }

      return prev;
    });
  }

  function resetValues(newDefaults?: Partial<DebugPayload>) {
    const { videoTime, ...rest } = initialPayloadState;

    setData((prev) => ({ ...prev, ...rest, ...newDefaults }));
  }

  useEffect(() => {
    function trackTimeUpdate() {
      registerValue(debugParams.currentTime, videoRef.current?.currentTime ?? 99);
    }

    if (videoRef.current) {
      videoRef.current.addEventListener('timeupdate', trackTimeUpdate);
    }

    return () => {
      if (videoRef.current) {
        videoRef.current.removeEventListener('timeupdate', trackTimeUpdate);
      }
    };
  }, [videoRef.current]);

  const value = useMemo(() => ({ data, registerValue, resetValues }), [data]);

  return (
    <DebugContext.Provider value={value}>
      { children }
      {
        createPortal(
          <div className={styles.container}>
            {
              Object.keys(initialPayloadState).map((key) => (
                <div key={key}>{ `${key} - ${data[key as DebugParams]}`}</div>
              ))
            }
          </div>,
          document.body,
        )
      }
    </DebugContext.Provider>
  );
}

export function useTelestrationDebug() {
  return useContext(DebugContext);
}
