import { ValueContainerProps, components } from 'react-select';
import cn from 'classnames';
import styles from '../dropdown.module.scss';

export function ValueContainer<Option, IsMulti extends boolean>(props: ValueContainerProps<Option, IsMulti>) {
  const { children, isMulti, ...restProps } = props;

  const containerClassnames = cn(
    styles['value-container'],
    { [styles['value-container-multi']]: isMulti },
  );

  return (
    <components.ValueContainer
      {...restProps}
      isMulti={isMulti}
      className={containerClassnames}
    >
      { children }
    </components.ValueContainer>
  );
}
