import cn from 'classnames';
import { Position } from 'src/common/model/enums/Position';
import { Nullable } from 'src/common/model/types/helper.type';
import { getPositionGridPlacement } from '../../utils/positions';
import { PITCH_POSITIONS } from '../../constants/positions';
import { PositionItem } from './position-item';
import styles from './pitch-positions.module.scss';

const positions = Object.values(PITCH_POSITIONS);

type Props = {
  position?: Nullable<Position[]>;
  pitch?: Record<'width' | 'height', number>;
  className?: string;
  onChange?(p: Position): void;
};

export function PitchPositions({
  pitch,
  position: activePosition,
  className,
  onChange,
}: Props) {
  const containerClasses = cn(styles.container, className);

  return (
    <div className={containerClasses} style={{ height: `${pitch?.height ?? 0}px` }}>
      {positions.map(({ order, label, position }) => {
        const { row, column } = getPositionGridPlacement(order);

        return (
          <PositionItem
            key={position}
            active={activePosition?.includes(position)}
            activeOrder={activePosition?.indexOf(position)}
            position={position}
            label={label}
            row={row}
            column={column}
            onSelect={onChange}
          />
        );
      })}
    </div>
  );
}
