/* eslint-disable no-param-reassign */
import { RefObject } from 'react';

declare global {
  interface HTMLVideoElement {
    webkitEnterFullscreen?(): void;
    msRequestFullScreen?(): void;
    webkitExitFullscreen?(): void;
    mozRequestFullScreen?(): void;
    webkitRequestFullScreen?(): void;
    msRequestFullscreen?(): void;
    webkitResumePlayback?(): void;
    webkitDisplayingFullscreen?: boolean;
  }
  interface Document {
    fullScreen?: boolean;
    webkitIsFullScreen?: boolean;
    mozFullScreen?: boolean;
    msFullscreenElement?: boolean;
    webkitFullscreenElement?: boolean;
  }
}

type VideoRef = RefObject<HTMLVideoElement>;

const isFunction = (fnLike: unknown): fnLike is () => unknown => typeof fnLike === 'function';

export const playVideo = (ref: VideoRef) => {
  if (!ref.current) {
    return;
  }

  if (ref.current.paused || ref.current.ended) {
    ref.current.play();
  }
};

export const pauseVideo = (ref: VideoRef) => {
  if (ref.current && !ref.current.paused) {
    ref.current.pause();
  }
};

export const seekVideo = async (ref: VideoRef, timestamp: number) => new Promise(
  (resolve, reject) => {
    if (ref.current) {
      const handleSeeked = () => {
        ref.current!.removeEventListener('seeked', handleSeeked);
        resolve(timestamp);
      };

      ref.current.addEventListener('seeked', handleSeeked);
      ref.current.currentTime = timestamp;
    } else {
      reject(new Error('Video is not defined'));
    }
  },
);

export const startVideo = (ref: VideoRef) => {
  if (!ref.current) {
    return;
  }

  if (ref.current.paused || ref.current.ended) {
    seekVideo(ref, 0);
    ref.current.play();
  }
};

export const restartVideo = (ref: VideoRef) => {
  if (!ref.current) {
    return;
  }

  if (!ref.current.paused) {
    ref.current.pause();
  }

  startVideo(ref);
};

export const stopVideo = (ref: VideoRef) => {
  if (!ref.current) {
    return;
  }

  ref.current.pause();
  ref.current.currentTime = 0;
};

export const togglePlayVideo = (ref: VideoRef) => {
  if (!ref.current) {
    return;
  }

  if (!ref.current.paused) {
    pauseVideo(ref);
  } else {
    playVideo(ref);
  }
};

export const getIsFullscreen = () => !!(document.fullScreen
  || document.webkitIsFullScreen
  || document.mozFullScreen
  || document.msFullscreenElement
  || document.fullscreenElement
);

export const enterFullscreen = (ref: VideoRef) => {
  if (!ref.current) {
    return;
  }
  const video = ref.current;

  if (isFunction(video.requestFullscreen)) {
    video.requestFullscreen();
  } else if (isFunction(video.mozRequestFullScreen)) {
    video.mozRequestFullScreen();
  } else if (isFunction(video.webkitRequestFullScreen)) {
    video.webkitRequestFullScreen();
  } else if (isFunction(video.msRequestFullscreen)) {
    video.msRequestFullscreen();
  } else if (isFunction(video.webkitEnterFullscreen)) {
    video.webkitEnterFullscreen();
  }
};
