import { ValueOf } from '../model/types/helper.type';

export const HTTP_STATUS_CODES = {
  OK: 200,
  CREATED: 201,
  NO_CONTENT: 204,
  BAD_REQUEST: 400,
  UNATHORIZED: 401,
  FORBIDDEN: 403,
  NOT_FOUND: 404,
  SERVER_ERROR: 500,
} as const;

export type HttpStatusCodes = ValueOf<typeof HTTP_STATUS_CODES>;
