/* eslint-disable no-param-reassign */
import { MutableRefObject } from 'react';

export type RefType<T> = ((instance: T | null) => void) | MutableRefObject<T | null> | null;

export function mergeRefs<T>(refs: Array<RefType<T>>) {
  return (instance: T) => {
    refs.forEach((ref) => {
      if (typeof ref === 'function') {
        ref(instance);
      } else if (ref) {
        ref.current = instance;
      }
    });
  };
}
