import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ErrorPage } from '@veo/web-design-system';
import { usePageViewEvent } from 'src/features/analytics/hooks/usePageViewEvent';
import { PAGE_VIEW_EVENTS } from 'src/features/analytics/constants/segment-events';

export function NotFound() {
  const navigate = useNavigate();
  const { t } = useTranslation();

  usePageViewEvent(PAGE_VIEW_EVENTS.NOT_FOUND_VISITED);

  return (
    <ErrorPage
      illustration="users"
      title={t('not_found_page.title')}
      description={t('not_found_page.description')}
      onButtonClick={() => navigate('/')}
      buttonText={t('not_found_page.action_label') as string}
      buttonIconStart="arrow-left"
    />
  );
}
