import { IClub } from '../model/interfaces/IClub';
import { playerSvc } from '../services/player-svc';

const ZOLA_PREFIX = '/zola';

export async function getUserClubs(): Promise<IClub[]> {
  const queryParams = '?filter=own&fields=crest&fields=slug&fields=name&fields=url&fields=is_fosbury&page_size=80';

  const url = `${ZOLA_PREFIX}/clubs${queryParams}`;

  const res = await playerSvc.request({ method: 'GET', url });

  return res as IClub[];
}
