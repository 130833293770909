import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { Text, IconButton } from '@veo/web-design-system';
import { LinkWrapper } from 'src/components/link/link';
import { FilterParams } from 'src/common/hooks/useQueryParams';
import { IMatch } from 'src/common/model/interfaces/IMatch';
import { Nullable } from 'src/common/model/types/helper.type';
import { config } from 'src/config';
import { getAppliedFilters } from '../../utils/filter';
import styles from './list-header.module.scss';

type Props = {
  match: Nullable<IMatch>;
  filter: FilterParams;
  className?: string;
};

export function ListHeader({ filter, match, className }: Props) {
  const { t } = useTranslation();

  const {
    isMatch,
    isBookmarks,
    isMentions,
    isCreator,
  } = getAppliedFilters(filter);

  function getTitle() {
    if (isMatch && !!match) {
      return match.title;
    }

    if (isBookmarks) {
      return t('add_player_clips.filters.bookmarked_filter_label');
    }

    if (isMentions) {
      return t('add_player_clips.filters.mentions_filter_label');
    }

    if (isCreator) {
      return t('add_player_clips.filters.created_by_me_filter_label');
    }

    return '';
  }

  return (
    <div className={cn(styles.container, className)}>
      <Text
        size="h4"
        type="h4"
        weight="semiBold"
        className={styles.title}
      >
        { getTitle() }
      </Text>
      {
        isMatch && !!match && (
          <LinkWrapper
            external
            link={`${config.ZOLA_URL}${match.url}`}
            target="_blank"
          >
            <IconButton
              icon="external-link"
              variant="text"
              size="sm"
            />
          </LinkWrapper>
        )
      }
    </div>
  );
}
