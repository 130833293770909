/* eslint-disable react/jsx-props-no-spreading */
import { ForwardedRef, forwardRef } from 'react';
import cn from 'classnames';
import styles from './paper.module.scss';

export type PaperProps = React.HTMLAttributes<HTMLDivElement> & {
  elevation?: 0 | 1 | 2 | 3 | 4 | 5 | 6;
};

function PaperCmp(
  {
    children,
    elevation = 1,
    className,
    ...restProps
  }: PaperProps,
  ref: ForwardedRef<HTMLDivElement>,
) {
  const paperClassNames = cn(
    styles.container,
    {
      [styles[`elevation-${elevation}`]]: true,
    },
    className,
  );

  return (
    <div ref={ref} className={paperClassNames} {...restProps}>
      { children }
    </div>
  );
}

export const Paper = forwardRef<HTMLDivElement, PaperProps>(PaperCmp);
