import { useRef } from 'react';
import cn from 'classnames';
import { Paper } from 'src/components/paper/paper';
import { MEDIA_QUERY } from 'src/common/constants/media-query';
import { AbilityCode } from 'src/common/model/enums/AbilityCode';
import { useMediaQuery } from 'src/common/hooks/useMediaQuery';
import { useAuthPlayer } from 'src/features/auth';
import { ITelestration } from 'src/common/model/interfaces/ITelestration';
import { IPlayerClip } from 'src/common/model/interfaces/IPlayerClip';
import { EditAbilities } from './edit-abilities/edit-abilities';
import { EditTelestration } from './edit-telestration/edit-telestration';
import { EditPlayerClipTelestration } from '../telestration';
import styles from './player-clip-details.module.scss';

type Props = {
  playerClip?: IPlayerClip;
  onChange(pc: IPlayerClip): void;
  className?: string;
};

export function PlayerClipDetails({ playerClip, className, onChange }: Props) {
  const telestrationActionsRef = useRef<HTMLDivElement>(null);

  const isMobileLayout = useMediaQuery(MEDIA_QUERY.MOBILE);

  const { data: authPlayer } = useAuthPlayer();

  function handleAbilityChange(ac: AbilityCode[]) {
    if (!playerClip) {
      return;
    }

    onChange({ ...playerClip, ability: ac });
  }

  function handleTelestrationChange(pct: ITelestration | null) {
    if (!playerClip) {
      return;
    }

    onChange({ ...playerClip, telestration: pct });
  }

  if (!playerClip) {
    return null;
  }

  const {
    telestration,
    thumbnail,
    title,
    ability,
    url,
  } = playerClip;

  const playerName = `${authPlayer?.jerseyNumber ?? ''} ${authPlayer?.firstName} ${authPlayer?.lastName}`;

  return (
    <div className={cn(styles.container, className)}>
      <div className={styles['video-wrapper']}>
        <EditPlayerClipTelestration
          telestration={telestration}
          video={{ thumbnail, url, title }}
          portalRef={telestrationActionsRef}
          onChange={handleTelestrationChange}
        />
      </div>
      <Paper
        elevation={isMobileLayout ? 1 : 2}
        className={styles.info}
      >
        <EditTelestration
          playerName={playerName}
          ref={telestrationActionsRef}
        />
        <EditAbilities
          assigned={ability ?? []}
          onChange={handleAbilityChange}
        />
      </Paper>
    </div>
  );
}
