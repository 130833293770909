import { ReactNode, useRef, forwardRef } from 'react';
import cn from 'classnames';
import styles from './telestration-canvas.module.scss';

export type TelestrationCanvasProps = {
  children: ReactNode;
  width: number;
  height: number;
  minX?: number;
  minY?: number;
  className?: string;
};

export const TelestrationCanvas = forwardRef<SVGSVGElement, TelestrationCanvasProps>(({
  width, height, children, minX = 0, minY = 0, className,
}: TelestrationCanvasProps, ref) => {
  const containerRef = useRef<HTMLDivElement>(null);

  if (!children) {
    return null;
  }

  return (
    <div
      ref={containerRef}
      className={cn(styles.container, className)}
    >
      <svg
        ref={ref}
        className={styles.canvas}
        viewBox={`${minX} ${minY} ${width} ${height}`}
        preserveAspectRatio="none"
      >
        { children }
      </svg>
    </div>
  );
});
