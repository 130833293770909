import { ReactNode, useState, useMemo } from 'react';
import {
  DndContext,
  DragOverlay,
  closestCenter,
  DragEndEvent,
  useSensor,
  useSensors,
  MouseSensor,
  TouchSensor,
  DragStartEvent,
} from '@dnd-kit/core';
import { SortableContext, arrayMove, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { restrictToVerticalAxis, restrictToFirstScrollableAncestor } from '@dnd-kit/modifiers';
import {
  BaseOrderableItem, OrderAction, OrderableDisabled, OrderableDndOptions,
} from './model/orderable';

type Props<T> = {
  children: ReactNode;
  items: T[];
  activationDelay?: number;
  renderDragOverlay?(item: T, options: OrderableDndOptions): ReactNode;
  onSorted(items: T[], meta: OrderAction<T>): void;
};

const defaultActivationConstraints = {
  delay: 100,
  tolerance: 5,
};

export function OrderableList<T extends BaseOrderableItem = BaseOrderableItem>({
  items,
  children,
  activationDelay = 100,
  renderDragOverlay,
  onSorted,
}: Props<T>) {
  const [activeItem, setActiveItem] = useState<T | null>(null);

  const constraints = useMemo(() => ({
    activationConstraint: {
      ...defaultActivationConstraints,
      delay: activationDelay,
    },
  }), []);

  const mouseSensor = useSensor(MouseSensor, constraints);
  const touchSensor = useSensor(TouchSensor, constraints);

  const sensors = useSensors(mouseSensor, touchSensor);

  function handleDragStart({ active }: DragStartEvent) {
    if (active) {
      setActiveItem(active.data.current as T);
    }
  }

  function handleDragEnd({ active, over }: DragEndEvent) {
    if (!over || !active) {
      return;
    }

    let sortedItems = [...items];

    if (active.id !== over.id) {
      const from = items.findIndex(({ id }) => id === active.id);
      const to = items.findIndex(({ id }) => id === over.id);

      sortedItems = arrayMove([...sortedItems], from, to);

      onSorted(
        sortedItems,
        {
          data: sortedItems[to],
          to,
          from,
        },
      );
    }
  }

  return (
    <DndContext
      collisionDetection={closestCenter}
      sensors={sensors}
      onDragEnd={handleDragEnd}
      onDragStart={handleDragStart}
      modifiers={[
        restrictToVerticalAxis,
        restrictToFirstScrollableAncestor,
      ]}
    >
      <SortableContext
        items={items}
        strategy={verticalListSortingStrategy}
      >
        { children }
      </SortableContext>
      <DragOverlay>
        {activeItem && renderDragOverlay ? renderDragOverlay(activeItem, { isDragging: true, isHovered: false }) : null}
      </DragOverlay>
    </DndContext>
  );
}

export { type OrderableDndOptions };
export { type OrderAction };
export { arrayMove };
export { type OrderableDisabled };
export { useOrderable } from './hooks/useOrderable';
