/* eslint-disable react/jsx-props-no-spreading */
import {
  ForwardedRef, forwardRef, useMemo, useRef,
} from 'react';
import cn from 'classnames';
import { CSSTransition } from 'react-transition-group';
import { ITelestrationItem } from 'src/common/model/interfaces/ITelestration';
import { SpotlightTitle } from './spotlight-title';
import { SPOTLIGHT_TEXTBOX_ASPECT_RATIO } from '../../constants/telestration';
import { getSpotlightBoundingBox } from '../../utils/parsed-telestration';
import { SpotlightCircle } from './spotlight-circle';
import styles from './spotlight.module.scss';

type Props = {
  boundingBox: ITelestrationItem;
  title?: string;
  visible?: boolean;
  sourceAspectRatio?: number;
  boundingBoxVisible?: boolean;
  onSpotlightClick?(id: string): void;
  onSpotlightOver?(id: string): void;
  onSpotlightLeave?(id: string): void;
  rectProps?: React.SVGAttributes<SVGRectElement>;
};

function SpotlightCmp({
  boundingBox,
  visible = false,
  boundingBoxVisible = false,
  title,
  sourceAspectRatio = 16 / 9,
  rectProps,
  onSpotlightOver,
  onSpotlightLeave,
  onSpotlightClick,
}: Props, ref: ForwardedRef<SVGRectElement>) {
  const circleRef = useRef(null);
  const textRef = useRef(null);

  const boxModel = useMemo(() => getSpotlightBoundingBox(boundingBox), [boundingBox]);

  const circleAttributes = useMemo(() => {
    const circleHeight = boxModel.width * sourceAspectRatio;
    const circleWidth = boxModel.width * 2;

    return {
      x: boxModel.x - (boxModel.width / 2),
      y: boxModel.y + boxModel.height - (circleHeight / 2),
      height: circleHeight,
      width: circleWidth,
    };
  }, [boundingBox]);

  const textAttributes = useMemo(() => {
    const textWidth = Math.max(boxModel.width * 3, 150);
    const textHeight = (textWidth / SPOTLIGHT_TEXTBOX_ASPECT_RATIO) * sourceAspectRatio;

    const textX = boxModel.x - (textWidth - boxModel.width) / 2;

    return {
      x: textX,
      y: boxModel.y - textHeight - 10,
      width: textWidth,
      height: textHeight,
    };
  }, [boundingBox]);

  function handleOver() {
    if (onSpotlightOver) {
      onSpotlightOver(boxModel.id);
    }
  }

  function handleLeave() {
    if (onSpotlightLeave) {
      onSpotlightLeave(boxModel.id);
    }
  }

  function handleClick() {
    if (onSpotlightClick) {
      onSpotlightClick(boxModel.id);
    }
  }

  if (!boundingBox) {
    return null;
  }

  const boundingBoxClassnames = cn(
    styles.box,
    { [styles['box-visible']]: boundingBoxVisible },
  );

  return (
    <>
      <CSSTransition
        classNames={{
          enter: styles['visible-enter'],
          enterActive: styles['visible-enter-active'],
          exit: styles['visible-exit'],
          exitActive: styles['visible-exit-active'],
        }}
        timeout={500}
        in={visible}
        nodeRef={textRef}
        mountOnEnter
        unmountOnExit
      >
        <SpotlightTitle ref={textRef} {...textAttributes} label={title} />
      </CSSTransition>
      <CSSTransition
        classNames={{
          enter: styles['visible-enter'],
          enterActive: styles['visible-enter-active'],
          exit: styles['visible-exit'],
          exitActive: styles['visible-exit-active'],
        }}
        timeout={500}
        in={visible}
        nodeRef={circleRef}
        mountOnEnter
        unmountOnExit
      >
        <SpotlightCircle ref={circleRef} {...circleAttributes} />
      </CSSTransition>
      <rect
        ref={ref}
        {...boxModel}
        className={boundingBoxClassnames}
        onClick={handleClick}
        onMouseEnter={handleOver}
        onTouchStart={handleOver}
        onMouseLeave={handleLeave}
        onTouchCancel={handleLeave}
        onTouchEnd={handleLeave}
        {...rectProps}
      />
    </>
  );
}

export const Spotlight = forwardRef<SVGRectElement, Props>(SpotlightCmp);
