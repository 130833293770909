import { Icon } from '@veo/web-design-system';
import { SeekBar } from './progress/seek-bar';
import { VeoPlayerRendererProps } from '../types/veo-player.type';
import { ControlButton } from './control-button/control-button';
import styles from './default-controls.module.scss';

export function DefaultControls(props: VeoPlayerRendererProps) {
  const {
    videoRef,
    isPlaying,
    videoControls,
    pauseVideo,
    playVideo,
    playNextVideo,
    requestFullscreen,
    playPreviousVideo,
    seekVideo,
  } = props;

  const togglePlay = () => {
    const handler = isPlaying ? pauseVideo : playVideo;

    handler();
  };

  const {
    play,
    previous,
    next,
    fullscreen,
  } = videoControls;

  const playPauseIcon = isPlaying ? 'pause' : 'play';

  return (
    <>
      <SeekBar
        videoRef={videoRef}
        onSeekVideo={seekVideo}
      />
      <div className={styles.container}>
        {
          play && (
            <ControlButton
              onClick={togglePlay}
            >
              <Icon name={playPauseIcon} size="xs" />
            </ControlButton>
          )
        }
        {
          previous && (
            <ControlButton
              onClick={playPreviousVideo}
            >
              <Icon size="xs" name="arrow-left" />
            </ControlButton>
          )
        }
        {
          next && (
            <ControlButton
              onClick={playNextVideo}
            >
              <Icon size="xs" name="arrow-right" />
            </ControlButton>
          )
        }
        {
          fullscreen && (
            <ControlButton
              className={styles.fullscreen}
              onClick={requestFullscreen}
            >
              <Icon size="xs" name="maximize" />
            </ControlButton>
          )
        }
      </div>
    </>
  );
}
