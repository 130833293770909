import { useFormContext, useController } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FormField, Input } from '@veo/web-design-system';
import { isNumberValidator } from 'src/common/utils/form-validators';
import { MeasurementSystems } from 'src/common/model/enums/MeasurementSystem';
import { MIN_WEIGHT, MAX_WEIGHT } from 'src/features/player-profile/constants/player';
import {
  MIN_LBS,
  MAX_LBS,
  isValidWeightValidator,
  isValidWeightLbsValidator,
} from 'src/features/player-profile/utils/key-information';
import { KeyInfoFormValues } from '../../../utils/key-information';
import styles from './key-information-form.module.scss';

export function WeightField() {
  const { t } = useTranslation();

  const { control, watch } = useFormContext<KeyInfoFormValues>();
  const measurementSystem = watch('measurementSystem');

  const isImperialSystem = measurementSystem === MeasurementSystems.Imperial;

  const { field: weightField, fieldState: weightFieldState } = useController<KeyInfoFormValues, 'weight'>({
    control,
    name: 'weight',
    disabled: !measurementSystem || isImperialSystem,
    rules: {
      validate: {
        isNumber: (v) => isNumberValidator(v),
        isInRange: (v) => isValidWeightValidator(v),
      },
    },
  });

  const { field: weightLbsField, fieldState: weightLbsFieldState } = useController<KeyInfoFormValues, 'weightLbs'>({
    control,
    name: 'weightLbs',
    disabled: !measurementSystem || !isImperialSystem,
    rules: {
      validate: {
        isNumber: (v) => isNumberValidator(v),
        isInRange: (v) => isValidWeightLbsValidator(v),
      },
    },
  });

  const {
    isTouched: isWeightTouched,
    error: weightError,
  } = weightFieldState;

  const {
    isTouched: isWeightLbsTouched,
    error: weightLbsError,
  } = weightLbsFieldState;

  const weightUnits = isImperialSystem ? 'lbs' : 'kg';

  if (isImperialSystem) {
    return (
      <FormField
        id="weightLbs"
        className={styles.field}
        label={t('player_key_information.weight_title', { unit: weightUnits })}
        hint={measurementSystem ? undefined : t('player_key_information.form.measurement_system_hint') as string}
        error={isWeightLbsTouched ? weightLbsError?.message : undefined}
      >
        <Input
          type="number"
          inputMode="numeric"
          min={MIN_LBS}
          max={MAX_LBS}
          {...weightLbsField}
        />
      </FormField>
    );
  }

  return (
    <FormField
      id="weight"
      className={styles.field}
      label={t('player_key_information.weight_title', { unit: weightUnits })}
      hint={measurementSystem ? undefined : t('player_key_information.form.measurement_system_hint') as string}
      error={isWeightTouched ? weightError?.message : undefined}
    >
      <Input
        type="number"
        inputMode="numeric"
        min={MIN_WEIGHT}
        max={MAX_WEIGHT}
        step={0.1}
        {...weightField}
      />
    </FormField>
  );
}
