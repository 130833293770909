import { QueryParams } from '../hooks/useQueryParams';
import { IMatch } from '../model/interfaces/IMatch';
import { IPaginatedResponse } from '../model/interfaces/IPaginatedResponse';
import { playerSvc } from '../services/player-svc';
import { getUrlQueryParams } from '../utils/query-params';

export async function getMatches(queryParams: Record<string, unknown> = {}) {
  const params = getUrlQueryParams(queryParams);

  const res = await playerSvc.request({ method: 'GET', url: '/matches', params });

  return res as IMatch[];
}

export async function getPaginatedMatches(queryParams: Partial<QueryParams>, signal?: any) {
  const params = getUrlQueryParams(queryParams);

  const res = await playerSvc.request({
    method: 'GET', url: '/matches/by/page', params, signal,
  });

  return res as IPaginatedResponse<IMatch>;
}
