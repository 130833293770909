import { ReactNode, useMemo } from 'react';
import { IOidcUser } from 'src/common/model/interfaces/IUser';
import { useOidcAuth } from '../../hooks/useOidcAuth';

type Props = {
  children: ReactNode;
  predicate?: (user: IOidcUser) => boolean;
};

export function OidcProtected({
  children,
  predicate = () => true,
}: Props) {
  const { data: user } = useOidcAuth();

  const isRestricted = useMemo(() => !user || !predicate(user), [user]);

  if (isRestricted) {
    return null;
  }

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{ children }</>;
}
