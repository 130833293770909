/* eslint-disable react/no-array-index-key */
import { useMemo, useState, Fragment } from 'react';
import cn from 'classnames';
import { flatten, last } from 'lodash-es';
import { useTranslation } from 'react-i18next';
import {
  Avatar, Button, Divider, Pagination, Text, IconButton,
} from '@veo/web-design-system';
import { TextSkeleton } from 'src/components/skeletons/text-skeleton';
import { ButtonSkeleton } from 'src/components/skeletons/button-skeleton';
import { ITeamMember } from 'src/common/model/interfaces/ITeamMember';
import { useMediaQuery } from 'src/common/hooks/useMediaQuery';
import { MEDIA_QUERY } from 'src/common/constants/media-query';
import { getInitialsFromName } from 'src/common/utils/team-membership';
import { useUserTeams } from 'src/common/hooks/user/useUserTeams';
import { ITeam } from 'src/common/model/interfaces/ITeam';
import { LinkWrapper } from 'src/components/link/link';
import { useUserTeamMembers } from '../../hooks/useUserTeamMembers';
import { TeamSelector } from '../team-selector';
import styles from './team-member-list.module.scss';

export function TeamMemberList() {
  const [currentTeam, setCurrentTeam] = useState<ITeam>();

  const { t } = useTranslation();

  const isMobileLayout = useMediaQuery(MEDIA_QUERY.MOBILE);
  const { data: teams } = useUserTeams({
    onSuccess(data) {
      setCurrentTeam(data[0]);
    },
  });

  const {
    data: membersInfiniteQuery,
    isLoading: isTeamMembersLoading,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
  } = useUserTeamMembers(currentTeam);

  const teamMembers: ITeamMember[] = useMemo(() => {
    if (membersInfiniteQuery) {
      const allPagesMembers = membersInfiniteQuery.pages.map(({ items }) => items);
      const allMembers = flatten(allPagesMembers);

      return allMembers;
    }

    return [] as ITeamMember[];
  }, [membersInfiniteQuery]);

  function getPositionAbbr(position: string) {
    return t(`entity.position.${position}`)
      .split(' ')
      .map((pos) => pos.charAt(0).toUpperCase())
      .join('');
  }

  if (!teams || !currentTeam) {
    return null;
  }

  return (
    <div className={styles.container}>
      <div className={styles.table}>
        <div className={cn(styles['team-selector'], styles['full-row'])}>
          <TeamSelector
            value={currentTeam!}
            onSelectedTeam={(selectedTeam: ITeam): void => { setCurrentTeam(selectedTeam); }}
            options={teams}
          />
        </div>
        <Text
          type="block"
          className={styles['header-cell']}
          size={isMobileLayout ? 'caption' : 'body'}
        >
          { t('home.member_list.player_column_title') }
        </Text>
        <Text
          type="block"
          className={cn(styles['header-cell'], styles['align-center'])}
          size={isMobileLayout ? 'caption' : 'body'}
        >
          { t('home.member_list.jersey_column_title') }
        </Text>
        <Text
          type="block"
          className={cn(styles['header-cell'], styles['align-center'])}
          size={isMobileLayout ? 'caption' : 'body'}
        >
          { t('home.member_list.position_column_title') }
        </Text>
        <Text
          type="block"
          className={styles['header-cell']}
          size={isMobileLayout ? 'caption' : 'body'}
        />
        {
          isTeamMembersLoading && (
            new Array(5).fill(0).map((_, index) => (
              <Fragment key={`loader_${index}`}>
                <div className={styles['content-cell']}>
                  <TextSkeleton words={2} />
                </div>
                <div className={cn(styles['content-cell'], styles['align-center'])}>
                  <TextSkeleton />
                </div>
                <div className={cn(styles['content-cell'], styles['align-center'])}>
                  <TextSkeleton />
                </div>
                <div className={styles['content-cell']}>
                  <ButtonSkeleton size="sm" />
                </div>
                <Divider className={styles['full-row']} />
              </Fragment>
            ))
          )
        }
        {
          teamMembers.map((member) => {
            const {
              avatar, email, name, id, jerseyNumber, position, additionalPositions,
            } = member;

            const initials = getInitialsFromName(name);

            const allPositions = position
              ? [position, ...additionalPositions ?? []]
              : [];

            const link = `/player/${id}`;

            return (
              <Fragment key={email}>
                <div className={styles['content-cell']}>
                  <Avatar
                    size="sm"
                    initials={initials}
                    image={avatar}
                  />
                  <Text
                    type="block"
                    className={styles['truncated-text']}
                    size={isMobileLayout ? 'overline' : 'body'}
                  >
                    {name ?? email}
                  </Text>
                </div>
                <Text className={cn(styles['content-cell'], styles['align-center'])}>
                  {jerseyNumber}
                </Text>
                <Text className={cn(styles['content-cell'], styles['align-center'])}>
                  {
                    allPositions.map(getPositionAbbr).join('/')
                  }
                </Text>
                <div className={styles['content-cell']}>
                  {
                    id && !isMobileLayout && (
                      <LinkWrapper link={link} target="_blank">
                        <Button
                          variant="outlined"
                          size="sm"
                          iconEnd="arrow-right"
                          label={t('home.member_list.view_profile_button_label')}
                        />
                      </LinkWrapper>
                    )
                  }
                  {
                    id && isMobileLayout && (
                      <LinkWrapper link={link} target="_blank">
                        <IconButton
                          variant="outlined"
                          size="sm"
                          icon="arrow-right"
                        />
                      </LinkWrapper>
                    )
                  }
                </div>
                <Divider className={styles['full-row']} />
              </Fragment>
            );
          })
        }
      </div>
      <Pagination
        size="md"
        buttonLabel={t('pagination.load_more_button') as string}
        count={teamMembers.length}
        disabled={!hasNextPage}
        totalCount={last(membersInfiniteQuery?.pages)?.meta?.totalCount ?? 0}
        loading={isFetchingNextPage}
        onClick={() => fetchNextPage()}
      />
    </div>
  );
}
