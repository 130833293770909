import { Route, Routes } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Spinner } from '@veo/web-design-system';
import { PlayerProfile } from 'src/features/player-profile/player-profile';
import {
  useOidcAuth,
  useRestrictedEvents,
  useSigninSilent,
  SignoutRedirect,
  SigninRedirect,
  Unauthorized,
} from 'src/features/oidc';
import { Layout } from 'src/components/layout/layout';
import { NotFound } from 'src/components/not-found/not-found';
import { APP_ROUTES } from 'src/common/constants/routes';
import { useVersion } from 'src/common/hooks/useVersion';
import { Home } from 'src/features/home/home';
import { useAnalyticsIdentifyUser } from 'src/features/analytics/hooks/useAnalyticsIdentifyUser';
import { OIDC_ROUTES } from './features/oidc/constants';
import { Forbidden } from './components/forbidden/forbidden';
import globalStyles from './App.module.scss';

export function App() {
  useVersion();
  useRestrictedEvents();
  useAnalyticsIdentifyUser();
  useSigninSilent();

  const { activeNavigator, isLoading } = useOidcAuth();

  if (isLoading && activeNavigator !== 'signinSilent') {
    return <Spinner className={globalStyles['app-loader']} size="lg" />;
  }

  return (
    <>
      <Helmet>
        <title>Veo | Player profile</title>
        <meta name="description" content="Player Offering dashboard" />
      </Helmet>
      <Routes>
        <Route path="/" element={<Layout className={globalStyles['pp-app']} />}>
          <Route index element={<Home />} />
          <Route path={APP_ROUTES.forbidden} element={<Forbidden />} />
          <Route path={OIDC_ROUTES.signinCallback} element={<SigninRedirect />} />
          <Route path={OIDC_ROUTES.signoutCallback} element={<SignoutRedirect />} />
          <Route path={OIDC_ROUTES.unauthorized} element={<Unauthorized />} />
          <Route path={APP_ROUTES.playerProfile}>
            <Route path=":id" element={<PlayerProfile />} />
          </Route>
          <Route path="*" element={<NotFound />} />
        </Route>
      </Routes>
    </>
  );
}
