import { RefObject } from 'react';
import {
  DndContext, DragEndEvent, MouseSensor, TouchSensor, useSensor, useSensors,
} from '@dnd-kit/core';
import { ITelestrationItem } from 'src/common/model/interfaces/ITelestration';
import { TelestrationCanvas, TelestrationCanvasProps } from '../telestration-canvas/telestration-canvas';
import { convertCoordsToSvg } from '../../utils/parsed-telestration';

type Props = TelestrationCanvasProps & {
  data: ITelestrationItem;
  containerRef: RefObject<HTMLDivElement>;
  onDragEnd(item: ITelestrationItem): void;
};

export function CustomSpotlightCanvas({
  width,
  height,
  children,
  data,
  className,
  containerRef,
  onDragEnd,
  ...restCanvasProp
}: Props) {
  const sensors = useSensors(
    useSensor(MouseSensor),
    useSensor(TouchSensor),
  );

  function handleDragEnd({ delta }: DragEndEvent) {
    const { x: deltaX, y: deltaY } = convertCoordsToSvg({
      point: delta,
      canvas: { width, height },
      viewContainerRef: containerRef,
    });

    const nextPoints = data.points.map(({ x, y }) => ({
      x: x + deltaX,
      y: y + deltaY,
    }));

    onDragEnd({
      ...data,
      points: nextPoints,
    });
  }

  return (
    <DndContext
      sensors={sensors}
      onDragEnd={handleDragEnd}
    >
      <TelestrationCanvas
        className={className}
        width={width}
        height={height}
        {...restCanvasProp}
      >
        { children }
      </TelestrationCanvas>
    </DndContext>
  );
}
