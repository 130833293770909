import { ValueOf } from 'src/common/model/types/helper.type';

const EVENT_PREFIX = 'Player_Profile';
const PAGE_VIEWED_SUFFIX = 'page_viewed';

export const EVENTS_STATUS = {
  SUCCESS: 'success',
  FAILED: 'failed',
};

export type EventsStatusType = ValueOf<typeof EVENTS_STATUS>;

export const PAGE_VIEW_EVENTS = {
  HOME_PAGE_VISITED: `${EVENT_PREFIX}_Home_${PAGE_VIEWED_SUFFIX}`,
  NOT_FOUND_VISITED: `${EVENT_PREFIX}_Not_Found_${PAGE_VIEWED_SUFFIX}`,
  UNAUTHORIZED_VISITED: `${EVENT_PREFIX}_Unauthorized_${PAGE_VIEWED_SUFFIX}`,
  FORBIDDEN_VISITED: `${EVENT_PREFIX}_Forbidden_${PAGE_VIEWED_SUFFIX}`,
  PROFILE_VISITED: `${EVENT_PREFIX}_${PAGE_VIEWED_SUFFIX}`,
} as const;

export type PageViewEventsType = ValueOf<typeof PAGE_VIEW_EVENTS>;

export const ANALYTIC_EVENTS = {
  // Player Profile Events
  /** Do not Remove!!!! This event is for counting the profile views in Lambda. */
  PROFILE_VIEW: `${EVENT_PREFIX}_public_view`,

  FULLSCREEN_REQUESTED: `${EVENT_PREFIX}_fullscreen_clicked`,
  ABILITY_FILTER_CLICKED: `${EVENT_PREFIX}_ability_filter_clicked`,
  VIDEO_VIEW_STARTED: `${EVENT_PREFIX}_video_view_started`,
  PROFILE_IMAGE_UPLOADED: `${EVENT_PREFIX}_image_uploaded`,
  SOCIAL_MEDIA_LINK_CLICKED: `${EVENT_PREFIX}_social_media_link_clicked`,
  ABOUT_INFO_UPDATED: `${EVENT_PREFIX}_about_section_updated`,
  KEY_INFO_UPDATED: `${EVENT_PREFIX}_key_information_updated`,
  POSITIONS_EDIT_CLICKED: `${EVENT_PREFIX}_edit_positions_clicked`,
  POSITIONS_UPDATED: `${EVENT_PREFIX}_positions_updated`,
  PROFILE_SETTINGS_UPDATED: `${EVENT_PREFIX}_settings_updated`,

  // Add video to profile Events
  ADD_CLIP_FILTERS_VIEW: `${EVENT_PREFIX}_add_clip_filters_viewed`,
  ADD_CLIP_FIND_VIEW: `${EVENT_PREFIX}_add_clip_find_viewed`,

  // Onboarding Events
  ONBOARDING: `${EVENT_PREFIX}_onboarding`,

  // Telestration Events
  TELESTRATION_CHANGED: `${EVENT_PREFIX}_telestration_changed`,
  TELESTRATION_VIEWED: `${EVENT_PREFIX}_telestration_viewed`,
  PLAYER_DETECTION: `${EVENT_PREFIX}_player_detection`,

  // Player Clips Events
  PLAYER_CLIPS_CHANGED: `${EVENT_PREFIX}_player_clips_changed`,
} as const;

export type AnalyticEventsType = ValueOf<typeof ANALYTIC_EVENTS>;
