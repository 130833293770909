import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useController, useFormContext } from 'react-hook-form';
import { debounce } from 'lodash-es';
import { FormField, Input } from '@veo/web-design-system';
import { validatePlayerField } from 'src/common/apis/player.api';
import { replaceEmptyValues } from 'src/common/utils/form-data';
import { isValidationError } from 'src/common/utils/requester-errors';
import { AboutInfoFormValues } from '../../../utils/about-information';
import styles from './about-information-form.module.scss';

export function UsernameField() {
  const { t } = useTranslation();

  const { control, register } = useFormContext<AboutInfoFormValues>();

  async function validateUsername(value: string, formValues: AboutInfoFormValues): Promise<boolean | string> {
    return new Promise((resolve) => {
      const fieldName: string = 'username';

      async function apiValidate() {
        try {
          const playerId = formValues.id;
          const values = replaceEmptyValues({ username: value });

          await validatePlayerField(playerId, values, fieldName);

          resolve(true);
        } catch (error) {
          let errorMessage = 'Something went wrong';
          if (isValidationError(error) && error.fields[fieldName]) {
            errorMessage = error.fields[fieldName];
          }

          resolve(errorMessage);
        }
      }

      if (value === '') {
        resolve(true);
      } else {
        apiValidate();
      }
    });
  }

  const debouncedValidator = useMemo(() => debounce(validateUsername, 300), []);

  const { field, fieldState } = useController<AboutInfoFormValues, 'username'>({
    control,
    name: 'username',
    rules: {
      validate: debouncedValidator,
    },
  });

  const { name, value } = field;

  const { error } = fieldState;

  const profileLink = `${window.location.hostname}/player/${value}`;

  return (
    <FormField
      id={name}
      className={styles.field}
      hint={t('profile_settings.form.username_field_tooltip') as string}
      label={t('profile_settings.form.username_field_label')}
      info={value && profileLink}
      error={error?.message}
    >
      <Input
        type="text"
        {...register('username')}
      />
    </FormField>
  );
}
