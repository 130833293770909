import { ReactNode } from 'react';
import { useOidcAuth } from '../../hooks/useOidcAuth';

type Props = {
  children: ReactNode;
};

export function OidcPublic({ children }: Props) {
  const { isAuthenticated } = useOidcAuth();

  if (isAuthenticated) {
    return null;
  }

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{ children }</>;
}
