import {
  IPlayer,
  IPlayerAboutInfo,
  IPlayerKeyInfo,
  IPlayerPositionInfo,
  IPlayerImage,
} from 'src/common/model/interfaces/IPlayer';
import { playerSvc } from '../services/player-svc';
import { isPlayerUsername } from '../utils/player-profile';

export async function getPlayerById(id: string): Promise<IPlayer> {
  const res = await playerSvc.request({ method: 'GET', url: `players/by/playerId/${id}` });
  return res as IPlayer;
}

export async function getPlayerByUsername(username: string): Promise<IPlayer> {
  const res = await playerSvc.request({ method: 'GET', url: `players/by/username/${username}` });
  return res as IPlayer;
}

export async function getPlayer(identifier: string): Promise<IPlayer> {
  const isUsernameProvided = isPlayerUsername(identifier);

  let res: IPlayer;

  if (isUsernameProvided) {
    res = await getPlayerByUsername(identifier);
  } else {
    res = await getPlayerById(identifier);
  }

  return res as IPlayer;
}

export async function updatePlayer(id: string, data: Partial<IPlayer>): Promise<unknown> {
  const res = await playerSvc.request({ method: 'PATCH', url: `players/by/playerId/${id}`, data });
  return res;
}

export async function validatePlayerForm(id: string, data: Record<string, unknown>) {
  const res = await playerSvc.request({ method: 'POST', url: `players/by/playerId/${id}/validate`, data });

  return res;
}

export async function validatePlayerField(id: string, data: Record<string, unknown>, fieldName: string) {
  const res = await playerSvc.request({ method: 'POST', url: `players/by/playerId/${id}/validate/${fieldName}`, data });

  return res;
}

export async function updatePlayerKeyInfo(data: IPlayerKeyInfo): Promise<unknown> {
  const { id, ...restData } = data;

  const res = await playerSvc.request({ method: 'PUT', url: `players/keyInfo/by/playerId/${id}`, data: restData });

  return res;
}

export async function updatePlayerPositionInfo(data: IPlayerPositionInfo): Promise<unknown> {
  const { id, ...restData } = data;

  const res = await playerSvc.request({ method: 'PUT', url: `players/positionInfo/by/playerId/${id}`, data: restData });

  return res;
}

export async function updatePlayerAboutInfo(data: IPlayerAboutInfo): Promise<unknown> {
  const { id } = data;

  const res = await playerSvc.request({ method: 'PUT', url: `players/aboutInfo/by/playerId/${id}`, data });

  return res;
}

export async function updatePlayerImage(data: IPlayerImage): Promise<unknown> {
  const { id, image } = data;

  if (!image) {
    return Promise.reject(new Error('Failed to upload'));
  }

  const formData = new FormData();
  const time = new Date().getTime();

  formData.append('image', image, `image_${time}.jpeg`);

  const res = await playerSvc.request({ method: 'POST', url: `players/image/by/playerId/${id}`, data: formData });

  return res;
}

export async function removePlayerImage(data: Pick<IPlayerImage, 'id'>): Promise<unknown> {
  const { id } = data;

  const res = await playerSvc.request({ method: 'DELETE', url: `players/image/by/playerId/${id}` });

  return res;
}
