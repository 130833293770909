import i18n from 'src/common/services/i18next';
import { Position } from 'src/common/model/enums/Position';

export const PITCH_POSITIONS = {
  [Position.Striker]: {
    order: 1,
    position: Position.Striker,
    label: i18n.t('entity.position.Striker'),
  },
  [Position.LeftWing]: {
    order: 2,
    position: Position.LeftWing,
    label: i18n.t('entity.position.LeftWing'),
  },
  [Position.AttackingMidfielder]: {
    order: 3,
    position: Position.AttackingMidfielder,
    label: i18n.t('entity.position.AttackingMidfielder'),
  },
  [Position.RightWing]: {
    order: 4,
    position: Position.RightWing,
    label: i18n.t('entity.position.RightWing'),
  },
  [Position.LeftMidfielder]: {
    order: 5,
    position: Position.LeftMidfielder,
    label: i18n.t('entity.position.LeftMidfielder'),
  },
  [Position.CentralMidfielder]: {
    order: 6,
    position: Position.CentralMidfielder,
    label: i18n.t('entity.position.CentralMidfielder'),
  },
  [Position.RightMidfielder]: {
    order: 7,
    position: Position.RightMidfielder,
    label: i18n.t('entity.position.RightMidfielder'),
  },
  [Position.LeftWingBack]: {
    order: 8,
    position: Position.LeftWingBack,
    label: i18n.t('entity.position.LeftWingBack'),
  },
  [Position.DefensiveMidfielder]: {
    order: 9,
    position: Position.DefensiveMidfielder,
    label: i18n.t('entity.position.DefensiveMidfielder'),
  },
  [Position.RightWingBack]: {
    order: 10,
    position: Position.RightWingBack,
    label: i18n.t('entity.position.RightWingBack'),
  },
  [Position.LeftFullBack]: {
    order: 11,
    position: Position.LeftFullBack,
    label: i18n.t('entity.position.LeftFullBack'),
  },
  [Position.CentreBack]: {
    order: 12,
    position: Position.CentreBack,
    label: i18n.t('entity.position.CentreBack'),
  },
  [Position.RightFullBack]: {
    order: 13,
    position: Position.RightFullBack,
    label: i18n.t('entity.position.RightFullBack'),
  },
  [Position.Goalkeeper]: {
    order: 14,
    position: Position.Goalkeeper,
    label: i18n.t('entity.position.Goalkeeper'),
  },
};
