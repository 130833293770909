import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ErrorPage } from '@veo/web-design-system';
import { usePageViewEvent } from 'src/features/analytics/hooks/usePageViewEvent';
import { PAGE_VIEW_EVENTS } from 'src/features/analytics/constants/segment-events';

export function Forbidden() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  usePageViewEvent(PAGE_VIEW_EVENTS.FORBIDDEN_VISITED);

  return (
    <ErrorPage
      illustration="users"
      title={t('auth.forbidden.title')}
      description={t('auth.forbidden.description')}
      onButtonClick={() => navigate(-1)}
      buttonText={t('auth.forbidden.action_label') as string}
      buttonIconStart="arrow-left"
    />
  );
}
