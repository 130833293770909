const BREAKPOINTS = {
  MOBILE: 479,
  DESKTOP: 960,
};

export const MEDIA_QUERY = {
  MOBILE: `(max-width: ${BREAKPOINTS.MOBILE}px)`,
  TABLET: `(min-width: ${BREAKPOINTS.MOBILE + 1}px) and (max-width: ${BREAKPOINTS.DESKTOP - 1}px)`,
  DESKTOP: `(min-width: ${BREAKPOINTS.DESKTOP}px)`,
};
