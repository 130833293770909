import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { Text, Tooltip } from '@veo/web-design-system';
import { formatDate } from 'src/common/utils/luxon-utils';
import { Paper } from 'src/components/paper/paper';
import { TextSkeleton } from 'src/components/skeletons/text-skeleton';
import { Privacy } from 'src/common/model/enums/Privacy';
import { IMatch } from 'src/common/model/interfaces/IMatch';
import { FilterParams } from 'src/common/hooks/useQueryParams';
import { useAuthPlayerZolaClips } from 'src/common/hooks/zola-clips/useAuthPlayerZolaClips';
import styles from './match-filters.module.scss';

type Props = {
  match: IMatch;
  filter: FilterParams;
  onClick(match: IMatch): void;
};

export function MatchItem({
  match,
  filter,
  onClick,
}: Props) {
  const { t } = useTranslation();

  const {
    thumbnail,
    title,
    privacy,
    date,
  } = match;

  const {
    data: zolaClips,
    isLoading,
  } = useAuthPlayerZolaClips({
    filter, pageSize: 10, page: 1, search: null, sort: null,
  });

  const formattedDate = formatDate(date);

  const matchContent = (
    <>
      <img className={styles.thumbnail} src={thumbnail} alt={title} />
      <div className={styles.info}>
        <Text
          type="block"
          size="h6"
          weight="semiBold"
          className={styles['match-title']}
        >
          {title}
        </Text>
        {
          !isLoading && (
            <Text
              className={styles['dimmed-color']}
              type="block"
              weight="medium"
            >
              { t('add_player_clips.filters.count', { count: zolaClips?.pages[0]?.meta.totalCount }) }
            </Text>
          )
        }
        { isLoading && (<TextSkeleton words={1} />)}
        <Text
          className={styles['dimmed-color']}
          type="block"
          weight="medium"
        >
          { formattedDate }
        </Text>
      </div>
    </>
  );

  if (privacy === Privacy.Private) {
    return (
      <Tooltip
        portal
        text={t('add_player_clips.filters.private_match_tooltip')}
      >
        <div
          aria-disabled
          className={cn(styles.item, styles.disabled)}
        >
          {matchContent}
        </div>
      </Tooltip>
    );
  }

  return (
    <Paper
      role="button"
      tabIndex={0}
      elevation={2}
      onClick={() => onClick(match)}
      className={styles.item}
    >
      {matchContent}
    </Paper>
  );
}
