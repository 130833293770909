import { useEffect } from 'react';
import { uniq } from 'lodash-es';
import { useAuthPlayerTeams } from 'src/common/hooks/player/useAuthPlayerTeams';
import { useOidcAuth } from 'src/features/oidc';
import { useAuthPlayer } from 'src/features/auth';
import { useAnalytics } from './useAnalytics';

export function useAnalyticsIdentifyUser() {
  const { data: user } = useOidcAuth();
  const { data: authPlayer } = useAuthPlayer();
  const { data: authPlayerTeams } = useAuthPlayerTeams();

  const { analytics } = useAnalytics();

  useEffect(() => {
    if (user && authPlayerTeams && authPlayer) {
      const { externalId, email } = user;
      const { id: playerId } = authPlayer;

      const clubIds = authPlayerTeams.map((team) => team.club.id);

      const traits = { email, clubPublicIdentifiers: uniq(clubIds), playerAppId: playerId };

      analytics?.identify(externalId, traits);
    }
  }, [user, authPlayerTeams, authPlayer]);

  useEffect(() => {
    if (!user) {
      analytics?.reset();
    }
  }, [user]);
}
