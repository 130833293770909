import {
  useMemo, useState, useEffect, useCallback,
} from 'react';
import cn from 'classnames';
import { flatten } from 'lodash-es';
import { Modal } from '@veo/web-design-system';
import { VeoPlayer } from 'src/components/veo-player/veo-player';
import { PlayerClipsPlaylist } from 'src/features/profile-video/components/player-clips-playlist/player-clips-playlist';
import { IPlayerClip } from 'src/common/model/interfaces/IPlayerClip';
import { IPlayer } from 'src/common/model/interfaces/IPlayer';
import { usePlayerClips } from 'src/common/hooks/player-clips/usePlayerClips';
import { useViewportSize } from 'src/common/hooks/useViewportSize';
import { useAuthPlayer } from 'src/features/auth';
import { useResizeObserver } from 'src/common/hooks/useResizeObserver';
import { useMediaQuery } from 'src/common/hooks/useMediaQuery';
import { VeoPlayerRendererProps } from 'src/components/veo-player/types/veo-player.type';
import { formatFloat } from 'src/common/utils/dev-utils';
import { useAnalytics } from 'src/features/analytics/hooks/useAnalytics';
import { MEDIA_QUERY } from 'src/common/constants/media-query';
import { useIntersectionObserver } from 'src/common/hooks/useIntersectionObserver';
import { PlayerClipsFilter } from './model/player-clips-filter';
import { PlayerSpotlightsOverlay } from './components/player-spotlights-overlay/player-spotlights-overlay';
import { PlayerClipPanel } from './components/player-clip/player-clip-panel';
import { SortablePlayerClips } from './components/sortable-player-clips/sortable-player-clips';
import { useObscuredElement } from './hooks/useObscuredElement';
import { EmptyPlayerClips } from './components/empty-player-clips/empty-player-clips';
import { PlayerClipsSkeleton } from './components/player-clips-skeleton/player-clips-skeleton';
import { VideoPlayerControls } from './components/video-player-controls/video-player-controls';
import { useActiveClipManager } from './hooks/useActiveClipManager';
import styles from './profile-video.module.scss';

type Props = {
  player: IPlayer
};

const profileVideoPlayerControls = {
  play: true,
  previous: true,
  next: true,
  fullscreen: true,
};

export function ProfileVideo({ player }: Props) {
  const [filter, setFilter] = useState<PlayerClipsFilter>({ playerId: player.id });
  const [collapsedPlaylist, setCollapsedPlaylist] = useState(true);
  const [sortable, setSortable] = useState(false);
  const [isFullscreen, setIsFullscreen] = useState(false);

  const viewportSize = useViewportSize();

  const { analytics, events } = useAnalytics();

  const isMobileLayout = useMediaQuery(MEDIA_QUERY.MOBILE);

  const { data: authPlayer } = useAuthPlayer();

  const { ref: videoPlayerRef, isObscured } = useObscuredElement<HTMLVideoElement>();

  useEffect(() => {
    if (sortable || isObscured) {
      videoPlayerRef.current?.pause();
    }

    if (!sortable && !isObscured) {
      videoPlayerRef.current?.play();
    }
  }, [sortable, isObscured]);

  const [wrapperRef, boundBox] = useResizeObserver<HTMLDivElement>();

  const {
    data: infiniteList,
    isLoading: isPlayerClipsLoading,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
  } = usePlayerClips({ pageSize: 10, filter });

  const isLoading = isPlayerClipsLoading || isFetchingNextPage;
  const playerClipsCount = infiniteList?.pages[0]?.meta.totalCount;

  const { ref: lastItemRef } = useIntersectionObserver<HTMLDivElement>({
    enabled: infiniteList && hasNextPage && !isLoading,
    onIntersect: fetchNextPage,
    options: { rootMargin: collapsedPlaylist ? '5px' : '20px' },
  });

  const list = useMemo(() => {
    if (infiniteList) {
      const allPagesItems = infiniteList.pages.map(({ items }) => items);
      const allHighlights = flatten(allPagesItems);

      return allHighlights;
    }

    return [];
  }, [infiniteList]);

  const activeClipManager = useActiveClipManager({ clips: list });
  const {
    activeClip,
    activeClipIndex,
    setActiveClip,
  } = activeClipManager;

  function trackVideoViewPlayed() {
    /** Do not fire analytic event if list is loading */
    if (isPlayerClipsLoading) {
      return;
    }

    const playerClip = list[activeClipIndex];

    const { width, height } = viewportSize;

    const viewport = `${width} x ${height}`;
    const viewportAspect = `${formatFloat(width / height, 2)}`;

    analytics?.track(events.VIDEO_VIEW_STARTED, {
      viewport,
      viewportAspect,
      own: player.id === authPlayer?.id,
      highlightId: playerClip?.videoId,
    });
  }

  useEffect(() => {
    /** 3 means if we are playing second from the end we need to fetch more if possible */
    if (activeClipIndex === list.length - 3 && hasNextPage) {
      fetchNextPage();
    }
  }, [activeClipIndex]);

  function scrollToVideo() {
    if (videoPlayerRef.current) {
      videoPlayerRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  }

  function handleFilterChange(f: PlayerClipsFilter) {
    if (isMobileLayout && !collapsedPlaylist) {
      scrollToVideo();
    }

    setFilter(f);

    /** Remove active clip, so once clips refetched the first one becomes active */
    if (f.ability !== filter.ability) {
      setActiveClip(null);
    }

    analytics?.track(events.ABILITY_FILTER_CLICKED);
  }

  /** Reset ability filter if no clips present for selected ability */
  useEffect(() => {
    if (!playerClipsCount && filter.ability && !isLoading) {
      const { ability, ...restFilters } = filter;

      handleFilterChange({ ...restFilters });
    }
  }, [playerClipsCount, isLoading, filter]);

  function playNextClip() {
    if (!playerClipsCount || playerClipsCount === 1) {
      return;
    }

    const nextIndex = activeClipIndex < (playerClipsCount - 1) ? activeClipIndex + 1 : 0;

    setActiveClip(list[nextIndex]);
  }

  function playPreviousClip() {
    if (activeClipIndex <= 0) {
      return;
    }

    const nextClip = list[activeClipIndex - 1];

    setActiveClip(nextClip);
  }

  function handleCollapseChange(collapsed: boolean) {
    if (isMobileLayout && collapsed) {
      scrollToVideo();
    }

    setCollapsedPlaylist(collapsed);
  }

  function handlePlayClip(pc: IPlayerClip) {
    if (isMobileLayout) {
      scrollToVideo();
    }

    setActiveClip(pc);
  }

  function handleToggleFullscreen(fullscreen: boolean) {
    setIsFullscreen(fullscreen);
  }

  function handleRemoveClip(pc: IPlayerClip) {
    if (pc.id === activeClip?.id) {
      /** Remove active clip, so once clips refetched the first one becomes active */
      setActiveClip(null);
    }
  }

  function handleReorderClips() {
    /** Remove active clip, so once clips refetched the first one becomes active */
    setActiveClip(null);
  }

  function getClipHasCurrentFilter(pc: IPlayerClip) {
    if (!filter.ability) {
      return true;
    }

    return pc.ability.some((pcAbility) => pcAbility === filter.ability);
  }

  function handleUpdateClip(pc: IPlayerClip) {
    const updatedClipHasCurrentAbilityFilter = getClipHasCurrentFilter(pc);

    if (!updatedClipHasCurrentAbilityFilter) {
      /** Remove active clip, so once clips refetched the first one becomes active */
      setActiveClip(null);

      return;
    }

    setActiveClip(pc);
  }

  function handleAddClip(pc: IPlayerClip) {
    const newClipHasCurrentAbilityFilter = getClipHasCurrentFilter(pc);

    if (newClipHasCurrentAbilityFilter) {
      /** Remove active clip, so once clips refetched the first one becomes active */
      setActiveClip(null);
    }
  }

  const controlsRenderer = useCallback(
    (rendererProps: VeoPlayerRendererProps) => {
      if (!activeClip) {
        return null;
      }

      const prefix = `${activeClipIndex + 1} / ${playerClipsCount}`;

      return (
        <VideoPlayerControls playerClip={activeClip} prefix={prefix} {...rendererProps} />
      );
    },
    [activeClip],
  );

  const videoPlayerClassnames = cn(
    styles['video-player'],
    { [styles.collapsed]: collapsedPlaylist },
  );

  const playListClassnames = cn(
    styles.playlist,
    { [styles.collapsed]: collapsedPlaylist },
  );

  /** Check for ability filter to prevent showing empty clips when removed last clip in filtered view */
  if (playerClipsCount === 0 && !filter.ability) {
    return <EmptyPlayerClips player={player} />;
  }

  if (!list) {
    return null;
  }

  return (
    <div className={styles.container}>
      <div ref={wrapperRef} className={videoPlayerClassnames}>
        <VeoPlayer
          videoControls={profileVideoPlayerControls}
          ref={videoPlayerRef}
          src={activeClip?.url}
          videoFit={isMobileLayout ? 'fit' : 'cover'}
          poster={activeClip?.thumbnail}
          controlsRenderer={controlsRenderer}
          onVideoStarted={trackVideoViewPlayed}
          onVideoEnded={playNextClip}
          onPlayPrevious={playPreviousClip}
          onPlayNext={playNextClip}
          onToggleFullscreen={handleToggleFullscreen}
        >
          <PlayerSpotlightsOverlay
            videoRef={videoPlayerRef}
            player={player}
            telestration={activeClip?.telestration ?? undefined}
            enabled={!isFullscreen}
          />
        </VeoPlayer>
      </div>
      <div
        className={playListClassnames}
        style={{ maxHeight: isMobileLayout || !boundBox?.height ? 'initial' : `${boundBox.height}px` }}
      >
        {
          !sortable && (
            <PlayerClipsPlaylist
              filter={filter}
              collapsed={collapsedPlaylist}
              orderingDisabled={!(!!playerClipsCount && playerClipsCount > 1)}
              onReorder={() => setSortable(true)}
              onFilterChange={handleFilterChange}
              onCollapseChange={handleCollapseChange}
              onAddClip={handleAddClip}
            >
              {
                list.map((pc) => (
                  <PlayerClipPanel
                    key={pc.id}
                    ref={pc.id === list[list.length - 1].id ? lastItemRef : undefined}
                    active={activeClip?.id === pc.id}
                    videoRef={videoPlayerRef}
                    collapsed={collapsedPlaylist}
                    playerClip={pc}
                    onClick={handlePlayClip}
                    onRemove={handleRemoveClip}
                    onUpdate={handleUpdateClip}
                  />
                ))
              }
              {
                isFetchingNextPage && (
                  <PlayerClipsSkeleton
                    mobile={isMobileLayout}
                    horizontal={isMobileLayout && collapsedPlaylist}
                  />
                )
              }
            </PlayerClipsPlaylist>
          )
        }
        {
          sortable && !isMobileLayout && (
          <SortablePlayerClips
            filter={filter}
            onClose={() => setSortable(false)}
            onReorder={handleReorderClips}
          />
          )
        }
        <Modal
          open={sortable && isMobileLayout}
          onClose={() => setSortable(false)}
          className={styles['sort-modal']}
        >
          <SortablePlayerClips
            filter={filter}
            onClose={() => setSortable(false)}
            onReorder={handleReorderClips}
          />
        </Modal>
      </div>
    </div>
  );
}
