import { useEffect } from 'react';
import { isNull } from 'lodash-es';
import { useOidcAuth } from './useOidcAuth';

export function useSigninSilent() {
  const {
    user,
    signinSilent,
  } = useOidcAuth();

  useEffect(() => {
    async function doSilent() {
      await signinSilent({
        silentRequestTimeoutInSeconds: 5,
      });
    }

    if (isNull(user)) {
      doSilent();
    }
  }, [user]);
}
