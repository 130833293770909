import { useTranslation } from 'react-i18next';
import { useFormContext, useController } from 'react-hook-form';
import { FormField, TextArea } from '@veo/web-design-system';
import { isMaxLengthValidator } from 'src/common/utils/form-validators';
import { AboutInfoFormValues } from '../../../utils/about-information';
import styles from './about-information-form.module.scss';

export function AboutField() {
  const { t } = useTranslation();

  const { control, register } = useFormContext<AboutInfoFormValues>();

  const { fieldState } = useController<AboutInfoFormValues, 'about'>({
    control,
    name: 'about',
    rules: {
      validate: (v) => isMaxLengthValidator(800)(v),
    },
  });

  const {
    isTouched,
    error,
  } = fieldState;

  return (
    <FormField
      className={styles.field}
      id="about"
      label={t('player_about.form.about_field_label')}
      error={isTouched ? error?.message : undefined}
    >
      <TextArea
        {...register('about')}
      />
    </FormField>
  );
}
