import { useInfiniteQuery } from 'react-query';
import { getTeamMembers } from 'src/common/apis/teams.api';
import { RQ_KEY, EXTENDED_CACHE_TIME_MS } from 'src/common/constants/react-query';
import { IPaginatedResponse } from 'src/common/model/interfaces/IPaginatedResponse';
import { RequesterError } from 'src/common/model/interfaces/IRequesterError';
import { ITeam } from 'src/common/model/interfaces/ITeam';
import { ITeamMember } from 'src/common/model/interfaces/ITeamMember';
import { useOidcAuth } from 'src/features/oidc';

const PAGE_SIZE = 10;

export function useUserTeamMembers(team?: ITeam) {
  const { data: authUser } = useOidcAuth();
  const clubSlug = team?.club.slug as string;
  const teamSlug = team?.slug as string;

  return useInfiniteQuery<IPaginatedResponse<ITeamMember>, RequesterError>(
    [RQ_KEY.TEAM_MEMBERS, { clubSlug, teamSlug }],
    ({ signal, pageParam = 1 }) => getTeamMembers(
      { clubSlug, teamSlug },
      {
        page: pageParam as number,
        pageSize: PAGE_SIZE,
        filter: null,
        search: null,
        sort: null,
      },
      signal,
    ),
    {
      enabled: !!authUser && !!team,
      cacheTime: EXTENDED_CACHE_TIME_MS,
      staleTime: EXTENDED_CACHE_TIME_MS,
      getNextPageParam: (lastPage) => {
        const totalPages = Math.ceil(lastPage.meta.totalCount / PAGE_SIZE);
        const nextPageExists = totalPages > lastPage.meta.pageNumber;

        return nextPageExists ? lastPage.meta.pageNumber + 1 : null;
      },
    },
  );
}
