import { Position } from '../model/enums/Position';
import { AbilityCodes } from '../model/enums/AbilityCode';

export const POSITION_ABILITY_MAP = {
  [Position.Goalkeeper]: [
    AbilityCodes.OneOnOnes,
    AbilityCodes.ShotStopping,
    AbilityCodes.Leadership,
    AbilityCodes.AerialAbility,
  ],
  [Position.RightFullBack]: [
    AbilityCodes.Tackling,
    AbilityCodes.Acceleration,
    AbilityCodes.LongThrows,
    AbilityCodes.Marking,
  ],
  [Position.LeftFullBack]: [
    AbilityCodes.Tackling,
    AbilityCodes.Acceleration,
    AbilityCodes.LongThrows,
    AbilityCodes.Marking,
  ],
  [Position.CentreBack]: [
    AbilityCodes.Tackling,
    AbilityCodes.Interceptions,
    AbilityCodes.Passing,
    AbilityCodes.Strength,
  ],
  [Position.RightWingBack]: [
    AbilityCodes.Tackling,
    AbilityCodes.Speed,
    AbilityCodes.Acceleration,
    AbilityCodes.Crossing,
  ],
  [Position.LeftWingBack]: [
    AbilityCodes.Tackling,
    AbilityCodes.Speed,
    AbilityCodes.Acceleration,
    AbilityCodes.Crossing,
  ],
  [Position.DefensiveMidfielder]: [
    AbilityCodes.Interceptions,
    AbilityCodes.Tackling,
    AbilityCodes.Passing,
    AbilityCodes.Aggression,
  ],
  [Position.RightMidfielder]: [
    AbilityCodes.Passing,
    AbilityCodes.Crossing,
    AbilityCodes.Speed,
    AbilityCodes.Vision,
  ],
  [Position.LeftMidfielder]: [
    AbilityCodes.Passing,
    AbilityCodes.Crossing,
    AbilityCodes.Speed,
    AbilityCodes.Vision,
  ],
  [Position.CentralMidfielder]: [
    AbilityCodes.Passing,
    AbilityCodes.Vision,
    AbilityCodes.BallControl,
    AbilityCodes.Positioning,
  ],
  [Position.RightWing]: [
    AbilityCodes.Technique,
    AbilityCodes.Acceleration,
    AbilityCodes.Dribbling,
    AbilityCodes.Crossing,
  ],
  [Position.LeftWing]: [
    AbilityCodes.Technique,
    AbilityCodes.Acceleration,
    AbilityCodes.Dribbling,
    AbilityCodes.Crossing,
  ],
  [Position.AttackingMidfielder]: [
    AbilityCodes.Technique,
    AbilityCodes.Passing,
    AbilityCodes.BallControl,
    AbilityCodes.Vision,
  ],
  [Position.Striker]: [
    AbilityCodes.Finishing,
    AbilityCodes.Heading,
    AbilityCodes.Speed,
    AbilityCodes.Strength,
  ],
};
