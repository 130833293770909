import { isUndefined } from 'lodash-es';
import {
  RefObject, useState, useEffect, useRef,
} from 'react';
import { getBrowserDetails, BROWSERS } from 'src/common/utils/user-agent';
import { useTelestrationDebug, debugParams } from '../components/debug-panel/debug-context';

type Props = {
  videoRef: RefObject<HTMLVideoElement>;
};

export function useTimeOffset({ videoRef }: Props) {
  const [offset, setOffset] = useState<number>();
  const videoFrameCallbackRef = useRef<number | null>(null);

  const { registerValue } = useTelestrationDebug();

  useEffect(() => {
    const { name: browser } = getBrowserDetails();

    const registerTimeOffset: VideoFrameRequestCallback = (now, { mediaTime }) => {
      if (videoRef.current && isUndefined(offset)) {
        setOffset(mediaTime);

        registerValue(debugParams.videoOffsetRegisteredAt, videoRef.current.currentTime);
      }

      if (videoRef.current && videoFrameCallbackRef.current) {
        videoRef.current.cancelVideoFrameCallback(videoFrameCallbackRef.current);
      }
    };

    const attachFrameCallback = () => {
      if (videoRef.current) {
        videoFrameCallbackRef.current = videoRef.current.requestVideoFrameCallback(registerTimeOffset);
      }
    };

    if (videoRef.current && browser === BROWSERS.CHROME) {
      videoRef.current.addEventListener('loadedmetadata', attachFrameCallback);
    }

    if (browser !== BROWSERS.CHROME) {
      setOffset(0);
    }

    return () => {
      if (videoRef.current && videoFrameCallbackRef.current) {
        videoRef.current.cancelVideoFrameCallback(videoFrameCallbackRef.current);
        videoRef.current.removeEventListener('loadedmetadata', attachFrameCallback);
      }
    };
  }, []);

  return offset;
}
