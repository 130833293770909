import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { FormProvider, useForm } from 'react-hook-form';
import { Modal, ModalHeader } from '@veo/web-design-system';
import { IPlayer } from 'src/common/model/interfaces/IPlayer';
import { useAuthPlayer } from 'src/features/auth';
import { Confirmation, useConfirmation } from 'src/components/confirmation';
import { AboutInformationForm } from './form';
import { AboutInfoFormValues, getAboutInfoFormValues } from '../../utils/about-information';
import styles from './about-information-modal.module.scss';

type Props = {
  open: boolean;
  player: IPlayer;
  onClose(open: boolean): void;
};

export function AboutInformationModal({ open, player, onClose }: Props) {
  const { data: authPlayer } = useAuthPlayer();

  const { t } = useTranslation();

  const formValues = useMemo(() => getAboutInfoFormValues(player), [player]);

  const formConfig = useForm<AboutInfoFormValues>({
    values: formValues,
    disabled: !open,
    shouldUseNativeValidation: false,
    mode: 'onSubmit',
  });

  const { formState, reset } = formConfig;
  const { isDirty } = formState;

  function closeModalAndReset() {
    onClose(false);
    reset();
  }

  const { triggerAction: confirmAndClose, ...restConfirmationProps } = useConfirmation({
    required: isDirty,
    action: closeModalAndReset,
  });

  return (
    <>
      <Confirmation
        title={t('player_about.close_confirmation.title')}
        message={t('player_about.close_confirmation.description') as string}
        confirmLabel={t('player_about.close_confirmation.confirm_button_label') as string}
        cancelLabel={t('player_about.close_confirmation.cancel_button_label') as string}
        {...restConfirmationProps}
      />
      <Modal
        open={open && !!authPlayer}
        onClose={confirmAndClose}
        className={styles.modal}
      >
        <ModalHeader
          title={t('player_about.heading')}
          description={t('player_about.description') as string}
        />
        <FormProvider<AboutInfoFormValues> {...formConfig}>
          <AboutInformationForm
            onSubmit={closeModalAndReset}
            onCancel={confirmAndClose}
          />
        </FormProvider>
      </Modal>
    </>
  );
}
