import { useTranslation } from 'react-i18next';
import { useForm, FormProvider } from 'react-hook-form';
import { Modal, ModalHeader } from '@veo/web-design-system';
import { useConfirmation, Confirmation } from 'src/components/confirmation';
import { IPlayer } from 'src/common/model/interfaces/IPlayer';
import { ProfileImageEditor } from './profile-image-editor';
import { AboutImageFormValues } from '../../utils/about-information';
import styles from './profile-image-modal.module.scss';

type Props = {
  open: boolean;
  player: IPlayer;
  onClose(open: boolean): void;
};

export function ProfileImageModal({ open, player, onClose }: Props) {
  const { t } = useTranslation();

  const formConfig = useForm<AboutImageFormValues>({
    defaultValues: { image: player.image ?? '' },
    mode: 'onSubmit',
  });

  const { formState, reset } = formConfig;
  const { isDirty } = formState;

  function handelCloseModal() {
    onClose(false);
    reset();
  }

  const { triggerAction: confirmAndClose, ...restConfirmationProps } = useConfirmation({
    required: isDirty,
    action: handelCloseModal,
  });

  return (
    <>
      <Confirmation
        title={t('player_about.close_confirmation.title')}
        message={t('player_about.close_confirmation.description') as string}
        confirmLabel={t('player_about.close_confirmation.confirm_button_label') as string}
        cancelLabel={t('player_about.close_confirmation.cancel_button_label') as string}
        {...restConfirmationProps}
      />
      <Modal
        className={styles.modal}
        open={open}
        onClose={confirmAndClose}
      >
        <ModalHeader
          title={t('player_about.edit_image_heading')}
          description={t('player_about.edit_image_description') as string}
        />
        <FormProvider<AboutImageFormValues> {...formConfig}>
          <ProfileImageEditor
            player={player}
            onSubmit={handelCloseModal}
            onCancel={confirmAndClose}
          />
        </FormProvider>
      </Modal>
    </>
  );
}
