/* eslint-disable @typescript-eslint/naming-convention */
import { useMemo, ReactNode } from 'react';
import { WebStorageStateStore } from 'oidc-client-ts';
import { AuthProvider, type AuthProviderProps } from 'react-oidc-context';
import { config } from 'src/config';
import { OIDC_ROUTES } from './constants';

const baseOidcConfig: AuthProviderProps = {
  authority: config.OIDC_URL as string,
  client_id: config.OIDC_CLIENT_ID as string,
  redirect_uri: `${window.location.origin}/${OIDC_ROUTES.signinCallback}`,
  post_logout_redirect_uri: `${window.location.origin}/${OIDC_ROUTES.signoutCallback}`,
  silent_redirect_uri: `${window.location.origin}/${OIDC_ROUTES.signinCallback}`,
  scope: 'openid profile email',
  userStore: new WebStorageStateStore({ store: window.sessionStorage }),
  /** 120 seconds to perform login */
  staleStateAgeInSeconds: 120,
};

type Props = {
  children: ReactNode;
};

export function OidcProvider({ children }: Props) {
  function onSigninCallback() {
    window.history.replaceState(
      {},
      document.title,
      window.location.pathname,
    );
  }

  const providerConfig: AuthProviderProps = useMemo(() => ({
    ...baseOidcConfig,
    onSigninCallback,
    loadUserInfo: true,
  }), []);

  return (
    <AuthProvider {...providerConfig}>
      { children }
    </AuthProvider>
  );
}
