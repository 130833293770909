import { QueryParams } from '../hooks/useQueryParams';
import { getUrlQueryParams } from '../utils/query-params';
import { IPaginatedResponse } from '../model/interfaces/IPaginatedResponse';
import { IPlayerClip } from '../model/interfaces/IPlayerClip';
import { playerSvc } from '../services/player-svc';

export async function getPlayerClips(
  playerId: string,
  queryParams?: Partial<QueryParams>,
  signal?: any,
): Promise<IPaginatedResponse<IPlayerClip>> {
  let params;

  if (queryParams) {
    params = getUrlQueryParams(queryParams);
  }

  const url = `/playerClips/by/playerId/${playerId}`;

  const res = await playerSvc.request({
    method: 'GET', url, params, signal,
  });

  return res as Promise<IPaginatedResponse<IPlayerClip>>;
}

export async function getPlayerClipById(playerClipId: string) {
  const url = `/playerClips/${playerClipId}`;

  const res = await playerSvc.request({ method: 'GET', url });

  return res as Promise<IPlayerClip>;
}

export async function createPlayerClip(data: IPlayerClip) {
  const url = '/playerClips';

  const res = await playerSvc.request({ method: 'POST', url, data });

  return res as Promise<IPlayerClip>;
}

export async function orderPlayerClips(playerClips: IPlayerClip[]) {
  const url = '/playerClips';

  const res = await playerSvc.request({ method: 'PATCH', url, data: playerClips });

  return res;
}

export async function deletePlayerClip(playerClipId: string) {
  const url = `/playerClips/${playerClipId}`;

  const res = await playerSvc.request({ method: 'DELETE', url });

  return res;
}

export async function updatePlayerClip(playerClip: IPlayerClip) {
  const url = `/playerClips/${playerClip.id}`;

  const res = await playerSvc.request({ method: 'PUT', url, data: playerClip });

  return res;
}
