/* eslint-disable no-param-reassign */
import { RefObject, useEffect, useRef } from 'react';
import { useAnalytics } from 'src/features/analytics';
import { useAuthPlayer } from 'src/features/auth';
import { IPlayer } from 'src/common/model/interfaces/IPlayer';
import { ITelestration } from 'src/common/model/interfaces/ITelestration';
import { useMediaQuery } from 'src/common/hooks/useMediaQuery';
import { MEDIA_QUERY } from 'src/common/constants/media-query';
import { TelestrationOverlay, RectRef } from 'src/features/telestration';
import { useViewportSize } from 'src/common/hooks/useViewportSize';
import styles from './player-spotlights-overlay.module.scss';

type Props = {
  player: IPlayer;
  telestration?: ITelestration;
  resetPanOnHide?: boolean;
  enabled?: boolean;
  videoRef: RefObject<HTMLVideoElement>;
};

export function PlayerSpotlightsOverlay({
  player,
  telestration,
  resetPanOnHide = false,
  enabled = true,
  videoRef,
}: Props) {
  const videoPannedRef = useRef(false);
  const { width: viewportWidth } = useViewportSize();

  const { data: authPlayer } = useAuthPlayer();
  const { analytics, events } = useAnalytics();

  const isMobileLayout = useMediaQuery(MEDIA_QUERY.MOBILE);

  useEffect(() => {
    function resetVideoPan() {
      if (!videoRef.current) {
        return;
      }

      videoRef.current.parentElement!.style.transform = 'translateX(0)';
    }

    function resetPanOnTimeUpdate() {
      if (!videoRef.current) {
        return;
      }

      const telestrationTime = telestration?.items[0].timestamp ?? Infinity;

      if (!Number.isFinite(telestrationTime) || videoRef.current.currentTime < Math.max(telestrationTime - 0.5, 0)) {
        resetVideoPan();
      }
    }

    if (videoRef.current) {
      videoRef.current.addEventListener('timeupdate', resetPanOnTimeUpdate);
      videoRef.current.addEventListener('ended', resetVideoPan);
    }

    return () => {
      if (videoRef.current) {
        videoRef.current.removeEventListener('timeupdate', resetPanOnTimeUpdate);
        videoRef.current.removeEventListener('ended', resetVideoPan);
      }
    };
  }, [telestration]);

  function handlePanVideo(rectRef: RectRef) {
    if (videoRef.current && rectRef.current && isMobileLayout) {
      const videoBoundingRect = videoRef.current.getBoundingClientRect();
      const rectBoundingRect = rectRef.current.getBoundingClientRect();

      const { right: rectRight, left: rectLeft, width: rectWidth } = rectBoundingRect;
      const { left: videoLeft, right: videoRight } = videoBoundingRect;

      const isRightOutsideViewport = viewportWidth < rectRight;
      const isLeftOutsideViewport = rectLeft < 0;

      let translateDistance: number;

      if (isRightOutsideViewport) {
        // Calculate translate to show the spotlight right in the center of viewport
        const calculatedRightTranslate = rectLeft - ((viewportWidth - rectWidth) / 2);
        // Calculate the most right point of the video to avoid overscrolling
        const videoRightBoundary = videoRight - viewportWidth;

        translateDistance = -1 * Math.min(calculatedRightTranslate, videoRightBoundary);
      } else if (isLeftOutsideViewport) {
        // Calculate translate to show the spotlight right in the center of viewport
        const calculatedLeftTranslate = Math.abs(rectLeft) + ((viewportWidth - rectWidth) / 2);
        // Calculate the most left point of the video to avoid overscrolling
        const videoLeftBoundary = Math.abs(videoLeft);

        translateDistance = Math.min(calculatedLeftTranslate, videoLeftBoundary);
      } else {
        return;
      }

      videoRef.current.parentElement!.style.transform = `translateX(${translateDistance}px)`;
      videoPannedRef.current = true;
    }
  }

  function handleResetPanVideo() {
    if (videoRef.current && resetPanOnHide && videoPannedRef.current) {
      videoRef.current.parentElement!.style.transform = 'translateX(0)';
      videoPannedRef.current = false;
    }
  }

  function handleShowSpotlight(rectRef: RectRef) {
    handlePanVideo(rectRef);

    analytics?.track(events.TELESTRATION_VIEWED, {
      own: authPlayer?.id === player.id,
    });
  }

  return (
    <div className={styles.container}>
      <TelestrationOverlay
        data={telestration ?? null}
        videoRef={videoRef}
        player={player}
        enabled={enabled}
        onSpotlightShow={handleShowSpotlight}
        onSpotlightHide={handleResetPanVideo}
      />
    </div>
  );
}
