/* eslint-disable react/jsx-props-no-spreading */
import cn from 'classnames';
import { ConditionalTooltip } from 'src/components/conditional-tooltip/conditional-tooltip';
import styles from './control-button.module.scss';

type Props = JSX.IntrinsicElements['button'] & {
  hint?: string;
  hintEnabled?: boolean;
};

export function ControlButton({
  children,
  className,
  hint,
  hintEnabled = false,
  onClick,
  ...restButtonProps
}: Props) {
  const buttonStyles = cn(
    styles.control,
    className,
  );

  return (
    <ConditionalTooltip
      visible={!!hint && hintEnabled}
      text={hint!}
    >
      <button
        type="button"
        className={buttonStyles}
        onClick={onClick}
        {...restButtonProps}
      >
        { children }
      </button>
    </ConditionalTooltip>
  );
}
