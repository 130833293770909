import { Outlet } from 'react-router-dom';
import cn from 'classnames';
import { Header } from './header/header';
import layoutStyles from './layout.module.scss';

type LayoutProps = {
  className?: string
};

export function Layout({ className }: LayoutProps) {
  const layoutClassnames = cn(layoutStyles.layout, { [`${className}`]: true });

  return (
    <div className={layoutClassnames}>
      <main className={layoutStyles['layout-content']}>
        <div className={layoutStyles['layout-outlet']}>
          <Outlet />
        </div>
      </main>
      {/* Should be the last rendered component to avoid z-index assignment */}
      <Header />
    </div>
  );
}
