import i18n from 'i18next';
import i18nextICU from 'i18next-icu';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import enJson from '../locales/en.json';
import deJson from '../locales/de.json';
import esJson from '../locales/es.json';
import frJson from '../locales/fr.json';
import itJson from '../locales/it.json';
import jaJson from '../locales/ja.json';
import plJson from '../locales/pl.json';

i18n
  .use(LanguageDetector)
  .use(i18nextICU)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en',
    load: 'languageOnly',
    resources: {
      en: { translation: { ...enJson } },
      de: { translation: { ...deJson } },
      es: { translation: { ...esJson } },
      fr: { translation: { ...frJson } },
      it: { translation: { ...itJson } },
      ja: { translation: { ...jaJson } },
      pl: { translation: { ...plJson } },
    },
    interpolation: {
      escapeValue: false,
    },
    detection: {
      order: ['cookie'],
      lookupCookie: 'veo_language',
      caches: ['cookie'],
    },
    react: {
      useSuspense: false,
    },
  });

export default i18n;
