import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, Navigate } from 'react-router-dom';
import { ErrorPage } from '@veo/web-design-system';
import { useOidcAuth } from '../../hooks/useOidcAuth';

export function SignoutRedirect() {
  const {
    error, clearStaleState, removeUser,
  } = useOidcAuth();
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    async function cleanupUser() {
      await removeUser();
      await clearStaleState();
    }

    return () => {
      cleanupUser();
    };
  }, []);

  if (error) {
    return (
      <ErrorPage
        illustration="users"
        title={t('auth.logout.error_title')}
        description={error.message}
        onButtonClick={() => navigate('/', { replace: true })}
        buttonText={t('auth.logout.action_label') as string}
      />
    );
  }

  return <Navigate to="/" replace />;
}
