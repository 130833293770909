import { useTranslation } from 'react-i18next';
import { useFormContext, useController } from 'react-hook-form';
import { FormField, Input } from '@veo/web-design-system';
import { isNumberInRangeValidator } from 'src/common/utils/form-validators';
import { KeyInfoFormValues } from '../../../utils/key-information';
import styles from './key-information-form.module.scss';

export function GpaField() {
  const { t } = useTranslation();

  const { control, register } = useFormContext<KeyInfoFormValues>();

  const { fieldState } = useController<KeyInfoFormValues, 'gpa'>({
    control,
    name: 'gpa',
    rules: {
      validate: (v) => isNumberInRangeValidator(1, 5)(v),
    },
  });

  const {
    isTouched,
    error,
  } = fieldState;

  return (
    <FormField
      id="gpa"
      className={styles.field}
      hint={t('player_key_information.form.gpa_field_tooltip') as string}
      label={t('player_key_information.gpa_title')}
      error={isTouched ? error?.message : undefined}
    >
      <Input
        type="number"
        step="0.1"
        min="1"
        max="5"
        {...register('gpa')}
      />
    </FormField>
  );
}
