import { User } from 'oidc-client-ts';
import { LS_AUTH_TOKEN_KEY } from '../constants';

export function getTokenFromStorage() {
  const value = window.sessionStorage.getItem(LS_AUTH_TOKEN_KEY);

  if (!value) {
    return null;
  }

  try {
    const user = JSON.parse(value) as User;

    return user.access_token;
  } catch (err) {
    return null;
  }
}
