import { RefObject, useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';
import { Modal } from '@veo/web-design-system';
import { VeoPlayer } from 'src/components/veo-player/veo-player';
import { useAuthPlayer } from 'src/features/auth';
import { ITelestrationItem, ITelestration } from 'src/common/model/interfaces/ITelestration';
import { VeoPlayerRendererProps } from 'src/components/veo-player/types/veo-player.type';
import { useMediaQuery } from 'src/common/hooks/useMediaQuery';
import { MEDIA_QUERY } from 'src/common/constants/media-query';
import { Nullable } from 'src/common/model/types/helper.type';
import { DEFAULT_TELESTRATION_TYPE } from '../../constants/telestration';
import { TelestrationVideoControls } from '../telestration-video-controls/telestration-video-controls';
import { useFrameCapture } from '../../hooks/useFrameCapture';
import { PlayerSpotlightPicker } from '../player-spotlight-picker/player-spotlight-picker';
import { useParsedSpotlightMutation } from '../../hooks/useParsedSpotlightMutation';
import { SourceVideo } from '../../models/source-video';
import { TelestrationOverlay } from '../telestration-overlay/telestration-overlay';
import { TelestrationActions } from '../telestration-actions/telestration-actions';
import styles from './edit-telestration.module.scss';

type Props = {
  portalRef: RefObject<HTMLDivElement>;
  video: SourceVideo;
  telestration: Nullable<ITelestration>;
  onChange(t: ITelestration | null): void;
};

export function EditPlayerClipTelestration({
  video, telestration, portalRef, onChange,
}: Props) {
  const videoPlayerRef = useRef<HTMLVideoElement>(null);

  const isMobileLayout = useMediaQuery(MEDIA_QUERY.MOBILE);

  const { data: authPlayer } = useAuthPlayer();

  const {
    data: capturedFrame,
    captureFrame,
    revokeFrame,
    isLoading: isCapturingFrame,
  } = useFrameCapture({
    videoRef: videoPlayerRef,
    enabled: !telestration,
  });

  const {
    mutate: parsePlayerSpotlights,
    data: parsedTelestration,
    isLoading: proccessingImage,
  } = useParsedSpotlightMutation();

  useEffect(() => {
    if (capturedFrame) {
      const { data: image, canvas, timestamp } = capturedFrame;

      const payload = {
        image,
        canvas,
        timestamp,
        type: DEFAULT_TELESTRATION_TYPE,
      };

      parsePlayerSpotlights(payload, {
        onError() {
          revokeFrame();
        },
      });
    }
  }, [capturedFrame]);

  function handleAddPlayerSpotlight(playerSpotlight: ITelestrationItem) {
    if (!capturedFrame) {
      return;
    }

    const payload: ITelestration = {
      canvas: capturedFrame.canvas,
      items: [playerSpotlight],
    };

    onChange(payload);
    revokeFrame();
  }

  function renderActions() {
    if (!portalRef.current) {
      return null;
    }

    return createPortal(
      <TelestrationActions
        videoRef={videoPlayerRef}
        telestration={telestration}
        isCapturing={isCapturingFrame}
        isPickingSpotlight={!!capturedFrame}
        onFrameCapture={captureFrame}
        onRemove={() => onChange(null)}
      />,
      portalRef.current,
    );
  }

  function renderVideoControls(rendererProps: VeoPlayerRendererProps) {
    return <TelestrationVideoControls {...rendererProps} telestration={telestration} />;
  }

  return (
    <>
      {
        !capturedFrame && (
          <VeoPlayer
            ref={videoPlayerRef}
            src={video.url}
            poster={video.thumbnail}
            containerClassName={styles['video-container']}
            crossOrigin="anonymous"
            controlsRenderer={(rendererProps) => renderVideoControls(rendererProps)}
          >
            <TelestrationOverlay
              data={telestration}
              videoRef={videoPlayerRef}
              player={authPlayer}
            />
          </VeoPlayer>
        )
      }
      { renderActions() }
      {
        !isMobileLayout && capturedFrame && (
          <PlayerSpotlightPicker
            frame={capturedFrame}
            loading={proccessingImage}
            telestration={parsedTelestration}
            onCancel={revokeFrame}
            onChange={handleAddPlayerSpotlight}
          />
        )
      }
      <Modal
        open={isMobileLayout && !!capturedFrame}
        onClose={revokeFrame}
        className={styles.modal}
      >
        <PlayerSpotlightPicker
          frame={capturedFrame}
          loading={proccessingImage}
          telestration={parsedTelestration}
          onCancel={revokeFrame}
          onChange={handleAddPlayerSpotlight}
        />
      </Modal>
    </>
  );
}
