import { useEffect, useState } from 'react';
import { AnalyticEventsType } from '../constants/segment-events';
import { useAnalytics } from './useAnalytics';

type MountEvent = {
  name: AnalyticEventsType,
  properties?: Record<string, any>,
};

export function useTrackEventOnMount(mountEvent: MountEvent) {
  const [defferedEvent, setDefferedEvent] = useState<MountEvent | null>(mountEvent);
  const { analytics } = useAnalytics();

  // Deffer the analytics call if it is not yet initialized
  useEffect(() => {
    if (analytics?.initialized && defferedEvent) {
      const { name, properties } = defferedEvent;

      analytics?.track(name, properties);

      setDefferedEvent(null);
    }
  }, [analytics?.initialized, defferedEvent]);
}
