import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter } from 'react-router-dom';
import { GlobalStyles as VeoGlobalStyles, NotificationProvider } from '@veo/web-design-system';
import { AnalyticsProvider } from 'src/features/analytics/hooks/useAnalytics';
import { OidcProvider } from 'src/features/oidc';
import { DEFAULT_QUERY_CLIENT_CONFIG } from './common/constants/react-query';
import { App } from './App';
import globalStyles from './App.module.scss';

const queryClient = new QueryClient(DEFAULT_QUERY_CLIENT_CONFIG);

export function Main() {
  return (
    <VeoGlobalStyles>
      <AnalyticsProvider>
        <OidcProvider>
          <QueryClientProvider client={queryClient}>
            <BrowserRouter>
              <NotificationProvider className={globalStyles['app-notification-container']}>
                <App />
              </NotificationProvider>
            </BrowserRouter>
          </QueryClientProvider>
        </OidcProvider>
      </AnalyticsProvider>
    </VeoGlobalStyles>
  );
}
