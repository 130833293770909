import { useTranslation } from 'react-i18next';
import { useFormContext, useController } from 'react-hook-form';
import { FormField, Input } from '@veo/web-design-system';
import { isNumberInRangeValidator } from 'src/common/utils/form-validators';
import { KeyInfoFormValues } from '../../../utils/key-information';
import styles from './key-information-form.module.scss';

export function GraduationField() {
  const { t } = useTranslation();

  const { control, register } = useFormContext<KeyInfoFormValues>();

  const { fieldState } = useController<KeyInfoFormValues, 'graduationYear'>({
    control,
    name: 'graduationYear',
    rules: {
      validate: (v) => isNumberInRangeValidator(2000, 2050)(v),
    },
  });

  const {
    isTouched,
    error,
  } = fieldState;

  return (
    <FormField
      id="graduationYear"
      className={styles.field}
      label={t('player_key_information.graduation_year_title')}
      hint={t('player_key_information.form.graduation_year_field_tooltip') as string}
      error={isTouched ? error?.message : undefined}
    >
      <Input
        type="number"
        min="2000"
        max="2050"
        {...register('graduationYear')}
      />
    </FormField>
  );
}
