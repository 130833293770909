import { useState, SyntheticEvent } from 'react';
import { useTranslation } from 'react-i18next';
import {
  useMenu,
  IconButton,
  Menu,
  MenuList,
  MenuListItem,
} from '@veo/web-design-system';
import { Confirmation, useConfirmation } from 'src/components/confirmation';
import { IPlayerClip } from 'src/common/model/interfaces/IPlayerClip';
import { useDeletePlayerClipMutation } from 'src/common/hooks/player-clips/useDeletePlayerClipMutation';
import { EditModal } from './edit-modal';
import styles from './edit-menu.module.scss';

type Props = {
  playerClip: IPlayerClip;
  className?: string;
  onRemove?(pc: IPlayerClip): void;
  onUpdate?(pc: IPlayerClip): void;
};

export function EditMenu({
  playerClip, className, onRemove, onUpdate,
}: Props) {
  const [menuOpen, setMenuOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);

  const { t } = useTranslation();

  const { mutate: removeItem } = useDeletePlayerClipMutation();

  function removePlayerClip() {
    removeItem(playerClip.id, {
      onSuccess() {
        if (onRemove) {
          onRemove(playerClip);
        }
      },
    });
  }

  const { triggerAction: removeWithConfirm, ...restConfirmationProps } = useConfirmation({ action: removePlayerClip });

  const {
    setButton, setMenu, getButtonProps, getMenuProps,
  } = useMenu(
    menuOpen,
    setMenuOpen,
    'bottom',
  );

  /** Prevent any click events to bubble from inside menu (menu items click, all modal actions in portals etc...) */
  function preventClickPropagation(e: SyntheticEvent<HTMLDivElement>) {
    e.stopPropagation();
  }

  function handleRemoveClicked() {
    setMenuOpen(false);
    removeWithConfirm();
  }

  function handleEditClicked() {
    setMenuOpen(false);
    setEditOpen(true);
  }

  return (
    <div
      role="presentation"
      className={className}
      onClick={preventClickPropagation}
    >
      <Confirmation
        message={t('profile_video.player_clip.remove_confirmation_description') as string}
        title={t('profile_video.player_clip.remove_confirmation_title')}
        confirmLabel={t('profile_video.player_clip.remove_confirmation_confirm_button_label') as string}
        {...restConfirmationProps}
      />

      <EditModal
        open={editOpen}
        playerClip={playerClip}
        onClose={() => setEditOpen(false)}
        onUpdate={onUpdate}
      />

      <IconButton
        ref={setButton}
        variant="text"
        size="sm"
        icon="more-vertical"
        {...getButtonProps()}
      />

      <Menu
        className={styles.container}
        ref={setMenu}
        {...getMenuProps()}
      >
        <MenuList>
          <MenuListItem
            title={t('profile_video.player_clip.edit_button_label')}
            iconStart="edit-2"
            onClick={handleEditClicked}
            size="sm"
          />
          <MenuListItem
            title={t('profile_video.player_clip.remove_button_label')}
            iconStart="x"
            onClick={handleRemoveClicked}
            size="sm"
          />
        </MenuList>
      </Menu>
    </div>
  );
}
