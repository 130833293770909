import { useTranslation } from 'react-i18next';
import { useController, useFormContext } from 'react-hook-form';
import { FormField } from '@veo/web-design-system';
import { Dropdown } from 'src/components/dropdown/dropdown';
import { ITeam } from 'src/common/model/interfaces/ITeam';
import { useAuthPlayerTeams } from 'src/common/hooks/player/useAuthPlayerTeams';
import { AboutInfoFormValues } from '../../../utils/about-information';
import styles from './about-information-form.module.scss';

export function TeamField() {
  const { t } = useTranslation();

  const { control } = useFormContext<AboutInfoFormValues>();

  const { field } = useController<AboutInfoFormValues, 'team'>({
    name: 'team',
    control,
  });

  const { name, ...fieldProps } = field;

  const { data: teams } = useAuthPlayerTeams();

  return (
    <FormField
      label={t('profile_settings.form.team_field_label')}
      className={styles.field}
      id={name}
    >
      <Dropdown<ITeam>
        multi={false}
        placeholder={t('profile_settings.form.team_field_placeholder')}
        options={teams}
        getOptionValue={(team) => team.name}
        getOptionLabel={(team) => team.name}
        {...fieldProps}
      />
    </FormField>
  );
}
