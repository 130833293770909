import { PlaceholderProps, components } from 'react-select';
import styles from '../dropdown.module.scss';

export function Placeholder<Option, IsMulti extends boolean>(props: PlaceholderProps<Option, IsMulti>) {
  const { children, ...restProps } = props;

  return (
    <components.Placeholder {...restProps} className={styles.placeholder}>
      { children }
    </components.Placeholder>
  );
}
