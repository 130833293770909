import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Divider,
  IconButton,
  DrawerModal,
  DrawerNavigation,
  DrawerNavigationItem,
} from '@veo/web-design-system';
import { LinkWrapper } from 'src/components/link/link';
import { config } from 'src/config';
import { ClubhouseSelector } from '../club-selector';
import { useUserClubs } from '../../hooks/useUserClubs';
import styles from './navigation-menu.module.scss';

export function NavigationMenu() {
  const [navbarOpen, setNavbarOpen] = useState(false);

  const { t } = useTranslation();
  const { data: clubs } = useUserClubs();

  function toggleSidebar() {
    setNavbarOpen((isOpen) => !isOpen);
  }

  const mainClub = clubs?.[0];

  if (!mainClub) {
    return null;
  }

  const mainClubBaseUrl = `${config.ZOLA_URL}${mainClub.url}`;

  return (
    <>
      <IconButton
        size="sm"
        variant="text"
        icon="menu"
        title="Navigation toggler"
        onClick={toggleSidebar}
      />
      <DrawerModal
        open={navbarOpen}
        onClose={() => setNavbarOpen(false)}
      >
        <DrawerNavigation className={styles.navigation}>
          <ClubhouseSelector
            value={mainClub}
            options={clubs}
          />
          <Divider />
          <LinkWrapper external link={`${mainClubBaseUrl}recordings`}>
            <DrawerNavigationItem
              title={t('layout.sidemenu_recordings_link')}
              iconStart="video"
              onClick={toggleSidebar}
              expanded
            />
          </LinkWrapper>
          <LinkWrapper external link={`${mainClubBaseUrl}teams`}>
            <DrawerNavigationItem
              title={t('layout.sidemenu_teams_link')}
              iconStart="users"
              onClick={toggleSidebar}
              expanded
            />
          </LinkWrapper>
          <LinkWrapper external link={`${mainClubBaseUrl}cameras`}>
            <DrawerNavigationItem
              title={t('layout.sidemenu_veo_cams_link')}
              iconStart="camera"
              onClick={toggleSidebar}
              expanded
            />
          </LinkWrapper>
          <Divider />
          <LinkWrapper link="/">
            <DrawerNavigationItem
              title={t('layout.sidemenu_player_profile_link')}
              iconStart="user"
              onClick={toggleSidebar}
              expanded
            />
          </LinkWrapper>
          <LinkWrapper external link={`${config.ZOLA_URL}/mentions`}>
            <DrawerNavigationItem
              title={t('layout.sidemenu_mentions_link')}
              iconStart="at-sign"
              onClick={toggleSidebar}
              expanded
            />
          </LinkWrapper>
          <div style={{ marginTop: 'auto' }} />
          <Divider />
          <LinkWrapper external link={`${mainClubBaseUrl}edit`}>
            <DrawerNavigationItem
              title={t('layout.sidemenu_club_settings_link')}
              iconStart="settings"
              onClick={toggleSidebar}
              expanded
            />
          </LinkWrapper>
          <LinkWrapper
            external
            target="_blank"
            link="https://support.veo.co/hc/articles/13229691326993-About-Player-Profile"
          >
            <DrawerNavigationItem
              title={t('layout.sidemenu_support_link')}
              iconStart="headphones"
              onClick={toggleSidebar}
              expanded
            />
          </LinkWrapper>
        </DrawerNavigation>
      </DrawerModal>
    </>
  );
}
