import { useTranslation } from 'react-i18next';
import { useFormContext, useController } from 'react-hook-form';
import { FormField } from '@veo/web-design-system';
import { Nullable } from 'src/common/model/types/helper.type';
import { MeasurementSystem } from 'src/common/model/enums/MeasurementSystem';
import { KeyInfoFormValues } from 'src/features/player-profile/utils/key-information';
import { Dropdown } from 'src/components/dropdown/dropdown';
import { getDropdownMeasurementSystemOptions } from 'src/common/utils/measurement-units';

type Props = {
  className?: string;
};

type MSDropdownValue = {
  value: MeasurementSystem,
  label: string,
};

export function MeasurementSystemField({ className }: Props) {
  const { t } = useTranslation();

  const { control, setValue } = useFormContext<KeyInfoFormValues>();

  const { field, fieldState } = useController<KeyInfoFormValues, 'measurementSystem'>({
    control,
    name: 'measurementSystem',
  });

  const {
    onChange,
    onBlur,
    value,
  } = field;

  const {
    isTouched,
    error,
  } = fieldState;

  function resetDependantFields() {
    const setterOptions = { shouldDirty: true, shouldValidate: true };
    setValue('weight', '', setterOptions);
    setValue('height', '', setterOptions);
    setValue('heightFeet', '', setterOptions);
    setValue('heightInches', '', setterOptions);
    setValue('weightLbs', '', setterOptions);
  }

  function handleSystemChange(option: Nullable<MSDropdownValue>) {
    if (option && option.value) {
      onChange(option.value);
      resetDependantFields();
    } else {
      onChange('');
    }
  }

  return (
    <FormField
      label={t('measurement_system.title')}
      className={className}
      id="measurementSystem"
      error={isTouched ? error?.message : undefined}
    >
      <Dropdown
        multi={false}
        value={
          value
            ? {
              value,
              label: t(`measurement_system.${value}`),
            }
            : null
        }
        options={getDropdownMeasurementSystemOptions()}
        onChange={handleSystemChange}
        onBlur={onBlur}
      />
    </FormField>
  );
}
