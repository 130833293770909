import { useTrackEventOnMount, ANALYTIC_EVENTS } from 'src/features/analytics';
import { IMatch } from 'src/common/model/interfaces/IMatch';
import { FilterParams } from 'src/common/hooks/useQueryParams';
import { QuickFilters } from './quick-filters';
import { MatchFilters } from './match-filters';

type Props = {
  onFilterChange(f: FilterParams): void;
  onMatchChange(m: IMatch): void;
  className?: string;
};

export function ClipsFilters({
  className,
  onFilterChange,
  onMatchChange,
}: Props) {
  useTrackEventOnMount({ name: ANALYTIC_EVENTS.ADD_CLIP_FILTERS_VIEW });

  return (
    <div className={className}>
      <MatchFilters onChange={onMatchChange} />
      <QuickFilters onChange={onFilterChange} />
    </div>
  );
}
