import { CSSProperties } from 'react';
import { Step as StepCmp } from './step';
import { Step, StepperSize, StepperSizes } from './types';
import styles from './stepper.module.scss';

type Props = {
  steps: Step[];
  active: Step;
  size?: StepperSize;
};

export function Stepper({ steps, active, size = StepperSizes.sm }: Props) {
  const activeIndex = steps.findIndex(({ label }) => label === active.label);

  return (
    <div
      className={styles.container}
      style={{ '--steper-grid-columns': `${steps.length}` } as CSSProperties}
    >
      {
        steps.map(({ label }, index) => (
          <StepCmp
            key={label}
            size={size}
            label={label}
            active={index <= activeIndex}
          />
        ))
      }
    </div>
  );
}

export { type StepperSize, StepperSizes };
