import {
  useEffect, useState, useRef, MutableRefObject,
} from 'react';

export function useHover<T extends HTMLElement>(enabled = true): [MutableRefObject<T | null>, boolean] {
  const [value, setValue] = useState<boolean>(false);
  const ref = useRef<T | null>(null);

  useEffect(() => {
    const node = ref.current;

    const handleMouseOver = (): void => setValue(true);
    const handleMouseOut = (): void => setValue(false);

    if (node && enabled) {
      node.addEventListener('mouseover', handleMouseOver);
      node.addEventListener('mouseout', handleMouseOut);
    }

    return () => {
      if (node) {
        node.removeEventListener('mouseover', handleMouseOver);
        node.removeEventListener('mouseout', handleMouseOut);
      }
    };
  }, [ref.current, enabled]);

  return [ref, value];
}
