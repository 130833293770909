import { MenuProps, components } from 'react-select';
import styles from '../dropdown.module.scss';

export function Menu<Option, IsMulti extends boolean>(props: MenuProps<Option, IsMulti>) {
  const { children, ...restProps } = props;

  return (
    <components.Menu {...restProps} className={styles.menu}>
      { children }
    </components.Menu>
  );
}
