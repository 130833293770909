import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { Text } from '@veo/web-design-system';
import { AbilityItem, AbilityTag } from 'src/components/ability';
import { usePlayerAbilities } from 'src/common/hooks/abilities/usePlayerAbilities';
import { AbilityCode } from 'src/common/model/enums/AbilityCode';
import { PlayerClipsFilter } from '../../model/player-clips-filter';
import styles from './abilities-filter.module.scss';

type Props = {
  filter: PlayerClipsFilter;
  className?: string;
  onFilterChange?(f: PlayerClipsFilter): void;
};

export function AbilitiesFilter({ filter, className, onFilterChange }: Props) {
  const { t } = useTranslation();

  const { data: playerAbilities } = usePlayerAbilities(filter.playerId);

  function handleAbilityChange(code: AbilityCode) {
    if (onFilterChange && filter.ability !== code) {
      onFilterChange({ ...filter, ability: code });
    }
  }

  function handleResetFilter() {
    if (onFilterChange && filter.ability) {
      const { ability, ...rest } = filter;
      onFilterChange(rest);
    }
  }

  const allFilterClassnames = cn(
    styles['all-filter'],
    { [styles.active]: !filter.ability },
  );

  return (
    <div className={cn(styles.container, className)}>
      <AbilityTag
        className={allFilterClassnames}
        active={!filter.ability}
        onClick={handleResetFilter}
      >
        <Text
          type="block"
          size="caption"
          weight="semiBold"
        >
          { t('profile_video.filters.all_filter_label') }
        </Text>
      </AbilityTag>
      {
        playerAbilities?.map(({ code }) => (
          <AbilityItem
            active={code === filter.ability}
            className={cn({ [styles.active]: code === filter.ability })}
            key={code}
            code={code}
            onClick={() => handleAbilityChange(code)}
          />
        ))
      }
    </div>
  );
}
