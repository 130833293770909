import { useTranslation } from 'react-i18next';
import { useFormContext, useController } from 'react-hook-form';
import { FormField, Input } from '@veo/web-design-system';
import { isSocialMediaIdentifierValidator } from 'src/common/utils/form-validators';
import { AboutInfoFormValues } from '../../../utils/about-information';
import styles from './about-information-form.module.scss';

export function InstagramField() {
  const { t } = useTranslation();

  const { control, register } = useFormContext<AboutInfoFormValues>();

  const { fieldState } = useController<AboutInfoFormValues, 'instagram'>({
    control,
    name: 'instagram',
    rules: {
      validate: (v) => isSocialMediaIdentifierValidator(v, t('validators.social_media_identifier') as string),
    },
  });

  const {
    isTouched,
    error,
  } = fieldState;

  return (
    <FormField
      className={styles.field__small}
      label={t('player_about.form.social_media_field_label', { value: 'Instagram' })}
      id="instagram"
      error={isTouched ? error?.message : undefined}
    >
      <Input
        type="text"
        placeholder={t('player_about.form.social_media_field_placeholder') as string}
        {...register('instagram')}
      />
    </FormField>
  );
}
