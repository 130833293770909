import { ReactNode } from 'react';
import { has } from 'lodash-es';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { IconButton, Button } from '@veo/web-design-system';
import { Paper } from 'src/components/paper/paper';
import { AddPlayerClip } from 'src/features/add-player-clip/components/add-player-clip';
import { IPlayerClip } from 'src/common/model/interfaces/IPlayerClip';
import { PlayerProtected } from 'src/features/auth';
import { PlayerClipsFilter } from '../../model/player-clips-filter';
import { AbilitiesFilter } from '../abilities-filter/abilities-filter';
import styles from './player-clips-playlist.module.scss';

type Props = {
  filter: PlayerClipsFilter;
  children: ReactNode;
  collapsed?: boolean;
  orderingDisabled?: boolean;
  onFilterChange(f: PlayerClipsFilter): void;
  onCollapseChange(collapsed: boolean): void;
  onReorder: VoidFunction;
  onAddClip?(pc: IPlayerClip): void;
};

export function PlayerClipsPlaylist({
  filter,
  children,
  collapsed = true,
  orderingDisabled = false,
  onFilterChange,
  onCollapseChange,
  onReorder,
  onAddClip,
}: Props) {
  const { t } = useTranslation();

  function toggleCollapse() {
    onCollapseChange(!collapsed);
  }

  const containerClassnames = cn(
    styles.container,
    { [styles.collapsed]: collapsed },
  );

  const expandClassnames = cn(
    styles.expand,
    { [styles.collapsed]: collapsed },
  );

  const reorderClassnames = cn(
    styles.reorder,
    {
      [styles.collapsed]: collapsed,
      [styles.hidden]: has(filter, 'ability'),
    },
  );

  const filterClassnames = cn(
    styles.filters,
    { [styles.collapsed]: collapsed },
  );

  const listClassnames = cn(
    styles.list,
    { [styles.collapsed]: collapsed },
  );

  const addClassnames = cn(
    styles.add,
    { [styles.collapsed]: collapsed },
  );

  const stickyBackground = cn(
    styles['sticky-background'],
    { [styles.collapsed]: collapsed },
  );

  /** Keep in mind that order of elements inside this container does not
   * determine appearance in ui. Everything is controlled via CSS and Grid props.
   * 'Expand' and 'Filters' placed in bottom to always appear on top when sticky.
   * Just to avoid adding z-indexes
   */
  return (
    <Paper className={containerClassnames}>
      <div className={reorderClassnames}>
        <PlayerProtected predicate={(authPlayer) => authPlayer.id === filter.playerId}>
          <Button
            label={t('profile_video.reorder.order_button_label')}
            size="md"
            disabled={orderingDisabled}
            iconEnd="layers"
            variant="outlined"
            onClick={onReorder}
          />
        </PlayerProtected>
      </div>
      <div className={listClassnames}>
        { children }
      </div>
      <div className={addClassnames}>
        <PlayerProtected predicate={(authPlayer) => authPlayer.id === filter.playerId}>
          {
            collapsed && (
              <AddPlayerClip onAddClip={onAddClip}>
                <IconButton
                  variant="secondary"
                  size="md"
                  icon="plus"
                />
              </AddPlayerClip>
            )
          }
          {
            !collapsed && (
              <AddPlayerClip onAddClip={onAddClip}>
                <Button
                  variant="secondary"
                  size="md"
                  label={t('profile_video.player_clip.add_button_label')}
                  iconEnd="plus"
                />
              </AddPlayerClip>
            )
          }
        </PlayerProtected>
      </div>
      {/* This should be placed after list but before the expand and filters to avoid z-indexing while sticky */}
      <Paper elevation={1} className={stickyBackground} />
      <div className={expandClassnames}>
        <IconButton
          variant="secondary"
          size="sm"
          icon={collapsed ? 'maximize-2' : 'minimize-2'}
          onClick={toggleCollapse}
        />
      </div>
      <div className={filterClassnames}>
        <AbilitiesFilter filter={filter} onFilterChange={onFilterChange} />
      </div>
    </Paper>
  );
}
