import { config } from 'src/config';
import { APP_ROUTES } from '../constants/routes';
import { IPlayer } from '../model/interfaces/IPlayer';
import { ITeam } from '../model/interfaces/ITeam';

const PLAYER_ID_LENGTH = 24;
const USERNAME_REGEX = /^(?=.{5,20}$)[a-zA-Z0-9]+(?:-[a-zA-Z0-9]+)*$/;

export const isPlayerUsername = (identifier: string) => new RegExp(USERNAME_REGEX).test(identifier);
export const isPlayerId = (identifier: string) => identifier.length === PLAYER_ID_LENGTH;

/** @deprecated */
export const isAuthProfile = (authPlayer?: IPlayer, profileIdentifier?: string) => {
  if (!profileIdentifier || !authPlayer) {
    return false;
  }

  const { id: playerId, username } = authPlayer;

  if (username === profileIdentifier) {
    return true;
  }

  if (playerId === profileIdentifier) {
    return true;
  }

  return false;
};

export const getProfileIdentifier = (player?: IPlayer) => {
  if (!player) {
    return player;
  }

  const identifier = player.username ?? player.id;

  return identifier;
};

export const getProfileExternalLink = (player: IPlayer) => {
  const { hostname } = window.location;
  const identifier = getProfileIdentifier(player);

  return `${hostname}/${APP_ROUTES.playerProfile}/${identifier}`;
};

export const getProfileRouteLink = (player: IPlayer) => {
  const identifier = getProfileIdentifier(player);

  return `/${APP_ROUTES.playerProfile}/${identifier}`;
};

export const getPlayerTeam = (team?: ITeam | null) => {
  if (!team) {
    return '';
  }

  const { name, club } = team;

  return [club.name, name].join(', ');
};

export const getPlayerClubUrl = (player: IPlayer) => `${config.ZOLA_URL}${player.team.club.url}`;

export const getPlayerClubSettingsUrl = (player: IPlayer) => {
  const clubUrl = getPlayerClubUrl(player);

  return `${clubUrl}edit`;
};

export const getPlayerClubTeamsUrl = (player: IPlayer) => {
  const clubUrl = getPlayerClubUrl(player);

  return `${clubUrl}teams`;
};

export const getPlayerClubCamerasUrl = (player: IPlayer) => {
  const clubUrl = getPlayerClubUrl(player);

  return `${clubUrl}cameras`;
};

export const getPlayerClubRecordingsUrl = (player: IPlayer) => {
  const clubUrl = getPlayerClubUrl(player);

  return `${clubUrl}recordings`;
};

export const getPlayerMentionsUrl = () => `${config.ZOLA_URL}/mentions`;

export const getPlayerBrowserLanguage = () => navigator.language;

export const getIsPossibleUSPlayer = () => getPlayerBrowserLanguage().includes('US');
