import { ComponentProps } from 'react';
import { Tooltip } from '@veo/web-design-system';

type ConditionalTooltipProps = ComponentProps<typeof Tooltip> & {
  visible: boolean;
};

export function ConditionalTooltip(props: ConditionalTooltipProps) {
  const {
    children, text, visible, ...restProps
  } = props;

  if (!visible) {
    return children ?? null;
  }

  return (
    <Tooltip text={text} {...restProps}>{children}</Tooltip>
  );
}
