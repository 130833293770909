export const getPositionColumn = (order: number) => {
  switch (order) {
    case 2:
      return 1;
    case 0:
      return 2;

    default:
      return 3;
  }
};

export function getPositionGridPlacement(order: number) {
  if (order === 1) {
    return { row: 1, column: 2 };
  }
  if (order === 14) {
    return { row: 6, column: 2 };
  }

  const column = getPositionColumn(order % 3);
  const calculatedRow = Math.floor(order / 3) + (order % 3);
  const row = column === 2 ? calculatedRow + 1 : calculatedRow;

  return { row, column };
}
