import { useMutation, useQueryClient } from 'react-query';
import { useNotification } from '@veo/web-design-system';
import { createPlayerClipTelestration } from 'src/common/apis/telestration';
import { RequesterError } from 'src/common/model/interfaces/IRequesterError';
import { ITelestration } from 'src/common/model/interfaces/ITelestration';
import { isRequesterError } from 'src/common/utils/requester-errors';
import { useAuthPlayer } from 'src/features/auth';
import { useAnalytics } from 'src/features/analytics/hooks/useAnalytics';
import { RQ_KEY } from 'src/common/constants/react-query';

type PlayerClipTelestration = {
  telestration: ITelestration;
  playerClipId: string;
};

export function useCreateTelestrationMutation() {
  const queryClient = useQueryClient();
  const { notify } = useNotification();
  const { data: authPlayer } = useAuthPlayer();
  const { analytics, statuses, events } = useAnalytics();

  return useMutation<unknown, RequesterError, PlayerClipTelestration>(
    ({ playerClipId, telestration }) => createPlayerClipTelestration(playerClipId, authPlayer!.id, telestration),
    {
      async onSuccess() {
        /** Invalidating all player clips queries (both own and public) since there might be different query params in each of them */
        queryClient.invalidateQueries(RQ_KEY.PLAYER_CLIPS);
      },
      async onError(err) {
        if (isRequesterError(err)) {
          notify('error', err.message);
        }
      },
      async onSettled(res, err, data) {
        const status = err ? statuses.FAILED : statuses.SUCCESS;
        const [{ probability = 0, type: telestrationType }] = data.telestration.items;

        const source = probability > 100 ? 'Manual' : 'AI';
        const type = `${source} ${telestrationType}`;

        analytics?.track(
          events.TELESTRATION_CHANGED,
          { action: 'Add', status, type },
        );
      },
    },
  );
}
