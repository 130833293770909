import { useState, useEffect } from 'react';

interface MobileDevice {
  android: () => boolean;
  blackBerry: () => boolean;
  iOS: () => boolean;
  opera: () => boolean;
  windows: () => boolean;
  any: () => boolean;
}

export const useMobileDevice = () => {
  const [isMobileDevice, setIsMobileDevice] = useState(false);

  useEffect(() => {
    const mobileDevice: MobileDevice = {
      android: () => navigator.userAgent.match(/Android/i) !== null,
      blackBerry: () => navigator.userAgent.match(/BlackBerry/i) !== null,
      iOS: () => navigator.userAgent.match(/iPhone|iPad|iPod/i) !== null,
      opera: () => navigator.userAgent.match(/Opera Mini/i) !== null,
      windows: () => navigator.userAgent.match(/IEMobile/i) !== null,
      any: () => (
        mobileDevice.android()
          || mobileDevice.blackBerry()
          || mobileDevice.iOS()
          || mobileDevice.opera()
          || mobileDevice.windows()
      ),
    };

    setIsMobileDevice(mobileDevice.any());
  }, []);

  return { isMobile: isMobileDevice };
};
