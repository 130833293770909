import { forwardRef, ForwardedRef } from 'react';
import { useTranslation } from 'react-i18next';
import { uniqueId } from 'lodash-es';
import { Text } from '@veo/web-design-system';
import { Paper } from 'src/components/paper/paper';
import { Spotlight } from 'src/features/telestration';
import { MEDIA_QUERY } from 'src/common/constants/media-query';
import { useMediaQuery } from 'src/common/hooks/useMediaQuery';
import styles from './edit-telestration.module.scss';

const dummySpotlight = {
  id: uniqueId(),
  type: 'custom',
  timestamp: 1,
  points: [
    {
      x: 80,
      y: 33,
    },
    {
      x: 120,
      y: 98,
    },
  ],
};

type Props = {
  playerName?: string;
};

function TelestrationEditor({ playerName = 'Your name' }: Props, ref: ForwardedRef<HTMLDivElement>) {
  const { t } = useTranslation();

  const isMobileLayout = useMediaQuery(MEDIA_QUERY.MOBILE);

  return (
    <Paper
      elevation={isMobileLayout ? 2 : 3}
      className={styles.container}
    >
      <Text
        type="block"
        size="caption"
        weight="medium"
      >
        { t('player_clip_details.telestration.title') }
      </Text>
      <Text
        type="block"
        size="caption"
        weight="medium"
        className={styles.accent}
      >
        { t('player_clip_details.telestration.description') }
      </Text>
      <svg
        viewBox="0 0 200 112"
        preserveAspectRatio="none"
      >
        <Spotlight
          visible
          boundingBoxVisible
          sourceAspectRatio={1}
          title={playerName}
          boundingBox={dummySpotlight}
        />
      </svg>
      {/* portal container to render telestration edit controlsRenderer */}
      <div ref={ref} />
    </Paper>
  );
}

export const EditTelestration = forwardRef<HTMLDivElement, Props>(TelestrationEditor);
