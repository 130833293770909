import { uniqBy } from 'lodash-es';
import { ITeam } from '../model/interfaces/ITeam';

export function getClubsByTeams(teams?: ITeam[]) {
  if (!teams) {
    return [];
  }

  const clubs = teams.map(({ club }) => club);

  return uniqBy(clubs, ({ slug }) => slug);
}
