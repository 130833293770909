import { MultiValueGenericProps, components } from 'react-select';
import styles from '../dropdown.module.scss';

export function MultiValueContainer<Option, IsMulti extends boolean>(props: MultiValueGenericProps<Option, IsMulti>) {
  const { children, data, ...restProps } = props;

  return (
    <components.MultiValueContainer {...restProps} data={data}>
      <span className={styles['multi-value-container']}>
        { children }
      </span>
    </components.MultiValueContainer>
  );
}
