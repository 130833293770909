import { ForwardedRef, forwardRef } from 'react';
import cn from 'classnames';
import { Text } from '@veo/web-design-system';
import { IPlayerClip } from 'src/common/model/interfaces/IPlayerClip';
import { AbilityStack } from 'src/components/ability';
import { Paper, PaperProps } from 'src/components/paper/paper';
import { useMobileDevice } from 'src/common/hooks/useMobileDevice';
import { useMediaQuery } from 'src/common/hooks/useMediaQuery';
import { MEDIA_QUERY } from 'src/common/constants/media-query';
import styles from './player-clip-panel.module.scss';

type Props = PaperProps & {
  clip: IPlayerClip;
  showStackedAbilityTooltip?: boolean;
};

function PlayerClipPanelCmp(
  {
    clip: pc,
    children,
    className,
    showStackedAbilityTooltip,
    ...restProps
  }: Props,
  ref: ForwardedRef<HTMLDivElement>,
) {
  const { isMobile } = useMobileDevice();
  const isMobileLayout = useMediaQuery(MEDIA_QUERY.MOBILE);

  const abilityStackProps = isMobile || isMobileLayout
    ? { stackAfter: 2, stackSize: 5 }
    : { stackAfter: 1, stackSize: 5 };

  return (
    <Paper
      ref={ref}
      elevation={1}
      className={cn(styles.container, className)}
      {...restProps}
    >
      <img
        src={pc.thumbnail}
        className={styles.thumbnail}
        alt={pc.title}
      />
      <Text
        type="block"
        size="caption"
        weight="medium"
        className={styles.title}
      >
        { pc.title }
      </Text>
      <div className={styles.abilities}>
        <AbilityStack
          {...abilityStackProps}
          codes={pc.ability}
          showTooltip={showStackedAbilityTooltip}
        />
      </div>
      <div className={styles.menu}>
        { children }
      </div>
    </Paper>
  );
}

export const PlayerClipPanel = forwardRef<HTMLDivElement, Props>(PlayerClipPanelCmp);
