import React from 'react';
import { Menu, MenuList } from '@veo/web-design-system';
import { IClub } from 'src/common/model/interfaces/IClub';
import { ClubhouseItem } from './menu-item';
import { NewClubhouse } from './new-clubhouse';

type Props = React.ComponentProps<typeof Menu> & {
  clubhouses: IClub[];
  selectedClubhouse: IClub;
  onChange: () => void;
};

export const ClubhouseSelectorMenu = React.forwardRef((
  {
    clubhouses, selectedClubhouse, onChange, ...menuProps
  }: Props,
  ref: React.ForwardedRef<HTMLDivElement>,
) => (
  <Menu {...menuProps} ref={ref}>
    <MenuList>
      {clubhouses.map((clubhouse) => (
        <ClubhouseItem
          key={clubhouse.name}
          {...clubhouse}
          selected={clubhouse.name === selectedClubhouse.name}
          onClick={onChange}
        />
      ))}
      <NewClubhouse />
    </MenuList>
  </Menu>
));
