import { RequesterErrorType } from '../model/enums/RequesterErrorType';
import { IRequesterError, IValidationError } from '../model/interfaces/IRequesterError';

export function isRequesterError(err: unknown): err is IRequesterError {
  if (typeof err === 'object' && err !== null && 'type' in err) {
    const typedError = err as { type: unknown };

    return Object.values(RequesterErrorType).some((errorType) => errorType === typedError.type);
  }

  return false;
}

export function isValidationError(err: unknown): err is IValidationError {
  if (typeof err === 'object' && err !== null && 'type' in err && 'fields' in err) {
    const typedError = err as { type: unknown };

    return Object.values(RequesterErrorType).some((errorType) => errorType === typedError.type);
  }

  return false;
}
