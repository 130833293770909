import { AuthUserRoles } from 'src/common/model/interfaces/IUser';
import { useAuth } from './useAuth';

export function useAuthPlayer() {
  const { data: authUser, ...rest } = useAuth();

  const data = authUser?.role === AuthUserRoles.player ? authUser.data : undefined;

  return {
    data,
    ...rest,
  };
}
